import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import UserContext from "../../../Context/User-context";
import { OptionsConsumer } from "../../../Context/Options-context";
import Loader from "../../UI/Loader/Loader";
import axios from "../../../axios-api";
import moneyIcon from "./salary.svg";
import checkIcon from "./check.svg";
import "./Balance.css";
import { Link } from "react-router-dom";

export default class BalanceWithdraw extends Component {
  static contextType = UserContext;
  state = {
    bank_name: "",
    account_holder: "",
    account_number: "",
    amount: "",
    amountType: false,
    intitialMessage: "",
    isLoading: false,
    errors: null,
    submitted: false,
  };

  handleChange = (type, e) => {
    let amount = this.context.sell_online_balance;

    if (type === "specfic") {
      let amount = this.context.sell_online_balance;

      this.setState({ amountType: true });
    } else if (type === "all") {
      this.setState({ amountType: false, amount });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  componentDidMount() {
    let amount = this.context.sell_online_balance;
    this.setState({ amount });
  }

  handleAmount = (e) => {
    let { name, value } = e.target;
    let amount = this.context.sell_online_balance;
    if (value > Number(amount)) {
      this.setState({ [name]: amount });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { bank_name, account_holder, account_number, amount } = this.state;
    const data = {
      bank_name,
      account_holder,
      account_number,
      amount,
    };

    this.setState({ isLoading: true });
    axios
      .post("/account/withdraw/store", data)
      .then((res) => {
        this.setState({
          submitted: true,
          isLoading: false,
          errors: null,
        });
      })
      .catch((err) => {
        this.setState({
          intitialMessage: err.response.data.message,
          errors: err.response.data.errors,
          isLoading: false,
        });
      });
  };
  showMinWithdraw = (options) => {
    const minWithObj = options.find((o) => o.key === "min_withdraw");
    return minWithObj.value;
  };
  render() {
    const {
      bank_name,
      account_holder,
      account_number,
      amount,
      amountType,
      intitialMessage,
      isLoading,
      errors,
      submitted,
    } = this.state;

    return (
      <div className="container-lg container-fluid">
        {isLoading ? <Loader /> : null}

        <div className="d-flex align-items-center mb-3 mt-3">
          <Link
            className="primary h6 grey ml-3 mb-0"
            to="/account/balance-details"
          >
            <i className="fa fa-arrow-right"></i>
          </Link>
          <h1 className="h5 bold_font mb-0">طلب سحب الرصيد</h1>
        </div>
        <OptionsConsumer>
          {(option) => (
            <div className="row">
              <div className="col-md-9 order-md-1 order-2">
                <div className="bg-white p-4 rounded shadow-sm">
                  {submitted ? (
                    <React.Fragment>
                      <div className="success_panel">
                        <img
                          src={checkIcon}
                          alt="ايقونة نجاح العملية"
                          className="mb-3"
                        />
                        <h3 className="h5 text-success medium_font">
                          تم ارسال الطلب بنجاح
                        </h3>
                        <p>
                          سنقوم بمراجعة الطلب وارسال رسالة عبر البريد الإلكترونى
                          ,او سنقوم بالتواصل معاك من خلال رقم الهاتف
                        </p>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="col-md-12 p-0">
                        <div
                          className="alert alert-info alert-dismissible fade show py-2"
                          role="alert"
                        >
                          يتم تحويل المبلغ كل اسبوعين , ويمكنك طلب سحب المبلغ فى
                          حال تجاوزه {this.showMinWithdraw(option)} ريال
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                htmlFor="bankHolder"
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                              >
                                اسم البنك
                              </label>
                              <input
                                onChange={this.handleChange.bind(this, "")}
                                type="text"
                                id="bankHolder"
                                name="bank_name"
                                value={bank_name}
                                className={classes.FormControl}
                              />
                              {errors != null ? (
                                <p className="text-danger small mb-0">
                                  {errors.bank_name}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                htmlFor="accountName"
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                              >
                                اسم صاحب الحساب
                              </label>
                              <input
                                onChange={this.handleChange.bind(this, "")}
                                type="text"
                                id="accountName"
                                name="account_holder"
                                value={account_holder}
                                className={classes.FormControl}
                              />
                              {errors != null ? (
                                <p className="text-danger small mb-0">
                                  {errors.account_holder}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                htmlFor="accountNumber"
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                              >
                                رقم الحساب
                              </label>
                              <input
                                onChange={this.handleChange.bind(this, "")}
                                type="number"
                                name="account_number"
                                value={account_number}
                                id="accountNumber"
                                className={classes.FormControl}
                              />
                              {errors != null ? (
                                <p className="text-danger small mb-0">
                                  {errors.account_number}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-4">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                  "d-block",
                                ].join(" ")}
                              >
                                المبلغ
                              </label>
                              <label
                                htmlFor="allCredit"
                                className={[
                                  classes.CustomCheckbox,
                                  classes.Radio,
                                  "ml-4",
                                ].join(" ")}
                              >
                                <input
                                  onChange={this.handleChange.bind(this, "all")}
                                  type="radio"
                                  name="credit"
                                  id="allCredit"
                                />
                                <span className="ml-2"></span>
                                كل المبلغ
                              </label>
                              <label
                                htmlFor="customCredit"
                                className={[
                                  classes.CustomCheckbox,
                                  classes.Radio,
                                ].join(" ")}
                              >
                                <input
                                  onChange={this.handleChange.bind(
                                    this,
                                    "specfic"
                                  )}
                                  type="radio"
                                  name="credit"
                                  id="customCredit"
                                />
                                <span className="ml-2"></span>
                                مبلغ محدد
                              </label>
                              {intitialMessage ? (
                                <p className="text-danger small mb-0">
                                  {intitialMessage}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            {amountType ? (
                              <div className="mb-4">
                                <label
                                  htmlFor="specficBalance"
                                  className={[
                                    classes.Grey,
                                    classes.Small_font,
                                  ].join(" ")}
                                >
                                  المبلغ
                                </label>
                                <input
                                  onChange={this.handleAmount}
                                  type="number"
                                  name="amount"
                                  value={amount}
                                  id="specficBalance"
                                  className={classes.FormControl + " mb-4"}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <button type="submit" className={classes.Primary_btn}>
                          تقديم الطلب
                        </button>
                      </form>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="col-md-3 order-md-2 order-1">
                <div className="bg-white py-md-4 py-3 px-4 mb-md-0 mb-3 shadow-sm rounded-lg">
                  <div className="balance_side">
                    <img src={moneyIcon} alt="ايقونة مال" />
                    <h3 className="medium_font h6">رصيدى</h3>
                    <p className="mb-0">
                      {this.context.sell_online_balance}
                      <sup>ر.س</sup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </OptionsConsumer>
      </div>
    );
  }
}
