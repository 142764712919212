import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import logo from "../../../assets/imgs/logo.png";
import { toastr } from "../../../Helper/Helper";

export default class Tickets extends Component {
  state = {
    tickets: {},
    service_order_id: null,
    title: "",
    desc: "",
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/tickets")
      .then((res) => this.setState({ isLoading: false, tickets: res.data }));
  }
  localeStatus = (status) => {
    switch (status) {
      case "new":
        return "جديد";
      case "support_undone":
        return "تحت المراجعة";
      case "closed":
        return "مغلقة";
      default:
    }
  };
  nextPage = (url) => {
    axios
      .get(url)
      .then((res) => this.setState({ isLoading: false, tickets: res.data }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  submitTicket = (e) => {
    const { service_order_id, title, desc } = this.state;
    const data = {
      title,
      desc,
      service_order_id,
    };
    e.preventDefault();
    this.setState({ isLoading: true });
    axios
      .post(`/tickets/store`, data)
      .then((res) => {
        toastr("success", "تم ارسال التذكرة بنجاح");
        this.setState({ isLoading: false });
        document.querySelector(".modalCloser").click();
      })
      .catch(() => {
        toastr("warning", "رقم الطلب غير صحيح");
        this.setState({ isLoading: false });
      });
  };
  render() {
    const { isLoading, tickets, title, desc, service_order_id } = this.state;
    return (
      <div className="container-lg container-fluid mt-3">
        {isLoading ? <Loader /> : null}

        {/* all notifications  */}
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="d-flex align-items-center mb-3">
              <h1 className="h5 bold_font">جميع التذاكر</h1>
              <div className="mr-auto">
                <button
                  className={classes.Primary_btn}
                  data-toggle="modal"
                  data-target="#newTicket"
                >
                  <i className="fa fa-plus ml-2"></i>
                  تذكرة جديدة
                </button>
              </div>
            </div>
            <div className="bg-white p-4">
              {tickets.data?.length ? (
                tickets.data?.map((e, i) => (
                  <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                    <Link
                      to={{
                        pathname: `/account/chat/${e.id}`,
                        search: "type=ticket",
                      }}
                    >
                      <img
                        className="img_small border ml-3 rounded-circle"
                        src={logo}
                        alt={"الدعم"}
                        style={{ objectFit: "contain" }}
                      />
                    </Link>
                    <div>
                      <h3 className="h6">
                        <Link
                          className="primary"
                          to={{
                            pathname: `/account/chat/${e.id}`,
                            search: "type=ticket",
                          }}
                        >
                          تذكرة: {e.title}
                        </Link>
                      </h3>
                      <div className="grey small_font">
                        <span className="ml-3">
                          <i className="fa fa-ticket ml-2"></i>
                          {this.localeStatus(e.status)}
                        </span>
                        <span className="ml-3">
                          <i className="fa fa-clock-o ml-2"></i>
                          منذ 3 ساعات
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3 className="medium_font text-center py-4 grey">
                  لايوجد تذاكر حتى الأن
                </h3>
              )}

              <div className="text-center">
                {tickets.next_page_url ? (
                  <button
                    className={classes.Transparent_btn}
                    onClick={this.nextPage.bind(this, tickets.next_page_url)}
                  >
                    عرض المزيد
                  </button>
                ) : null}
              </div>
            </div>

            <div
              className="modal fade"
              id="newTicket"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={this.submitTicket}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        فتح تذكرة جديدة
                      </h5>
                    </div>
                    <div className="modal-body">
                      <label htmlFor="title" className="grey small_font">
                        عنوان التذكرة
                      </label>
                      <input
                        type="text"
                        name="title"
                        className={classes.FormControl + " mb-4"}
                        placeholder="عنوان التذكرة"
                        onChange={this.handleChange}
                        value={title}
                        required
                      />
                      <label htmlFor="orderId" className="grey small_font">
                        رقم الطلب
                      </label>
                      <input
                        type="number"
                        id="orderId"
                        name="service_order_id"
                        className={classes.FormControl + " mb-4"}
                        placeholder="اكتب رقم الطلب هنا"
                        onChange={this.handleChange}
                        value={service_order_id}
                        required
                      />
                      <label htmlFor="msg" className="grey small_font">
                        الرسالة
                      </label>
                      <textarea
                        name="desc"
                        className={classes.FormControl}
                        row="4"
                        required
                        placeholder="وصف التذكرة"
                        value={desc}
                        onChange={this.handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="modalCloser"
                      ></button>
                      <button type="submit" className={classes.Primary_btn}>
                        إرسال
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
