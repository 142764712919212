import React from "react";
import classes from "../../UI/UI.module.css";
import { Component } from "react";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { Link } from "react-router-dom";
import "./singleCourse.css";
import { copyUrl, toastr } from "../../../Helper/Helper";
import { fabric } from "fabric";
import CertImg from "./cert.jpeg";
let canvas = null;

class SingleCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: "",
      students: [],
      isLoading: false,
      images: [],
      notFound: false,
      activeStudent: {},
    };
  }

  componentDidMount() {
    this.fetchCourse();
  }
  fetchCourse = () => {
    canvas = new fabric.Canvas("canvas");
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    axios
      .get(`account/course/${params.courseId}`)
      .then((res) => {
        this.setState({
          course: res.data,
          students: res.data.students,
          isLoading: false,
        });
        if (res.data.cert !== null) {
          canvas.loadFromJSON(res.data.cert.coordinates);
        } else {
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  certUpload = (id, studentName) => {
    const { cert } = this.state.course;

    if (cert && canvas.getObjects().length) {
      canvas.getObjects()[0].text = studentName;
      canvas.renderAll();

      this.setState({ isLoading: true });

      const certImg = canvas.toDataURL({
        format: "jpeg",
        multiplier: 3,
        quality: 1,
      });
      const certObj = {
        image: certImg,
        id,
      };

      axios
        .post("/cert/student/store", {
          images: [certObj],
        })
        .then((res) => {
          this.setState({ isLoading: false });
          this.fetchCourse();
          toastr("success", "تم منح الشهادة بنجاح");
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toastr("danger", "نحن نواجه مشكلة فنية , برجاء المحاولة فى وقت لاحق");
        });
    } else {
      alert("لايوجد شهادة معتمدة لهذه الدورة");
    }
  };
  handleSubmitAll = async () => {
    // get students
    const students = this.state.course.students;
    const canvasText = canvas.getObjects()[0];

    // await forEach
    await students.forEach((student) => {
      if (student.certificate === null) {
        // set text
        canvasText.text = `${student.first_name}`;
        canvas.renderAll();
        // set canvasToDataURL
        const certImg = canvas.toDataURL({
          format: "jpeg",
          multiplier: 3,
          quality: 1,
        });
        // setState images
        this.setState((prevState) => ({
          images: [...prevState.images, { id: student.id, image: certImg }],
        }));
      }
    });

    if (this.state.images.length > 0) {
      this.setState({ isLoading: true });
      axios
        .post("/cert/student/store", {
          images: this.state.images,
        })
        .then((res) => {
          this.fetchCourse();
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    } else {
      alert("جميع الطلاب حصلو على شهادات");
    }
  };
  handleActiveStudent = (student) => {
    if (typeof student.nationality === "string") {
      student.nationality = JSON.parse(student.nationality);
    }
    this.setState({ activeStudent: student });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      activeStudent: { ...prevState.activeStudent, [name]: value },
    }));
  };
  changeStudentData = (e) => {
    e.preventDefault();
    const activeStudent = this.state.activeStudent;
    this.setState({ isLoading: true });
    axios
      .put(`/account/student/${activeStudent.id}/edit`, activeStudent)
      .then((res) => {
        const newStudents = this.state.students.map((s) => {
          if (s.id === activeStudent.id) s = res.data;
          return s;
        });
        this.setState({ students: newStudents, isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  translateStatus = (status) => {
    switch (status) {
      case "pending":
        return <span className="badge badge-pill bg-primary">لم يدفع</span>;
      case "completed":
        return <span className="badge badge-pill bg-success">مكتمل الدفع</span>;
      default:
        return false;
    }
  };
  toggleCourseStatus = (id) => {
    const { is_active } = this.state.course;

    this.setState({ isLoading: true });
    axios
      .post(`/account/course/${id}/edit`, {
        is_active: Number(is_active) ? false : true,
      })
      .then((res) => {
        const newStatus = res.data.is_active;
        this.setState((prev) => ({
          course: { ...prev.course, is_active: newStatus },
          isLoading: false,
        }));
        toastr(
          "success",
          newStatus ? "تم فتح الدورة مجددا" : "تم اغلاق الدورة مجدداً"
        );
        console.log(res);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "");
      });
  };
  downloadFile = () => {
    const { id } = this.state.course;
    axios
      .get(`/account/excel/course/${id}`, {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.state.course.title}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  render() {
    const { title, start_date, end_date, cert, id, is_active } =
      this.state.course;
    const { activeStudent, students, isLoading, notFound } = this.state;

    return (
      <div className="container-lg container-fluid">
        {isLoading === true ? <Loader /> : null}
        {notFound ? (
          <div className="bg-white mx-auto col-md-6 shadow-sm rounded p-5 text-center mt-5">
            <h3 className={classes.Grey + " h4 bold_font mb-4"}>
              لايوجد دورات فى هذه الصفحة
            </h3>
            <Link to={"/account"} className={classes.Primary_btn}>
              العودة للحساب
            </Link>
          </div>
        ) : (
          <div>
            <div className="bg-white p-4 rouded shadow-sm mt-4">
              <div className="row align-items-center mb-3">
                <div className="col-md-3 mb-md-0 text-md-right text-center">
                  <div className="d-flex align-items-center mb-3">
                    <h1 className="medium_font h4 mb-0">{title}</h1>
                    {!Number(is_active) ? (
                      <h6 className="h5 mb-0">
                        <span className="badge rounded-pill bg-danger mr-2 mt-1 pb-1 pt-0">
                          مغلقة
                        </span>
                      </h6>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3 mb-md-0 mb-3 ">
                  <i className="fa fa-calendar ml-2"></i>
                  تبدء فى :{start_date}
                </div>
                <div className="col-md-3 mb-md-0 mb-3 ">
                  <i className="fa fa-calendar-times-o ml-2"></i>
                  تنتهى فى :{end_date}
                </div>
                <div className="col-md-3 text-md-left text-center">
                  <div className="dropdown">
                    <button
                      className={`dropdown-toggle ${classes.Primary_btn}`}
                      data-toggle="dropdown"
                    >
                      خيارات الدورة
                    </button>
                    <div className="dropdown-menu">
                      <Link
                        className="dropdown-item"
                        to={`/account/edit-course/${id}`}
                      >
                        تعديل
                      </Link>

                      <Link
                        className="dropdown-item"
                        to={`/account/messaging/${id}`}
                      >
                        إرسال رسالة للطلاب
                      </Link>

                      <button
                        onClick={() => copyUrl("course", id)}
                        className="dropdown-item"
                      >
                        مشاركة الدورة
                      </button>

                      <Link
                        to={`/account/add-certifcate/${id}`}
                        className={"dropdown-item"}
                      >
                        تعيين شهادة
                      </Link>

                      {/* {cert !== null ? (
                        <button
                          onClick={this.handleSubmitAll}
                          className="dropdown-item"
                        >
                          منح شهادة للكل
                        </button>
                      ) : null} */}

                      <button
                        onClick={this.toggleCourseStatus.bind(this, id)}
                        className={
                          "dropdown-item medium_font " +
                          (Number(is_active) ? "text-danger" : "text-success")
                        }
                      >
                        {Number(is_active) ? " اغلاق الدورة" : "فتح الدورة"}
                      </button>
                      <button class="dropdown-item" onClick={this.downloadFile}>
                        <i className="fa fa-download ml-2"></i>
                        تحميل ملف الدورة
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {cert === null ? (
                <div className="alert alert-warning">
                  لايوجد شهادة فى هذه الدورة حتى الان
                </div>
              ) : null}
              <div className="table-responsive">
                <table className="table" id="table-to-xls">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">الاسم بالكامل</th>
                      <th scope="col">البريد الإلكترونى</th>
                      <th scope="col">رقم الهاتف</th>
                      <th scope="col">اجابات الطالب</th>
                      <th scope="col">الشهادات</th>
                      <th scope="col">حالة الدفع</th>
                      <th scope="col">خيارات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students
                      ? students.map((e, i) => {
                          return (
                            <tr key={e.id}>
                              <th scope="row">{i + 1}</th>
                              <td>{e.first_name}</td>
                              <td>{e.email}</td>
                              <td>{e.phone}</td>
                              <td>
                                {e.nationality ? (
                                  <button
                                    type="button"
                                    onClick={this.handleActiveStudent.bind(
                                      this,
                                      e
                                    )}
                                    className={classes.Primary}
                                    data-toggle="modal"
                                    data-target="#answersModal"
                                  >
                                    الاجابات
                                  </button>
                                ) : (
                                  "لايوجد اجابات"
                                )}
                              </td>
                              <td>
                                {e.certificate ? "استلم الشهادة" : " لم يستلم"}
                              </td>
                              <td>
                                {e.student_order ? (
                                  this.translateStatus(e.student_order.status)
                                ) : (
                                  <span className="badge badge-pill bg-secondary">
                                    مجانى
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={this.handleActiveStudent.bind(
                                    this,
                                    e
                                  )}
                                  data-toggle="modal"
                                  data-target="#editModal"
                                  title={
                                    e.student_order &&
                                    e.student_order.status === "pending"
                                      ? "لايمكنك تعديل بيانات طالب لم يدفع"
                                      : null
                                  }
                                  className={[
                                    "light_btn",
                                    classes.Btn_Small,
                                    "ml-2",
                                  ].join(" ")}
                                >
                                  تحرير البيانات
                                </button>
                                <button
                                  className={
                                    this.props.plan ? classes.Primary : "d-none"
                                  }
                                  title={
                                    e.student_order &&
                                    e.student_order.status === "pending"
                                      ? "لايمكنك منح شهادة لطالب لم يدفع"
                                      : null
                                  }
                                  disabled={
                                    e.student_order &&
                                    e.student_order.status === "pending"
                                      ? true
                                      : false
                                  }
                                  onClick={this.certUpload.bind(
                                    this,
                                    e.id,
                                    e.first_name
                                  )}
                                >
                                  منح شهادة
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
              {students && students.length === 0 ? (
                <h3 className={classes.Grey + " text-center"}>
                  'لايوجد طلاب مشتركين حتى الان'
                </h3>
              ) : null}
              <div>
                <canvas
                  id="canvas"
                  className="w-100 d-none"
                  width="800"
                  height="600"
                ></canvas>
              </div>
            </div>
            <div
              className="modal fade"
              id="editModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <form onSubmit={this.changeStudentData}>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        تحرير بيانات الطالب
                      </h5>
                      <button
                        type="button"
                        className="close mr-auto ml-0"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="form-group mb-4">
                        <label
                          htmlFor="userName"
                          className={classes.Small_font}
                        >
                          الاسم بالكامل
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-user"}></i>
                          <input
                            type="text"
                            name="first_name"
                            value={activeStudent.first_name}
                            className={classes.FormControl}
                            id="userName"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <label
                          htmlFor="studentEmail"
                          className={classes.Small_font}
                        >
                          البريد الإلكترونى
                        </label>
                        <div className="position-relative">
                          <i
                            className={classes.Input_icon + " fa fa-envelope"}
                          ></i>
                          <input
                            type="email"
                            name="email"
                            value={activeStudent.email}
                            className={classes.FormControl}
                            id="studentEmail"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label
                          htmlFor="studentPhone"
                          className={classes.Small_font}
                        >
                          رقم الجوال
                        </label>
                        <div className="position-relative">
                          <i
                            className={classes.Input_icon + " fa fa-phone"}
                          ></i>
                          <input
                            type="tel"
                            name="phone"
                            value={activeStudent.phone}
                            className={classes.FormControl}
                            id="studentPhone"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label
                          htmlFor="studentPhone"
                          className={classes.Small_font}
                        >
                          حالة الدفع
                        </label>
                        <div className="position-relative">
                          <select
                            onChange={this.handleChange}
                            name="status"
                            className={classes.FormControl}
                          >
                            <option
                              value="completed"
                              selected={
                                activeStudent?.student_order?.status ===
                                "completed"
                                  ? true
                                  : false
                              }
                            >
                              تم الدفع
                            </option>
                            <option
                              value="pending"
                              selected={
                                activeStudent?.student_order?.status ===
                                "pending"
                                  ? true
                                  : false
                              }
                            >
                              جارى الدفع
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className={classes.Primary_btn}>
                        {isLoading ? (
                          <span>جارى التحديث ..</span>
                        ) : (
                          "حفظ البيانات"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="modal fade"
              id="answersModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      اجابات الطالب ({activeStudent.first_name})
                    </h5>
                    <button
                      type="button"
                      className="close mr-auto ml-0"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {activeStudent.nationality
                      ? activeStudent.nationality.map((a, i) => {
                          return (
                            <div className="single_student_answer">
                              <span>{i + 1}</span>
                              <div>
                                <h3 className="medium_font h6">{a.value}</h3>
                                <p className="mb-0">
                                  {Array.isArray(a.choosedAnswers)
                                    ? a.choosedAnswers.join(", ")
                                    : a.choosedAnswers}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="modal-footer">
                    <button data-dismiss="modal" className="btn btn-secondary">
                      اغلاق
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SingleCourse;
