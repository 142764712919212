const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
  },
  {
    name: "اسم البنك",
    selector: "bank_name",
    sortable: true,
  },
  {
    name: "صاحب الحساب",
    selector: "account_holder",
    sortable: true,
  },
  {
    name: "المبلغ",
    selector: "amount",
    sortable: true,
  },
  {
    name: "حالة الدفع",
    selector: "status",
    sortable: true,
  },
];

module.exports = { columns };
