import React from "react";
import classes from "../UI/UI.module.css";
import "./about.css";
import axios from "../../axios-api";
import { WOW } from "wowjs";
import { Component } from "react";
import Loader from "../UI/Loader/Loader";
import { Link } from "react-router-dom";

class SinglePage extends Component {
  state = {
    title: "",
    content: "",
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });
    this.fetchPages(params.pageId);
    wow.init();
  }
  fetchPages = (id) => {
    this.setState({ isLoading: true });
    axios
      .get(`/pages/${id}`)
      .then((res) => {
        const { title, content } = res.data;
        this.setState({ title, content, isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  componentWillReceiveProps(nextProps) {
    const id = nextProps.match.params.pageId;
    this.fetchPages(id);
  }

  render() {
    const { title, content, isLoading } = this.state;

    return (
      <div className="aboutWrapper mt-4">
        {isLoading ? <Loader /> : null}
        <div className="container-fluid container-lg">
          <div className="row align-items-center">
            <div className="col-md-6 mt-4 mx-auto wow fadeInUp">
              <div className="home_wrapper p-4 bg-white rounded shadow-sm">
                <h2 className="bold_font mb-4 h4 text-center">{title}</h2>

                <div
                  className={classes.Grey}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SinglePage;
