import React, { Component } from "react";
import classes from "../UI/UI.module.css";
import Loader from "../UI/Loader/Loader";
import axios from "../../axios-api";
import "react-toastify/dist/ReactToastify.css";
import "./Register.css";
import { WOW } from "wowjs";
import { Link } from "react-router-dom";
import OptionsContext from "../../Context/Options-context";

class Register extends Component {
  static contextType = OptionsContext;
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: "",
        email: "",
        phone: "",
        nationality: "",
        password: "",
        password_confirmation: "",
      },
      isLoading: false,
      errors: null,
    };
  }
  handleSubmit = (event) => {
    const {
      name,
      phone,
      email,
      password,
      password_confirmation,
      nationality,
    } = this.state.user;
    const registerInfo = {
      name,
      email,
      password,
      password_confirmation: password_confirmation,
      phone,
      nationality,
    };
    this.setState({ isLoading: true });
    axios
      .post("https://acplatform.net/api/auth/signup", registerInfo)
      .then((res) => {
        this.setState({ isLoading: false, submitted: true, errors: null });
        this.props.handleSuccessfulRegister(res.data);
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        this.setState({ errors: errors, isLoading: false });
      });

    event.preventDefault();
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };
  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });

    wow.init();
  }
  render() {
    const options = this.context;
    const registerImg = options.find((o) => o.key === "register_img");
    const pathName = `https://acplatform.net/storage/`;

    if (this.props.loggedInStatus) {
      this.props.history.push("/pricing-plans");
    }
    const { user, isLoading, errors } = this.state;
    return (
      <div
        className="registerWrapper mb-4"
        style={{
          backgroundImage: registerImg
            ? `url(${pathName + registerImg.value})`
            : null,
        }}
      >
        {isLoading === true ? <Loader /> : null}
        <div className="container-lg container-fluid">
          <div className="row align-items-">
            <div className="col-md-6 mr-auto wow fadeInUp">
              <div className="mt-5">
                <form className="px-0 px-md-3" onSubmit={this.handleSubmit}>
                  <h2 className="bold_font mb-4 h4">تسجيل حساب جديد</h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="userName"
                        >
                          الاسم
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-user"}></i>
                          <input
                            type="text"
                            name="name"
                            value={user.name}
                            className={classes.FormControl}
                            id="userName"
                            onChange={this.handleChange}
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="userEmail"
                        >
                          البريد الإلكترونى
                        </label>
                        <div className="position-relative">
                          <i
                            className={classes.Input_icon + " fa fa-envelope"}
                          ></i>
                          <input
                            type="email"
                            className={classes.FormControl}
                            name="email"
                            id="userEmail"
                            onChange={this.handleChange}
                            value={user.email}
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="userPhone"
                        >
                          رقم الجوال
                        </label>
                        <div className="position-relative">
                          <i
                            className={classes.Input_icon + " fa fa-phone"}
                          ></i>
                          <input
                            type="number"
                            className={classes.FormControl}
                            name="phone"
                            id="userPhone"
                            onChange={this.handleChange}
                            value={user.phone}
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="nation"
                        >
                          الجنسية
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-flag"}></i>
                          <input
                            type="text"
                            className={classes.FormControl}
                            name="nationality"
                            id="nation"
                            onChange={this.handleChange}
                            value={user.nationality}
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="userPw"
                        >
                          كلمة السر
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-key"}></i>
                          <input
                            type="password"
                            className={classes.FormControl}
                            id="userPw"
                            name="password"
                            onChange={this.handleChange}
                            value={user.password}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="userPwConfirm"
                        >
                          تأكيد كلمة السر
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-key"}></i>
                          <input
                            type="password"
                            className={classes.FormControl}
                            id="userPwConfirm"
                            name="password_confirmation"
                            onChange={this.handleChange}
                            value={user.password_confirmation}
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.password}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="agree"
                        className={classes.CustomCheckbox + " mb-4"}
                      >
                        <input type="checkbox" name="" id="agree" />
                        <span className="ml-2"></span>
                        اوافق على
                        <Link
                          className={classes.Primary + " mr-2"}
                          to="/page/11"
                        >
                          {" "}
                          الشروط والأحكام
                        </Link>
                      </label>
                    </div>

                    <div className="col-md-12">
                      <button type="submit" className={classes.Primary_btn}>
                        تسجيل حساب
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Register;
