import React from "react";
import logo from "../../../assets/imgs/logo.png";
import classes from "./Logo.module.css";
import { Link } from "react-router-dom";
const logoWrapper = (props) => {
  return (
    <Link className="navbar-brand  ml-md-auto" to={""}>
      <img
        className={classes.Logo}
        src={props.logo ? props.logo : logo}
        alt="شعار الموقع"
      />
    </Link>
  );
};

export default logoWrapper;
