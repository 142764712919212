import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import { copyUrl, toastr } from "../../../Helper/Helper";
import Loader from "../../UI/Loader/Loader";
import UserContext from "../../../Context/User-context";
import OptionsConsumer from "../../../Context/Options-context";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditTest extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      errors: null,
      image: null,
      is_can_repeate: false,
      exam_duration: "",
      fail_message: "",
      success_message: "",
      pass_degree_percentage: "",
      internal_exam: [],
      times: [],
      student_additional_payload: [],
      submitted: false,
      isLoading: false,
      multi_time: false,
    };
    this.fileRef = React.createRef();
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    const {
      match: { params },
    } = this.props;
    axios
      .get(`/account/exams/${params.testId}`)
      .then((res) => {
        console.log(res);
        const {
          title,
          content,
          exam_duration,
          pass_degree_percentage,
          questions,
          is_can_repeate,
          image,
          id,
          fail_message,
          times,
          multi_time,
          success_message,
          student_additional_payload,
        } = res.data;
        let student = JSON.parse(student_additional_payload);

        // times coverting
        const newTimes = times.map((t) => {
          t.startDate = new Date(t.date);
          t.endDate = t.end_date ? new Date(t.end_date) : null;
          t.time = new Date();
          return t;
        });
        this.setState({
          title,
          id,
          content,
          exam_duration,
          is_can_repeate: Number(is_can_repeate),
          pass_degree_percentage,
          fail_message,
          success_message,
          times: newTimes,
          multi_time,
          student_additional_payload:
            typeof student === "string" ? [] : student,
          internal_exam: questions,
          isLoading: false,
          image,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false, notFound: null });
      });
  }
  handleChange = (e, t) => {
    const { type, checked } = e.target;
    const isBoolean = type === "checkbox";
    if (t) {
      this.setState({
        [t]: e,
      });
    } else {
      this.setState({
        [e.target.name]: isBoolean ? checked : e.target.value,
      });
    }
  };
  handleDropzone = ({ meta, file }, status) => {
    this.setState({ image: file });
  };
  addField = (type, e) => {
    switch (type) {
      case "userData":
        return this.setState((prev) => ({
          student_additional_payload: prev.student_additional_payload
            ? [...prev.student_additional_payload, ""]
            : [""],
        }));
      case "text":
        return this.setState((prev) => ({
          internal_exam: [
            ...prev.internal_exam,
            {
              type: type,
              answers: [],
              question: "",
              degree: null,
              is_boolean: 1,
              boolean_answer: 1,
            },
          ],
        }));
      case "select":
        return this.setState((prev) => ({
          internal_exam: [
            ...prev.internal_exam,
            {
              type,
              is_boolean: 0,
              question: "",
              boolean_answer: 0,
              degree: null,
              answers: [
                {
                  answer: "",
                  is_true: 1,
                },
                {
                  answer: "",
                  is_true: 0,
                },
              ],
            },
          ],
        }));
      default:
        return false;
    }
  };
  addAnswer = (id) => {
    const { internal_exam } = this.state;
    const newFields = internal_exam.map((e, i) => {
      console.log(e);
      const answer = e.answers.length === 0 ? true : false;
      if (id === i) {
        e.answers.push({
          answer: "",
          is_true: answer,
        });
      }
      return e;
    });
    console.log(newFields);
    this.setState({ internal_exam: newFields });
  };
  handleInputChange = (id, e) => {
    const { name, value, files } = e.target;
    const { internal_exam, student_additional_payload } = this.state;
    if (name === "userData") {
      const newFields = student_additional_payload.map((e, i) => {
        if (id === i) e = value;
        return e;
      });
      this.setState({ student_additional_payload: newFields });
    } else {
      const newFields = internal_exam.map((e, i) => {
        if (id === i) {
          if (files && files.length) {
            console.log(files);
            e.image = files[0];
            e.delete_image = 0;
            e.imgSrc = URL.createObjectURL(files[0]);
          } else {
            e[name] = value;
          }
        }

        return e;
      });
      this.setState({ internal_exam: newFields });
    }
  };
  handleChangeStatus = ({ meta, file }, status) => {
    this.setState({ image: file });
  };
  handleInputDelete = (i, type, status) => {
    const { internal_exam, student_additional_payload, id, times } = this.state;
    console.log(type, times);
    if (type === "student") {
      student_additional_payload.splice(i, 1);
      this.setState({ internal_exam });
    } else if (type === "time") {
      times.splice(i, 1);
      this.setState({ times });
    } else if (status === "exist") {
      this.setState({ isLoading: true });
      axios
        .delete(`/account/questionDelete/${id}/${type}`)
        .then(() => {
          internal_exam.splice(i, 1);
          this.setState({ internal_exam, isLoading: false });
        })
        .catch((err) => {
          toastr("warning", "لم نتمكن من حذف السؤال");
        });
    } else if (status === "new") {
      internal_exam.splice(i, 1);
      this.setState({ internal_exam });
    }
  };
  handleAnswerChange = (questionId, answerId, event) => {
    const { internal_exam } = this.state;
    const newFields = internal_exam.map((e, index) => {
      if (questionId === index) {
        e.answers[answerId].answer = event.target.value;
      }
      return e;
    });
    this.setState({ internal_exam: newFields });
  };
  handleDeleteAnswer = (questionId, answerId) => {
    const { internal_exam } = this.state;
    const newFields = internal_exam.map((e, i) => {
      if (questionId === i) {
        e.answers.splice(answerId, 1);
      }
      return e;
    });
    this.setState({ internal_exam: newFields });
  };
  handleMultiple = (id, e) => {
    const { checked } = e.target;
    const { internal_exam } = this.state;

    if (checked) {
      internal_exam[id].multiple = true;
      this.setState({ internal_exam });
    } else {
      internal_exam[id].multiple = false;
      this.setState({ internal_exam });
    }
  };
  handleRightAnswer = (answerId, id, e) => {
    const { internal_exam } = this.state;
    internal_exam[id].answers.map((a) => (a.is_true = false));
    internal_exam[id].answers[answerId].is_true = true;
    this.setState({ internal_exam });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });

    e.preventDefault();
    const {
      student_additional_payload,
      title,
      content,
      image,
      id,
      fail_message,
      success_message,
      exam_duration,
      pass_degree_percentage,
      is_can_repeate,
      times,
      multi_time,
    } = this.state;
    const data = new FormData();
    data.append("title", title);
    if (student_additional_payload && student_additional_payload.length) {
      student_additional_payload.forEach((e, i) => {
        data.append(`student_additional_payload[${i}]`, e);
      });
    } else {
      data.append(`student_additional_payload`, null);
    }
    data.append("content", content);
    if (typeof image === "object" && image !== null) {
      data.append("image", image);
    }
    data.append("status", 1);
    data.append("is_can_repeate", is_can_repeate);
    data.append("exam_duration", Math.floor(exam_duration));
    data.append("fail_message", fail_message);
    data.append("success_message", success_message);
    data.append("pass_degree_percentage", pass_degree_percentage);
    data.append("multi_time", Math.floor(multi_time) ? 1 : 0);
    if (times?.length) {
      times.forEach((t, i) => {
        delete t.time;
        delete t.startDate;
        delete t.endDate;
        delete t.end;
        delete t.exam_id;
        delete t.id;
        for (const key in t) {
          data.append(`times[${i}][${key}]`, t[key]);
        }
      });
    }
    axios
      .post(`/account/exams/${id}`, data)
      .then((res) => {
        this.setState({ isLoading: false });
        toastr("success", "تم تعديل الاختبار بنجاح");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطأ اثناء التعديل , حاول مرة اخرى");
      });
    this.collectQuestions(e);
  };
  collectQuestions = (e) => {
    e.preventDefault();
    const { internal_exam, id } = this.state;
    // iterate through answers
    internal_exam.forEach((q, i) => {
      if (!q.id) {
        const data = new FormData();
        data.append("question", q.question);
        data.append("degree", q.degree);
        data.append("is_boolean", q.is_boolean ? 1 : 0);
        data.append("boolean_answer", q.boolean_answer ? 1 : 0);
        if (q.image && typeof q.image === "object") {
          data.append("image", q.image);
        }
        if (q.answers.length) {
          q.answers.forEach((a, aId) => {
            data.append(`answers[${aId}][answer]`, a.answer);
            data.append(`answers[${aId}][is_true]`, a.is_true ? 1 : 0);
          });
        }
        axios
          .post(`account/questionCreate/${id}`, data)
          .then((res) => console.log(res))
          .catch((err) => this.setState({ isLoading: false }));
      } else if (q.answers.length) {
        q.answers.forEach((a) => {
          const { image, question, degree, delete_image } = q;
          const { answer, is_true } = a;
          const data = new FormData();

          if (Math.floor(delete_image)) {
            data.append("delete_image", 1);
          } else if (image && typeof image === "object") {
            data.append("image", image);
          }

          data.append("question", question);
          data.append("degree", Math.floor(degree));
          data.append("answer", answer);
          data.append("is_true", is_true ? 1 : 0);
          axios
            .post(`account/questionUpdate/${id}/${q.id}/${a.id}`, data)
            .then((res) => {
              this.forceUpdate();
            })
            .catch((err) => this.setState({ isLoading: false }));
        });
      } else {
        const { image, question, degree, boolean_answer, delete_image } = q;
        const data = new FormData();
        if (Math.floor(delete_image)) {
          data.append("delete_image", 1);
        } else if (image && typeof image === "object") {
          data.append("image", image);
        }
        data.append("question", question);
        data.append("degree", Math.floor(degree));
        data.append("boolean_answer", Math.floor(boolean_answer));
        axios.post(`account/questionUpdate/${id}/${q.id}`, data).then((res) => {
          this.forceUpdate();
        });
        this.setState({ isLoading: false });
      }
    });
  };
  handleUpdate = () => {
    const { internal_exam, id } = this.state;
    internal_exam.map((i) => {
      return axios.post(`/exams/${id}/${i.id}`);
    });
  };
  handleTrueFalse = (id, type, e) => {
    const { internal_exam } = this.state;
    internal_exam[id].boolean_answer = type;
    this.setState({ internal_exam });
  };
  qImgDelete = (id, type) => {
    const { internal_exam } = this.state;
    internal_exam[id].image = null;
    internal_exam[id].imgSrc = null;
    internal_exam[id].delete_image = 1;
    this.forceUpdate();
  };
  repeatTimes = () => {
    const schedule = {
      startDate: new Date(),
      endDate: new Date(),
      date: new Date().toLocaleDateString("en-ZA"),
      end_date: new Date().toLocaleDateString("en-ZA"),
      time: new Date(),
      start_time: new Date().toLocaleTimeString("en-GB"),
      duration: null,
    };
    this.setState((prev) => ({ times: [...prev.times, schedule] }));
  };
  handleTimeChange = (id, e) => {
    const { times } = this.state;
    const { name, value } = e.target;
    times[id][name] = value;
    this.forceUpdate();
  };
  calendarChange = (id, type, date) => {
    const { times } = this.state;
    if (type === "start") {
      times[id].date = date.toLocaleDateString("en-ZA");
      times[id].startDate = date;
    } else if (type === "start_time") {
      times[id].time = date;
      times[id].start_time = date.toLocaleTimeString("en-GB");
    } else {
      times[id].end_date = date.toLocaleDateString("en-ZA");
      times[id].endDate = date;
    }
    this.forceUpdate();
  };
  render() {
    const path = "https://acplatform.net/storage/";
    const {
      match: { params },
    } = this.props;
    // adding
    const {
      title,
      pass_degree_percentage,
      exam_duration,
      internal_exam,
      content,
      errors,
      fail_message,
      success_message,
      is_can_repeate,
      student_additional_payload,
      isLoading,
      image,
      times,
      notFound,
      multi_time,
    } = this.state;
    return (
      <main className="course_wrapper">
        {isLoading ? <Loader /> : null}
        <div className="container-lg container-fluid">
          {notFound ? (
            <div className="bg-white mx-auto col-md-6 shadow-sm rounded p-5 text-center mt-5">
              <h3 className={classes.Grey + " h4 bold_font mb-4"}>
                لايوجد اختبارات فى هذه الصفحة
              </h3>
              <Link to={"/account"} className={classes.Primary_btn}>
                العودة للحساب
              </Link>
            </div>
          ) : (
            <div className=" mt-4">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h1 className="h5 bold_font mb-3">تعديل على {title}</h1>
                </div>
                <div className="col-md-6 text-md-left">
                  <button
                    onClick={() => copyUrl("quiz", params.testId)}
                    className={classes.Primary_btn + " mb-3"}
                  >
                    <i className="fa fa-copy ml-2"></i>
                    نسخ الرابط
                  </button>
                </div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-8 order-md-1 order-2">
                    <div className="bg-white rounded shadow-sm p-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="right_course_sidebar">
                            <img
                              src={path + image}
                              alt="test"
                              className="mb-2"
                            />
                            <div className="mb-3">
                              <Dropzone
                                onChangeStatus={this.handleDropzone}
                                onSubmit={this.handleUpload}
                                inputContent={
                                  <div>
                                    <i className="fa fa-upload ml-2"></i>
                                    رفع صورة الاختبار
                                  </div>
                                }
                                maxFiles="1"
                                accept="image/*"
                              />
                            </div>
                            <h3 className="medium_font h6 mb-4">
                              اسئلة الاختبار
                            </h3>
                            <button
                              type="button"
                              className={
                                classes.Transparent_btn + " mb-3 btn-block"
                              }
                              onClick={this.addField.bind(this, "userData")}
                            >
                              <i className="fa fa-plus ml-2"></i>
                              حقل بيانات
                            </button>
                            <button
                              type="button"
                              className={
                                classes.Transparent_btn + " mb-3 btn-block"
                              }
                              onClick={this.addField.bind(this, "select")}
                            >
                              <i className="fa fa-plus ml-2"></i>
                              اختر الاجابة
                            </button>
                            <button
                              type="button"
                              className={
                                classes.Transparent_btn + " mb-3 btn-block"
                              }
                              onClick={this.addField.bind(this, "text")}
                            >
                              <i className="fa fa-plus ml-2"></i>
                              صح ام خطأ
                            </button>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <h3 className="medium_font h6 mb-4">حقول الاختبار</h3>
                          {student_additional_payload?.map((e, i) => {
                            return (
                              <div className="custom_field" key={i}>
                                <span className="question-index">
                                  <i className="fa fa-user"></i>
                                </span>
                                <div className="row">
                                  <div className="col-md-10 col-9">
                                    <input
                                      type="text"
                                      name="userData"
                                      required
                                      value={e}
                                      className={classes.FormControl}
                                      placeholder="حقل بيانات ( مثال , السن , الجنسية )"
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                    <small className={classes.Grey}>
                                      يظهر هذا الحقل اثناء التسجيل فى الاختبار (
                                      بجانب الاسم , الايميل , رقم الجوال )
                                    </small>
                                  </div>
                                  <div className="col-md-2 col-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={this.handleInputDelete.bind(
                                        this,
                                        i,
                                        "student"
                                      )}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {internal_exam?.map((e, i) => {
                            return Number(e.is_boolean) ? (
                              <div className="custom_field" key={i}>
                                <span className="question-index">{i + 1}</span>
                                <div className="row g-2 mb-2">
                                  <div className="col-md-8 col-9">
                                    <textarea
                                      name="question"
                                      rows="2"
                                      style={{ height: 40 }}
                                      className={classes.FormControl}
                                      placeholder="سؤال صح ام خطأ"
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    >
                                      {e.question}
                                    </textarea>
                                  </div>
                                  <div className="col-md-2">
                                    <input
                                      type="number"
                                      required={true}
                                      name="degree"
                                      value={e.degree}
                                      className={classes.FormControl + " pl-2"}
                                      placeholder="درجة"
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-2 col-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={this.handleInputDelete.bind(
                                        this,
                                        i,
                                        e.id,
                                        e.id ? "exist" : "new"
                                      )}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                                <label className="small_font grey mb-2 mt-3">
                                  صورة السؤال (اختيارى)
                                </label>
                                <div className="row mb-2">
                                  <div className="col-md-9">
                                    {typeof e.image === "string" ? (
                                      <img
                                        width="40"
                                        src={path + e.image}
                                        alt="صورة السؤال"
                                      />
                                    ) : (
                                      <input
                                        type="file"
                                        name={i}
                                        className={
                                          classes.FormControl +
                                          " bg-white mr-auto"
                                        }
                                        onChange={this.handleInputChange.bind(
                                          this,
                                          i
                                        )}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3">
                                    {typeof e.image === "object" && e.image ? (
                                      <div className="preview_img">
                                        <img
                                          width="40"
                                          src={e.imgSrc}
                                          alt="preview"
                                        />
                                        <button
                                          className="text-danger mr-2"
                                          type="button"
                                          onClick={this.qImgDelete.bind(
                                            this,
                                            i,
                                            "notUp"
                                          )}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={this.qImgDelete.bind(this, i)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <label
                                  htmlFor={`true-${i}`}
                                  className={[
                                    classes.CustomCheckbox,
                                    classes.Radio,
                                    classes.Success,
                                    "ml-4",
                                  ].join(" ")}
                                >
                                  <input
                                    type="radio"
                                    name={`tf-${i}`}
                                    id={`true-${i}`}
                                    checked={
                                      Math.floor(e.boolean_answer)
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleTrueFalse.bind(
                                      this,
                                      i,
                                      true
                                    )}
                                  />
                                  <span className="ml-2"></span>
                                  صح
                                </label>
                                <label
                                  htmlFor={`false-${i}`}
                                  className={[
                                    classes.CustomCheckbox,
                                    classes.Radio,
                                    classes.Success,
                                    "ml-4",
                                  ].join(" ")}
                                >
                                  <input
                                    type="radio"
                                    name={`tf-${i}`}
                                    id={`false-${i}`}
                                    checked={
                                      !Math.floor(e.boolean_answer)
                                        ? true
                                        : false
                                    }
                                    onChange={this.handleTrueFalse.bind(
                                      this,
                                      i,
                                      false
                                    )}
                                  />
                                  <span className="ml-2"></span>
                                  خطأ
                                </label>
                              </div>
                            ) : (
                              <div className="custom_field" key={i}>
                                <span className="question-index">{i + 1}</span>
                                <div className="row g-2 align-items-center">
                                  <div className="col-md-8 col-9">
                                    <textarea
                                      name="question"
                                      rows="2"
                                      style={{ height: 40 }}
                                      required
                                      className={classes.FormControl}
                                      placeholder="اكتب السؤال هنا "
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    >
                                      {e.question}
                                    </textarea>
                                  </div>

                                  <div className="col-md-2">
                                    <input
                                      type="number"
                                      name="degree"
                                      required={true}
                                      value={e.degree}
                                      className={classes.FormControl + " pl-2"}
                                      placeholder="درجة"
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </div>

                                  <div className="col-md-2 col-3">
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={this.handleInputDelete.bind(
                                        this,
                                        i,
                                        e.id,
                                        e.id ? "exist" : "new"
                                      )}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                                <label className="small_font grey mb-2 mt-3">
                                  صورة السؤال (اختيارى)
                                </label>
                                <div className="row">
                                  <div className="col-md-9">
                                    {typeof e.image === "string" ? (
                                      <img
                                        width="40"
                                        src={path + e.image}
                                        alt="صورة السؤال"
                                      />
                                    ) : (
                                      <input
                                        type="file"
                                        name={i}
                                        className={
                                          classes.FormControl +
                                          " bg-white mr-auto"
                                        }
                                        onChange={this.handleInputChange.bind(
                                          this,
                                          i
                                        )}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3">
                                    {typeof e.image === "object" && e.image ? (
                                      <div className="preview_img">
                                        <img
                                          width="40"
                                          src={e.imgSrc}
                                          alt="preview"
                                        />
                                        <button
                                          className="text-danger mr-2"
                                          type="button"
                                          onClick={this.qImgDelete.bind(
                                            this,
                                            i,
                                            "notUp"
                                          )}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={this.qImgDelete.bind(this, i)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {e.answers.map((answer, answerId) => {
                                  return (
                                    <div key={answerId}>
                                      <span className="question-index">
                                        {i + 1}
                                      </span>
                                      <div className="row align-items-end mt-3 answer_wrapper">
                                        <div className="col-md-7 col-9">
                                          <input
                                            type="text"
                                            value={answer.answer}
                                            required
                                            className="answer_input"
                                            placeholder="اكتب الاجابة هنا"
                                            onChange={this.handleAnswerChange.bind(
                                              this,
                                              i,
                                              answerId
                                            )}
                                          />
                                        </div>
                                        <div className="col-md-3">
                                          <label
                                            htmlFor={`answerId-${i}${answerId}`}
                                            className={[
                                              classes.CustomCheckbox,
                                              classes.Radio,
                                              classes.Success,
                                              " mb-0 mt-2",
                                            ].join(" ")}
                                          >
                                            <input
                                              type="radio"
                                              id={`answerId-${i}${answerId}`}
                                              name={`answer-${i}`}
                                              defaultChecked={
                                                answer.is_true ? true : false
                                              }
                                              onChange={this.handleRightAnswer.bind(
                                                this,
                                                answerId,
                                                i,
                                                null
                                              )}
                                            />
                                            <span className="ml-2"></span>
                                            صحيحة
                                          </label>
                                        </div>
                                        <div className="col-md-2 col-3">
                                          <button
                                            onClick={this.handleDeleteAnswer.bind(
                                              this,
                                              i,
                                              answerId
                                            )}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}

                                <div>
                                  <button
                                    type="button"
                                    onClick={this.addAnswer.bind(this, i)}
                                    className="btn btn-secondary mt-3 mb-2"
                                  >
                                    <i className="fa fa-plus ml-2"></i>
                                    إجابة جديدة
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <OptionsConsumer>
                    {(options) => (
                      <div className="col-md-4 order-md-2 order-1 mb-4">
                        <div
                          className="bg-white p-4"
                          style={{ position: "sticky", top: "1rem" }}
                        >
                          <div className="course_main_info">
                            <h3 className="medium_font h6 mb-4">
                              حقول الاختبار الاساسية
                            </h3>
                            <div className="form-group mb-4">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                                htmlFor="courseName"
                              >
                                عنوان الاختبار
                              </label>
                              <div className="position-relative">
                                <i
                                  className={classes.Input_icon + " fa fa-book"}
                                ></i>
                                <input
                                  type="text"
                                  className={classes.FormControl}
                                  name="title"
                                  id="courseName"
                                  required={true}
                                  onChange={this.handleChange}
                                  value={title}
                                />
                              </div>
                              {errors != null ? (
                                <p className="text-danger small mb-0">
                                  {errors.email}
                                </p>
                              ) : null}
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group mb-4">
                                  <label
                                    className={[
                                      classes.Grey,
                                      classes.Small_font,
                                    ].join(" ")}
                                    htmlFor="courseName"
                                  >
                                    مدة الاختبار
                                    <small className="mr-1">( بالدقيقة )</small>
                                  </label>
                                  <div className="position-relative">
                                    <i
                                      className={
                                        classes.Input_icon + " fa fa-clock-o"
                                      }
                                    ></i>
                                    <input
                                      type="number"
                                      className={classes.FormControl}
                                      name="exam_duration"
                                      id="courseName"
                                      required={true}
                                      onChange={this.handleChange}
                                      value={exam_duration}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-4">
                                  <label
                                    className={[
                                      classes.Grey,
                                      classes.Small_font,
                                    ].join(" ")}
                                    htmlFor="quizMark"
                                  >
                                    درجة النجاح
                                    <small className="mr-1">
                                      ( نسبة مئوية )
                                    </small>
                                  </label>
                                  <div className="position-relative">
                                    <i
                                      className={
                                        classes.Input_icon + " fa fa-percent"
                                      }
                                    ></i>
                                    <input
                                      type="number"
                                      className={classes.FormControl}
                                      name="pass_degree_percentage"
                                      required={true}
                                      id="quizMark"
                                      onChange={this.handleChange}
                                      value={pass_degree_percentage}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="d-flex align-items-center">
                                  <label
                                    htmlFor="multiTime"
                                    className={classes.CustomCheckbox}
                                  >
                                    <input
                                      onChange={this.handleChange}
                                      type="checkbox"
                                      checked={
                                        Math.floor(multi_time) ? true : false
                                      }
                                      name="multi_time"
                                      id="multiTime"
                                    />
                                    <span className="ml-2"></span>
                                    تحديد ميعاد للاختبار
                                  </label>
                                  <button
                                    data-toggle="modal"
                                    data-target="#schedule"
                                    className={
                                      classes.Primary + " mr-auto underline"
                                    }
                                    type="button"
                                  >
                                    تعديل
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-4">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                                htmlFor="success_message"
                              >
                                رسالة النجاح
                                <small className="mr-1">
                                  ( تظهر امام الطالب )
                                </small>
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={classes.FormControl}
                                  name="success_message"
                                  id="success_message"
                                  onChange={this.handleChange}
                                  value={
                                    success_message === "null"
                                      ? null
                                      : success_message
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                                htmlFor="fail_message"
                              >
                                رسالة الرسوب
                                <small className="mr-1">
                                  ( تظهر امام الطالب )
                                </small>
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={classes.FormControl}
                                  name="fail_message"
                                  id="fail_message"
                                  onChange={this.handleChange}
                                  value={
                                    fail_message === "null"
                                      ? null
                                      : fail_message
                                  }
                                />
                              </div>
                            </div>

                            <div className="form-group mb-2">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                                htmlFor="courseContent"
                              >
                                تعليمات الاختبار
                              </label>
                              <div className="position-relative">
                                <i
                                  className={classes.Input_icon + " fa fa-book"}
                                ></i>
                                <textarea
                                  className={classes.FormControl}
                                  name="content"
                                  id="courseContent"
                                  onChange={this.handleChange}
                                  value={content}
                                ></textarea>
                              </div>
                              {errors != null ? (
                                <p className="text-danger small mb-0">
                                  {errors.email}
                                </p>
                              ) : null}
                            </div>
                            <div className="form-group mb-4">
                              <label
                                htmlFor="canRepeate"
                                className={classes.CustomCheckbox}
                              >
                                <input
                                  onChange={this.handleChange}
                                  type="checkbox"
                                  checked={is_can_repeate ? true : false}
                                  name="is_can_repeate"
                                  id="canRepeate"
                                />
                                <span className="ml-2"></span>
                                إتاحة إعادة الامتحان
                              </label>
                            </div>
                            <button
                              type="submit"
                              className={classes.Primary_btn + " btn-block"}
                            >
                              تعديل الاختبار
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </OptionsConsumer>
                </div>

                <div
                  className="modal fade"
                  id="schedule"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          مواعيد الاختبار
                        </h5>
                        <button
                          type="button"
                          className="close mr-auto ml-0"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {times.length ? (
                          times.map((t, i) => (
                            <div className="row align-items-end" key={i}>
                              <div className="col-md-3 mb-3">
                                <label className={classes.Grey + " small_font"}>
                                  التاريخ ( من )
                                </label>
                                <DatePicker
                                  className={classes.FormControl}
                                  selected={t.startDate}
                                  onChange={this.calendarChange.bind(
                                    this,
                                    i,
                                    "start"
                                  )}
                                  selectsStart
                                  minDate={t.startDate}
                                  startDate={t.startDate}
                                  dateFormat="yyyy/MM/dd"
                                />
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className={classes.Grey + " small_font"}>
                                  التاريخ ( الى )
                                </label>
                                <DatePicker
                                  className={classes.FormControl}
                                  selected={t.endDate}
                                  onChange={this.calendarChange.bind(
                                    this,
                                    i,
                                    "end"
                                  )}
                                  selectsEnd
                                  startDate={t.startDate}
                                  minDate={t.startDate}
                                  endDate={t.endDate}
                                  dateFormat="yyyy/MM/dd"
                                />
                              </div>
                              <div className="col-md-3 mb-3">
                                <label
                                  htmlFor={`start-time-${i}`}
                                  className={classes.Grey + " small_font"}
                                >
                                  وقت بدء الاختبار {t.start_time}
                                </label>
                                <DatePicker
                                  className={classes.FormControl}
                                  selected={t.time}
                                  onChange={this.calendarChange.bind(
                                    this,
                                    i,
                                    "start_time"
                                  )}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                />
                              </div>
                              <div className="col-md-2 mb-3">
                                <label
                                  htmlFor={`start-time-${i}`}
                                  className={classes.Grey + " small_font"}
                                >
                                  مدة الاختبار
                                  <small>(دقيقة)</small>
                                </label>
                                <input
                                  type="number"
                                  value={t.duration}
                                  name="duration"
                                  className={classes.FormControl}
                                  onChange={this.handleTimeChange.bind(this, i)}
                                />
                              </div>
                              <div className="col-md-1 mb-3">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger"
                                  onClick={this.handleInputDelete.bind(
                                    this,
                                    i,
                                    "time"
                                  )}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h3 className="medium_font h6 text-center grey mb-3">
                            لايوجد مواعيد محددة الان
                          </h3>
                        )}
                        <button
                          type="button"
                          onClick={this.repeatTimes}
                          className={classes.Primary_btn}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          ميعاد جديد
                        </button>
                      </div>
                      <div className="modal-footer">
                        <button
                          data-dismiss="modal"
                          className="btn btn-secondary"
                        >
                          اغلاق
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </main>
    );
  }
}

export default EditTest;
