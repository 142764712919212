import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import { toastr } from "../../../Helper/Helper";
import Loader from "../../UI/Loader/Loader";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "./EditAccount.css";
import { Link } from "react-router-dom";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      logos: [],
      password: "",
      nationality: "",
      password_confirmation: "",
      errors: null,
      isLoading: false,
      mainLogo: 0,
      cover_image: "",
      social: {},
    };
  }
  handleChange = (type, e) => {
    const { name, value } = e.target;
    if (type) {
      this.setState((prev) => {
        return {
          social: {
            ...prev.social,
            [name]: value,
          },
        };
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleChangeStatus = ({ meta, file }, status) => {
    const elm = document.getElementsByClassName("dzu-submitButton");
    if (elm.length) {
      elm[0].textContent = "اعتماد الشعار";
    }
  };

  handleCoverChange = ({ meta, file }, status) => {
    const elm = document.getElementsByClassName("dzu-submitButton");
    if (elm.length) {
      elm[0].textContent = "اعتماد الخلفية";
    }
  };

  mainLogoHandler = (e) => {
    if (e.target.checked) {
      this.setState({ mainLogo: 1 });
    } else {
      this.setState({ mainLogo: 0 });
    }
  };
  handleSubmit = (e) => {
    const { name, phone, email, nationality, password, password_confirmation } =
      this.state;
    const userData = {
      name,
      phone,
      email,
      nationality,
      password,
      password_confirmation,
    };
    this.setState({ isLoading: true });
    axios
      .post("account/update", userData)
      .then(() => {
        toastr("success", "تم تعديل البيانات بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
    e.preventDefault();
  };
  handleSocial = (e) => {
    this.setState({ isLoading: true });
    const { facebook, twitter, snap, linkedin, instagram, bio } =
      this.state.social;
    const socialUrls = {
      fa: facebook,
      tw: twitter,
      sn: snap,
      in: instagram,
      li: linkedin,
      bio,
    };
    e.preventDefault();
    axios
      .post("/account/update/social", socialUrls)
      .then(() => {
        toastr("success", "تم تعديل الروابط بنجاح");
        this.setState({ isLoading: false });
      })
      .catch(() => {
        toastr("warning", "حدث خطأ ما , حاول مرة اخرى");
        this.setState({ isLoading: false });
      });
  };
  handleUpload = (files, allFiles) => {
    const main = this.state.mainLogo;
    const { logos } = this.state;
    const body = new FormData();
    files.forEach((f) => {
      if (logos.length === 0) {
        body.append("main", 1);
      } else {
        body.append("main", main);
      }
      body.append("image", f.file);
    });
    this.setState({ isLoading: true });
    axios
      .post("/account/update/logos", body)
      .then((res) => {
        this.setState({
          isLoading: false,
          logos: res.data,
        });
        toastr("success", "تم رفع الشعار بنجاح");
        this.props.updateLogos(res.data);
        allFiles.forEach((f) => f.remove());
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطاً ما , حاول مرة اخرى");
      });
  };
  handleCoverUpload = (files, allFiles) => {
    const { nationality } = this.state;
    this.setState({ isLoading: true });
    const body = new FormData();
    files.forEach((f) => {
      body.append("cover_image", f.file);
      body.append("nationality", nationality);
    });
    axios
      .post("account/update", body)
      .then(() => {
        toastr("success", "تم تعديل البيانات بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };
  componentDidMount() {
    axios.get("/account").then((res) => {
      const { name, phone, email, nationality, logos, social, cover_image } =
        res.data;
      this.setState({
        name,
        phone,
        email,
        logos,
        nationality,
        social,
        cover_image,
      });
    });
  }
  updateLogo = (id) => {
    axios
      .put(`/account/update/logos/main/${id}`)
      .then((res) => {
        this.props.updateLogos();
        toastr("success", "تم تعديل الشعار بنجاح");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطأ ما , حاول مرة اخرى");
      });
  };
  deleteLogo = (id, type) => {
    if (type !== 1) {
      if (window.confirm("هل انت متأكد من حذف الشعار")) {
        this.setState({ isLoading: true });
        axios
          .delete(`/account/delete/logo/${id}`)
          .then((res) => {
            this.setState({ isLoading: false });
            this.props.updateLogos();
            toastr("success", "تم حذف الشعار بنجاح");
          })
          .catch((err) => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      toastr("warning", "لايمكنك حذف الشعار الاساسى");
    }
  };

  // put /account/update/logos/main/id
  render() {
    const path = "https://acplatform.net/storage/";
    const {
      errors,
      isLoading,
      name,
      email,
      phone,
      nationality,
      password,
      cover_image,
      password_confirmation,
      social,
    } = this.state;
    const logos = this.props.logos;
    return (
      <div className="container-lg container-fluid">
        {isLoading === true ? <Loader /> : null}
        <div className="row mt-4">
          <div className="col-md-7 mb-4">
            <div className=" bg-white p-4 shadow-sm rounded">
              <div className="d-flex align-items-center mb-3">
                <h1 className="h5 bold_font mb-0">تعديل بيانات المستخدم</h1>
                <Link
                  to={`/trainer/${this.props.id}`}
                  className={[
                    classes.Transparent_btn,
                    classes.Btn_Small,
                    " mr-auto",
                  ].join(" ")}
                >
                  <i className="fa fa-eye ml-2"></i>
                  صفحتى
                </Link>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        اسم المستخدم
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-user"}></i>
                        <input
                          type="text"
                          className={classes.FormControl}
                          name="name"
                          id="userName"
                          onChange={this.handleChange.bind(this, null)}
                          value={name}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.name}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        البريد الإلكترونى
                      </label>
                      <div className="position-relative">
                        <i
                          className={classes.Input_icon + " fa fa-envelope"}
                        ></i>
                        <input
                          type="email"
                          className={classes.FormControl}
                          name="email"
                          id="userEmail"
                          onChange={this.handleChange.bind(this, null)}
                          value={email}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        رقم الجوال
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-phone"}></i>
                        <input
                          type="tel"
                          className={classes.FormControl}
                          name="phone"
                          id="userNum"
                          onChange={this.handleChange.bind(this, null)}
                          value={phone}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        الجنسية
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-flag"}></i>
                        <input
                          type="text"
                          className={classes.FormControl}
                          name="nationality"
                          id="nationality"
                          onChange={this.handleChange.bind(this, null)}
                          value={nationality}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        كلمة السر القديمة
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-key"}></i>
                        <input
                          type="password"
                          className={classes.FormControl}
                          name="passowrd"
                          id="userPw"
                          onChange={this.handleChange.bind(this, null)}
                          value={password}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">
                          {errors.password}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="courseName"
                      >
                        تغيير كلمة السر
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-phone"}></i>
                        <input
                          type="tel"
                          className={classes.FormControl}
                          name="phone"
                          id="userNum"
                          onChange={this.handleChange.bind(this, null)}
                          value={password_confirmation}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <button type="submit" className={classes.Primary_btn}>
                  تعديل البيانات
                </button>
              </form>
            </div>
          </div>

          <div className="col-md-5 mb-md-0 mb-4">
            <div className="p-4 bg-white rounded shadow-sm mb-4">
              <h3 className="medium_font h5 mb-3">رفع شعار جديد</h3>

              <Dropzone
                onChangeStatus={this.handleChangeStatus}
                onSubmit={this.handleUpload}
                inputContent={
                  <div>
                    <i className="fa fa-upload ml-2"></i>
                    ارفع شعارك هنا
                  </div>
                }
                maxFiles="1"
                accept="image/*"
              />
              <label htmlFor="mainLogo" className="mt-3">
                <input
                  id="mainLogo"
                  type="checkbox"
                  onChange={this.mainLogoHandler}
                />
                <span className="mr-2">الشعار الاساسى</span>
              </label>
            </div>
            <div className="p-4 bg-white rounded shadow-sm mb-4">
              <div className="d-flex align-items-center mb-3">
                <h3 className="medium_font h5 mb-3">خلفية صفحتى الشخصية</h3>
                {cover_image ? (
                  <div className="mr-auto">
                    <img
                      className="rounded"
                      width="40"
                      src={path + cover_image}
                      alt="صورة الخلفية"
                    />
                  </div>
                ) : null}
              </div>
              <Dropzone
                onChangeStatus={this.handleCoverChange}
                onSubmit={this.handleCoverUpload}
                inputContent={
                  <div>
                    <i className="fa fa-upload ml-2"></i>
                    ارفق صورة الخلفية هنا
                  </div>
                }
                maxFiles="1"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="bg-white p-4 rounded shadow-sm mb-4">
            <h3 className="medium_font h5 mb-3">وسائل التواصل</h3>
            <form onSubmit={this.handleSocial}>
              <div className="row row-cols-1 row-cols-lg-5 g-2">
                <div className="col">
                  <label
                    htmlFor="facebook"
                    className={[classes.Grey, classes.Small_font].join(" ")}
                  >
                    فيس بوك
                  </label>
                  <div className="position-relative">
                    <i className={classes.Input_icon + " fa fa-facebook"}></i>
                    <input
                      type="url"
                      className={classes.FormControl}
                      name="facebook"
                      id="facebook"
                      onChange={this.handleChange.bind(this, "social")}
                      value={social?.facebook}
                    />
                  </div>
                </div>

                <div className="col">
                  <label
                    htmlFor="twitter"
                    className={[classes.Grey, classes.Small_font].join(" ")}
                  >
                    تويتر
                  </label>
                  <div className="position-relative">
                    <i className={classes.Input_icon + " fa fa-twitter"}></i>
                    <input
                      type="url"
                      className={classes.FormControl}
                      name="twitter"
                      id="twitter"
                      onChange={this.handleChange.bind(this, "social")}
                      value={social?.twitter}
                    />
                  </div>
                </div>

                <div className="col">
                  <label
                    htmlFor="instagram"
                    className={[classes.Grey, classes.Small_font].join(" ")}
                  >
                    انستغرام
                  </label>
                  <div className="position-relative">
                    <i className={classes.Input_icon + " fa fa-instagram"}></i>
                    <input
                      type="url"
                      className={classes.FormControl}
                      name="instagram"
                      id="instagram"
                      onChange={this.handleChange.bind(this, "social")}
                      value={social?.instagram}
                    />
                  </div>
                </div>

                <div className="col">
                  <label
                    htmlFor="linkedin"
                    className={[classes.Grey, classes.Small_font].join(" ")}
                  >
                    لينكد ان
                  </label>
                  <div className="position-relative">
                    <i className={classes.Input_icon + " fa fa-linkedin"}></i>
                    <input
                      type="url"
                      className={classes.FormControl}
                      name="linkedin"
                      id="linkedin"
                      onChange={this.handleChange.bind(this, "social")}
                      value={social?.linkedin}
                    />
                  </div>
                </div>

                <div className="col">
                  <label
                    htmlFor="snapchat"
                    className={[classes.Grey, classes.Small_font].join(" ")}
                  >
                    سناب شات
                  </label>
                  <div className="position-relative">
                    <i
                      className={classes.Input_icon + " fa fa-snapchat-ghost"}
                    ></i>
                    <input
                      type="url"
                      className={classes.FormControl}
                      name="snap"
                      id="snapchat"
                      onChange={this.handleChange.bind(this, "social")}
                      value={social?.snap}
                    />
                  </div>
                </div>
              </div>
              <label
                htmlFor="myBio"
                className={[classes.Grey, classes.Small_font, "mt-3 "].join(
                  " "
                )}
              >
                نبذة عنك
              </label>
              <div className="position-relative mb-3">
                <textarea
                  rows="1"
                  className={classes.FormControl}
                  name="bio"
                  id="myBio"
                  onChange={this.handleChange.bind(this, "social")}
                  value={social?.bio}
                ></textarea>
              </div>

              <button className={classes.Primary_btn}>تعديل</button>
            </form>
          </div>
        </div>
        <div className="col-md-12 mb-4">
          <h3 className="medium_font h5 mb-3">شعارات العضو</h3>
          <div className="bg-white p-3 pb-0 shadow-sm rounded">
            <div className="row">
              {logos.length ? (
                logos.map((cert, i) => {
                  return (
                    <div className="col-md-3  mb-3" key={i}>
                      <div className="userLogo">
                        <img
                          className="mb-2"
                          src={cert.logo}
                          alt="شعار المستخدم"
                        />
                        {Math.floor(cert.main) ? (
                          <span>
                            <i className="fa fa-check ml-2"></i> الاساسى
                          </span>
                        ) : null}
                        <button
                          className="text-primary ml-3"
                          onClick={this.updateLogo.bind(this, cert.id)}
                        >
                          شعار اساسى
                        </button>
                        <button
                          className="text-danger"
                          onClick={this.deleteLogo.bind(
                            this,
                            cert.id,
                            cert.main
                          )}
                        >
                          حذف الشعار
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3
                  className={classes.Grey + " h5 medium_font text-center pb-3"}
                >
                  لايوجد شعارات حتى الان
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAccount;
