import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import { Redirect } from "react-router-dom";
import HijriDatePicker from "hijri-date-picker";
import { toastr } from "../../../Helper/Helper";
import Loader from "../../UI/Loader/Loader";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import UserContext from "../../../Context/User-context";
import OptionsConsumer from "../../../Context/Options-context";
import "./addcourse.css";

class AddCourse extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      start_date: "",
      end_date: "",
      image: "",
      errors: null,
      fields: [],
      submitted: false,
      isLoading: false,
      price_status: 0,
      cert_price_status: 0,
      price: "",
      cert_price: "",
    };
  }
  handleChange = (e, type) => {
    if (type) {
      this.setState({
        [type]: e,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  addField = (type, e) => {
    switch (type) {
      case "input":
        return this.setState((prev) => ({
          fields: [
            ...prev.fields,
            { type, value: "", required: false, answers: [] },
          ],
        }));
      case "text":
        return this.setState((prev) => ({
          fields: [...prev.fields, { type: type, value: "" }],
        }));
      case "image":
        return this.setState((prev) => ({
          fields: [...prev.fields, { type: type, image: {} }],
        }));
      case "select":
        return this.setState((prev) => ({
          fields: [
            ...prev.fields,
            {
              type,
              value: "",
              answers: [],
              multiple: false,
              required: false,
              choosedAnswers: [],
            },
          ],
        }));
      default:
        return false;
    }
  };
  addAnswer = (id) => {
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (id === i) e.answers.push("");
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleInputChange = (id, evt) => {
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (id === i) {
        if (evt.target.files?.length) {
          e.image = evt.target.files[0];
        } else {
          e.value = evt.target.value;
        }
      }
      return e;
    });
    this.setState({ fields: newFields });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    this.setState({ image: file });
  };
  handleInputDelete = (id) => {
    const { fields } = this.state;
    fields.splice(id, 1);
    this.setState({ fields });
  };
  handleAnswerChange = (questionId, answerId, event) => {
    const { fields } = this.state;
    const newFields = fields.map((e, index) => {
      if (questionId === index) {
        e.answers[answerId] = event.target.value;
      }
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleDeleteAnswer = (questionId, answerId) => {
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (questionId === i) {
        e.answers.splice(answerId, 1);
      }
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleMultiple = (id, e) => {
    const { checked } = e.target;
    const { fields } = this.state;

    if (checked) {
      fields[id].multiple = true;
      this.setState({ fields });
    } else {
      fields[id].multiple = false;
      this.setState({ fields });
    }
  };
  handleRequired = (id, e) => {
    const { checked } = e.target;
    const { fields } = this.state;

    if (checked) {
      fields[id].required = true;
      this.setState({ fields });
    } else {
      fields[id].required = false;
      this.setState({ fields });
    }
  };
  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked ? 1 : 0 });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      content,
      start_date,
      end_date,
      fields,
      image,
      price_status,
      cert_price_status,
      cert_price,
      price,
    } = this.state;

    const courseData = {
      title,
      content: content.split(/\n/).join("<br/>"),
      start_date,
      end_date,
      data: fields,
      is_active: 1,
      price_status,
      cert_price,
      cert_price_status,
    };
    console.log(courseData);
    if (price_status) {
      courseData.price = price;
    }

    const brocureData = new FormData();
    brocureData.append("image", image);

    this.setState({ isLoading: true });

    axios
      .post(`/account/course`, courseData)
      .then((res) => {
        const id = res.data.id;
        if (typeof image === "string") {
          return res;
        } else {
          return axios.post(`/account/course/${id}/edit`, brocureData);
        }
      })
      .then((res) => {
        toastr("success", "تم إنشاء الدورة بنجاح");
        this.props.updateCourses();
        this.props.history.push(`/account/edit-course/${res.data.id}`);
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };
  handleUploadImage = (e) => {
    e.preventDefault();
    const { fields } = this.state;

    const newFields = fields.map((e, i) => {
      if (e.image) {
        this.setState({ isLoading: true });
        const images = new FormData();
        images.append(`images[]`, e.image);
        axios
          .post("/account/uploadSingleImage", images)
          .then((res) => {
            e.image = res.data[0];
            this.setState({ isLoading: false });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            toastr("warning", "لم يتم رفع الصورة , حاول مرة اخرى");
          });
      }
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleDeleteImg = (id) => {
    const { fields } = this.state;
    const image = fields[id].image;
    this.setState({ isLoading: true });
    axios
      .post("/account/detroySingleImage", { images: [image] })
      .then((res) => {
        fields.splice(id, 1);
        this.setState({ isLoading: false, fields });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  showCommision = (options) => {
    const minWithObj = options.find((o) => o.key === "commission_percent");
    return minWithObj.value;
  };
  render() {
    const canPay = Number(this.context.can_sell_online);
    // adding
    const {
      title,
      fields,
      content,
      start_date,
      end_date,
      errors,
      isLoading,
      price_status,
      cert_price_status,
      price,
      cert_price,
      submitted,
    } = this.state;
    return (
      <main className="course_wrapper">
        <div className="container-lg container-fluid">
          {submitted === true ? (
            <Redirect from="/account/add-course" to="/account" />
          ) : null}
          {isLoading === true ? <Loader /> : null}
          <div className=" mt-4">
            <h1 className="h5 bold_font mb-3">إنشاء دورة جديدة</h1>
            <div className="row gx-2">
              <div className="col-md-8 order-md-1 order-2">
                <div className="bg-white rounded shadow-sm p-md-4">
                  {!canPay ? (
                    <div
                      className="alert alert-info alert-dismissible fade show py-2"
                      role="alert"
                    >
                      يمكنك مراسلة الإدارة لتفعيل بيع الدورات اونلاين
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-4">
                      <div className="right_course_sidebar">
                        <h3 className="medium_font h6 mb-4">
                          إضافة حقول للدورة
                        </h3>
                        <label>صورة الدورة</label>
                        <div className="mb-3">
                          <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            onSubmit={this.handleUpload}
                            inputContent={
                              <div>
                                <i className="fa fa-upload ml-2"></i>
                                رفع بروشور الدورة
                              </div>
                            }
                            maxFiles="1"
                            accept="image/*"
                          />
                        </div>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "input")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة حقل نصى
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "select")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة سؤال
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "image")}
                        >
                          <i className="fa fa-image ml-2"></i>
                          إضافة صورة
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "text")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة عبارة نصية
                        </button>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <h3 className="medium_font h6 mb-4">
                        حقول الدورة المخصصة
                      </h3>
                      {fields.map((e, i) => {
                        return e.type === "input" || e.type === "image" ? (
                          <div className="custom_field" key={i}>
                            <div className="row">
                              <div className="col-md-10 col-9">
                                <input
                                  type={e.type === "input" ? "text" : "file"}
                                  value={e.type === "input" ? e.value : null}
                                  className={classes.FormControl}
                                  placeholder="اكتب اسم الحقل هنا "
                                  onChange={this.handleInputChange.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>
                              <div className="col-md-2 col-3">
                                <button
                                  className="btn btn-outline-danger"
                                  onClick={
                                    typeof e.image === "string"
                                      ? this.handleDeleteImg.bind(this, i)
                                      : this.handleInputDelete.bind(this, i)
                                  }
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            </div>

                            {e.type === "input" ? (
                              <label
                                htmlFor={`required` + i}
                                className={
                                  classes.CustomCheckbox + " mb-0 mt-2"
                                }
                              >
                                <input
                                  type="checkbox"
                                  id={`required` + i}
                                  checked={e.required}
                                  onChange={this.handleRequired.bind(this, i)}
                                />
                                <span className="ml-2"></span>
                                حقل مطلوب
                              </label>
                            ) : typeof e.image === "string" ? (
                              <span className="text-success">
                                <i className="fa fa-check ml-2"></i>تم الرفع
                                بنجاح
                              </span>
                            ) : (
                              <button
                                className={classes.Primary}
                                onClick={this.handleUploadImage}
                              >
                                <i className="fa fa-upload ml-2"></i>
                                رفع
                              </button>
                            )}
                          </div>
                        ) : e.type === "text" ? (
                          <div className="custom_field" key={i}>
                            <div className="row">
                              <div className="col-md-10 col-9">
                                <input
                                  type="text"
                                  value={e.value}
                                  className={classes.FormControl}
                                  placeholder="اكتب اسم العبارة النصية هنا "
                                  onChange={this.handleInputChange.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>
                              <div className="col-md-2 col-3">
                                <button
                                  className="btn btn-outline-danger"
                                  onClick={this.handleInputDelete.bind(this, i)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="custom_field" key={i}>
                            <div className="row">
                              <div className="col-md-10 col-9">
                                <input
                                  type="text"
                                  value={e.value}
                                  className={classes.FormControl}
                                  placeholder="اكتب السؤال هنا "
                                  onChange={this.handleInputChange.bind(
                                    this,
                                    i
                                  )}
                                />
                              </div>

                              <div className="col-md-2 col-3">
                                <button
                                  className="btn btn-outline-danger"
                                  onClick={this.handleInputDelete.bind(this, i)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                            {e.answers.map((answer, answerId) => {
                              return (
                                <div key={answerId}>
                                  <div className="row align-items-end mt-3 answer_wrapper">
                                    <div className="col-md-10 col-9">
                                      <input
                                        type="text"
                                        value={answer}
                                        className="answer_input"
                                        placeholder="اكتب الاجابة هنا"
                                        onChange={this.handleAnswerChange.bind(
                                          this,
                                          i,
                                          answerId
                                        )}
                                      />
                                    </div>
                                    <div className="col-md-2 col-3">
                                      <button
                                        onClick={this.handleDeleteAnswer.bind(
                                          this,
                                          i,
                                          answerId
                                        )}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                            <div>
                              <button
                                onClick={this.addAnswer.bind(this, i)}
                                className="btn btn-secondary mt-3 mb-2"
                              >
                                <i className="fa fa-plus ml-2"></i>
                                إجابة جديدة
                              </button>
                            </div>
                            <div>
                              <label
                                htmlFor={`multiple` + i}
                                className={
                                  classes.CustomCheckbox +
                                  " mb-0 d-md-inline-flex d-flex"
                                }
                              >
                                <input
                                  type="checkbox"
                                  id={`multiple` + i}
                                  checked={e.multiple}
                                  onChange={this.handleMultiple.bind(this, i)}
                                />
                                <span className="ml-2"></span>
                                سؤال متعدد الاجابات
                              </label>

                              <label
                                htmlFor={`required` + i}
                                className={
                                  classes.CustomCheckbox + " mr-md-3 mb-0"
                                }
                              >
                                <input
                                  type="checkbox"
                                  id={`required` + i}
                                  checked={e.required}
                                  onChange={this.handleRequired.bind(this, i)}
                                />
                                <span className="ml-2"></span>
                                سؤال مطلوب
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <OptionsConsumer>
                {(options) => (
                  <div className="col-md-4 order-md-2 order-1 mb-4">
                    <div className="bg-white p-4">
                      <div className="course_main_info">
                        <h3 className="medium_font h6 mb-4">
                          حقول الدورة الاساسية
                        </h3>
                        <form onSubmit={this.handleSubmit}>
                          <div className="form-group mb-4">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                              ].join(" ")}
                              htmlFor="courseName"
                            >
                              اسم الدورة
                            </label>
                            <div className="position-relative">
                              <i
                                className={classes.Input_icon + " fa fa-book"}
                              ></i>
                              <input
                                type="text"
                                className={classes.FormControl}
                                name="title"
                                id="courseName"
                                onChange={this.handleChange}
                                value={title}
                                required
                              />
                            </div>
                            {errors != null ? (
                              <p className="text-danger small mb-0">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>

                          <div className="form-group mb-4">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                              ].join(" ")}
                              htmlFor="startDate"
                            >
                              تاريخ بدء الدورة (اختيارى)
                            </label>
                            <div className="position-relative">
                              <i
                                className={
                                  classes.Input_icon + " fa fa-calendar"
                                }
                              ></i>
                              <HijriDatePicker
                                onChange={(e, type) =>
                                  this.handleChange(e, "start_date")
                                }
                                inputName="start_date"
                                value={start_date}
                                className={classes.FormControl}
                                dateFormat="iYYYY/iMM/iDD"
                              />
                            </div>
                            {errors != null ? (
                              <p className="text-danger small mb-0">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>

                          <div className="form-group mb-4">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                              ].join(" ")}
                              htmlFor="endDate"
                            >
                              تاريخ انتهاء الدورة (اختيارى)
                            </label>
                            <div className="position-relative">
                              <i
                                className={
                                  classes.Input_icon + " fa fa-calendar-times-o"
                                }
                              ></i>
                              <HijriDatePicker
                                onChange={(e, type) =>
                                  this.handleChange(e, "end_date")
                                }
                                inputName="start_date"
                                value={end_date}
                                className={classes.FormControl}
                                dateFormat="iYYYY/iMM/iDD"
                              />
                            </div>
                            {errors != null ? (
                              <p className="text-danger small mb-0">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>

                          {this.context.can_sell_online ? (
                            <div className="mb-2">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                  " d-block",
                                ].join(" ")}
                              ></label>
                              <div className="row gx-2 align-items-center mb-2">
                                <div className="col-5">
                                  <label
                                    htmlFor="courseMoney"
                                    className={classes.CustomCheckbox}
                                  >
                                    <input
                                      type="checkbox"
                                      id="courseMoney"
                                      onChange={this.handleCheckbox}
                                      name="price_status"
                                    />
                                    <span className="ml-2"></span>
                                    دورة مدفوعة
                                  </label>
                                </div>
                                <div className="col-7">
                                  {price_status ? (
                                    <div className="position-relative mr-2">
                                      <input
                                        type="number"
                                        className={classes.FormControl}
                                        name="price"
                                        id="courseName"
                                        onChange={this.handleChange}
                                        value={price}
                                        placeholder="سعر الدورة"
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row gx-2 align-items-center">
                                <div className="col-5">
                                  <label
                                    htmlFor="priceStatus"
                                    className={classes.CustomCheckbox}
                                  >
                                    <input
                                      type="checkbox"
                                      id="priceStatus"
                                      onChange={this.handleCheckbox}
                                      name="cert_price_status"
                                    />
                                    <span className="ml-2"></span>
                                    شهادة مدفوعة
                                  </label>
                                </div>
                                <div className="col-7">
                                  {cert_price_status ? (
                                    <div className="position-relative mr-2">
                                      <input
                                        type="number"
                                        className={classes.FormControl}
                                        name="cert_price"
                                        onChange={this.handleChange}
                                        value={cert_price}
                                        placeholder="سعر الشهادة"
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {price_status || cert_price_status ? (
                            <div className="mb-3">
                              <small className={classes.Grey}>
                                نسبة المنصة من بيع الدورة او الشهادة هو{" "}
                                {this.showCommision(options)}% شاملة لضريبة
                                القيمة المضافة
                              </small>
                            </div>
                          ) : null}
                          <div className="form-group mb-4">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                              ].join(" ")}
                              htmlFor="disabled"
                            >
                              (الاسم , البريد الإلكترونى , رقم الجوال)
                            </label>
                            <div className="position-relative">
                              <input
                                type="text"
                                className={classes.FormControl + " small"}
                                name="x"
                                id="disabled"
                                disabled={true}
                                onChange={this.handleChange}
                                value={
                                  "هذه الحقول موجودة بالفعل فى صفحة التسجيل بالدورة"
                                }
                              />
                            </div>
                            {errors != null ? (
                              <p className="text-danger small mb-0">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>

                          <div className="form-group mb-4">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                              ].join(" ")}
                              htmlFor="courseContent"
                            >
                              محتوى الدورة
                            </label>
                            <div className="position-relative">
                              <i
                                className={classes.Input_icon + " fa fa-book"}
                              ></i>
                              <textarea
                                className={classes.FormControl}
                                name="content"
                                id="courseContent"
                                onChange={this.handleChange}
                                value={content}
                              ></textarea>
                            </div>
                            {errors != null ? (
                              <p className="text-danger small mb-0">
                                {errors.email}
                              </p>
                            ) : null}
                          </div>

                          <button
                            type="submit"
                            className={classes.Primary_btn + " btn-block"}
                          >
                            إضافة الدورة
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </OptionsConsumer>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default AddCourse;
