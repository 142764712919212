import React, { Component } from "react";
import logo from "../../../src/assets/imgs/logo.png";
import classes from "../UI/UI.module.css";
import { Link as a, Link } from "react-router-dom";
import { WOW } from "wowjs";
import OptionsContext from "../../Context/Options-context";
import axios from "../../axios-api";
import Loader from "../../components/UI/Loader/Loader";
import Pricing from "../Pricing/Pricing";
import "./HomePage.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import searchBg from "../Trainer/trainerBg.jpg";

class HomePage extends Component {
  static contextType = OptionsContext;
  state = {
    options: [],
    sliders: [],
    services: [],
    pages: {},
    counters: {},
    searchWord: "",
    faqActive: 0,
    catParents: [],
    results: [],
  };

  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });

    wow.init();
    this.setState({ isLoading: true });

    axios
      .get("/sliders")
      .then((res) => {
        this.setState({ sliders: res.data, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCounters();
    this.getServices();
    this.getCats();
    this.getPage();
    document.querySelector(".whats_link").classList.remove("d-none");
  }
  getCounters = () => {
    this.setState({ isLoading: true });
    axios
      .get("/counters")
      .then((res) => this.setState({ counters: res.data, isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };
  getServices = () => {
    axios.get("/services").then((res) => this.setState({ services: res.data }));
  };
  getCats = () => {
    this.setState({ isLoading: true });
    axios
      .get("/services/category/parents")
      .then((res) => {
        this.setState({ catParents: res.data, isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  getPage = () => {
    axios.get("/pages/menus").then((res) => {
      this.setState({ pages: res.data });
    });
  };
  componentWillUnmount() {
    document.querySelector(".whats_link").classList.add("d-none");
  }
  scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  handleChange = (e) => {
    this.setState({ searchWord: e.target.value });
    if (e.target.value.length > 2) {
      axios
        .get(`/search/services?s=${e.target.value}`)
        .then((res) => this.setState({ results: res.data.data }))
        .catch((err) => this.setState({ isLoading: false }));
    }
  };
  toggleFaq = (id) => {
    this.setState({ faqActive: id });
  };

  render() {
    const {
      isLoading,
      counters,
      sliders,
      services,
      pages,
      searchWord,
      results,
      faqActive,
      catParents,
    } = this.state;
    const options = this.context;
    const socialMedia = options.filter((o) => {
      return (
        o.key === "facebook" || o.key === "twitter" || o.key === "instagram"
      );
    });
    const homeOptions = options.filter((o) => {
      return (
        o.key === "home_title" ||
        o.key === "home_subtitle" ||
        o.key === "home_call_to_action"
      );
    });

    return (
      <React.Fragment>
        <div className="loginWrapper">
          {isLoading ? <Loader /> : null}
          <div className="row g-0 align-items-center">
            {sliders.length ? (
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  {sliders.map((s, id) => (
                    <div
                      key={id}
                      className={
                        "carousel-item " + (id === 1 ? "active" : null)
                      }
                      data-interval="3000"
                    >
                      <div className="col-md-12 mx-auto">
                        <div className="home_wrapper  text-center">
                          {s.url !== "null" ? (
                            <a href={s.url}>
                              <img
                                className="w-100"
                                src={s.image}
                                alt="شعار المدرب العربى"
                                width="300"
                              />
                            </a>
                          ) : (
                            <img
                              className=" w-100"
                              src={s.image}
                              alt="شعار المدرب العربى"
                              width="300"
                            />
                          )}
                          {/* <div className="wow fadeInUp">
                            <h2 className="bold_font mb-3 h5">{s.title}</h2>
                            <p className="mb-0">
                              {JSON.parse(s.desc) ? s.desc : null}
                            </p>
                            <a
                              href={s.url}
                              className={classes.Primary_btn + " mt-4"}
                            >
                              {s.url_title}
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <h3 className="h5 mb-3 mb-4 bold_font mt-4 text-center">
          الخدمات المساندة للمدرب العربي
        </h3>
        <div className="container-lg container-fluid">
          <div className="row d-md-flex d-none">
            {catParents.map((c, i) => (
              <div className="col-md-3">
                <div className="product_cat mb-4">
                  <Link
                    to={`/category/${c.id}`}
                    className="d-block position-relative"
                  >
                    <img src={c.full_path ? c.full_path : logo} alt={c.title} />
                    <h3 className="h5 medium_font">{c.title}</h3>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div
            id="carouselCategory"
            className="carousel slide d-block d-md-none"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {catParents.map((cat, i) => (
                <div
                  key={i}
                  className={"carousel-item" + (!i ? " active" : " ")}
                  data-interval="3000"
                >
                  <div className="product_cat mb-4">
                    <Link
                      to={`/category/${cat.id}`}
                      className="d-block position-relative"
                    >
                      <img
                        src={cat.full_path ? cat.full_path : logo}
                        alt="category-1"
                      />
                      <h3 className="h5 medium_font">{cat.title}</h3>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselCategory"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselCategory"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div className="text-center our_services">
          <div className="container-lg container-fluid">
            <h3 className="h4 mb-3 bold_font">خدمات المنصة</h3>

            <div className="row justify-content-between wow fadeInUp d-none d-md-flex">
              {services.map((s, i) => (
                <div
                  key={i}
                  className="col-lg-2 col-md-3 mb-md-0 mb-3 col-sm-6"
                >
                  <div className="service_block">
                    <i className={"fa " + s.icon}></i>
                    <h3 className="medium_font h6 mb-0">{s.title}</h3>
                    <div className="service_desc">{s.description}</div>
                  </div>
                </div>
              ))}
            </div>
            {services.length ? (
              <div
                id="services-carousel"
                className="carousel slide d-md-none d-block"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  {services.map((s, id) => (
                    <div
                      key={id}
                      className={
                        "carousel-item " + (id === 1 ? "active" : null)
                      }
                      data-interval="3000"
                    >
                      <div className="service_block">
                        <i className={"fa " + s.icon}></i>
                        <h3 className="medium_font h6 mb-2">{s.title}</h3>
                        <p className="small_font grey">{s.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <a
                  className="carousel-control-prev"
                  href="#services-carousel"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#services-carousel"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            ) : null}

            <div className="position-relative d-md-block d-none">
              <div className="border_bottom"></div>
              <div className="row justify-content-center mt-4">
                <div className="col-md-3">
                  <span className="step-circle"></span>
                </div>
                <div className="col-md-3">
                  <span className="step-circle"></span>
                </div>
                <div className="col-md-3">
                  <span className="step-circle"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search section */}
        {/* <div
          className="home_search"
          style={{ backgroundImage: `url(${searchBg})` }}
        >
          <form>
            <div className="col-md-6 mx-auto p-0 position-relative">
              <h3 className="h5 mb-4 medium_font text-white text-center">
                ابحث عن الخدمة التى تحتاجها
              </h3>
              <div className="search_field">
                <i className="fa fa-search"></i>
                <input
                  value={searchWord}
                  type="search"
                  onChange={this.handleChange}
                  placeholder="تصميم شهادة او شعار"
                />
                <div
                  className={
                    "search_results_wrapper" +
                    (searchWord.length > 2 ? " d-block" : " ")
                  }
                >
                  {results.length ? (
                    results.map((s, i) => (
                      <li key={i}>
                        <Link to={`/services/${s.id}`}>{s.title}</Link>
                      </li>
                    ))
                  ) : (
                    <li className="grey text-center pt-3">
                      لايوجد خدمات بهذه الكلمة{" "}
                    </li>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div> */}

        {/* <h3 className="h5 mb-3 mb-4 bold_font mt-4 text-center">
          الاسئلة الشائعة
        </h3>
        <div className="container lg container-fluid">
          {new Array(3).fill(2).map((f, i) => (
            <div
              className={"faq_single" + (faqActive === i ? " active" : "")}
              onClick={this.toggleFaq.bind(this, i)}
            >
              <h3 className="h6 medium_font">ماهى منصة المدرب العربى</h3>
              <p className="faq_desc small_font grey">
                خمسات هو أكبر سوق عربي لبيع وشراء الخدمات المصغرة. يجمع خمسات
                بين الشباب العربي الموهوب المستعد لتقديم خبراته ومهاراته على شكل
                خدمات مصغرة بأسعار تبدأ من 5 دولار، وبين الأفراد ورواد الأعمال
                الذين يحتاجون هذه الخدمات لتساعدهم في تنمية وتطوير أعمالهم.
              </p>
            </div>
          ))}
        </div>
         */}
        <div className="bg-white pb-4">
          <Pricing />
        </div>
        <div className="our_services">
          <div className="container-lg container-fluid">
            <h3 className="h5 bold_font mb-4 text-center">إحصائيات</h3>
            <div className="row wow fadeInUp">
              <div className="col-md-3 col-6 mb-md-0 mb-3">
                <div className="counter_block">
                  <i className="fa fa-shopping-cart"></i>
                  {counters.orders ? (
                    <CountUp start={0} redraw={true} end={counters.orders}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <div className="number">
                            <span ref={countUpRef} />
                          </div>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  ) : null}
                  <h3 className="medium_font">عمليات الشراء</h3>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-md-0 mb-3">
                <div className="counter_block">
                  <i className="fa fa-user-plus"></i>
                  {counters.trainers ? (
                    <CountUp start={0} redraw={true} end={counters.trainers}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <div className="number">
                            <span ref={countUpRef} />
                          </div>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  ) : null}
                  <h3 className="medium_font">عدد المدربين</h3>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-md-0 mb-3">
                <div className="counter_block">
                  <i className="fa fa-users"></i>
                  {counters.courseStudents ? (
                    <CountUp
                      start={0}
                      redraw={true}
                      end={counters.courseStudents}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <div className="number">
                            <span ref={countUpRef} />
                          </div>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  ) : null}
                  <h3 className="medium_font">عدد الطلاب</h3>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-md-0 mb-3">
                <div className="counter_block">
                  <i className="fa fa-id-badge"></i>
                  {counters.courseCertificates ? (
                    <CountUp
                      start={0}
                      redraw={true}
                      end={counters.courseCertificates}
                    >
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <div className="number">
                            <span ref={countUpRef} />
                          </div>
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  ) : null}
                  <h3 className="medium_font">عدد الشهادات</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="bg-white py-3">
          <div className="container-lg container-fluid">
            <div className="row">
              <div className="col-lg-8 col-md-6 text-md-right text-center">
                <img src={logo} width="200" alt="شعار المنصة" />
                <div className="mt-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[0].value}` : null}
                    className="social_link mr-md-3 mr-3"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[1].value}` : null}
                    className="social_link mr-md-3 mr-3"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[2].value}` : null}
                    className="social_link mr-md-3 mr-3"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <h3 className="h6 medium_font">روابط المنصة</h3>
                <ul>
                  <li className="mb-2">
                    <Link
                      className={classes.Primary + " medium_font small_font"}
                      to="/"
                    >
                      الرئيسية
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className={classes.Primary + " medium_font small_font"}
                      to="/login"
                    >
                      تسجيل دخول
                    </Link>
                  </li>
                  {pages &&
                    pages.outside?.map((p, i) => (
                      <li className="mb-2" key={i}>
                        <Link
                          className={
                            classes.Primary + " medium_font small_font"
                          }
                          to={`/page/${p.id}`}
                        >
                          {p.title}
                        </Link>
                      </li>
                    ))}
                  <li className="mb-2">
                    <Link
                      className={classes.Primary + " medium_font small_font"}
                      to="/pricing-plans"
                    >
                      خطط الاسعار
                    </Link>
                  </li>
                  {pages &&
                    pages.dropdown?.map((p, i) => (
                      <li className="mb-2" key={i}>
                        <Link
                          className={
                            classes.Primary + " medium_font small_font"
                          }
                          to={`/page/${p.id}`}
                        >
                          {p.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <div className="text-center">
          <div className="small_font py-2">
            جميع الحقوق محفوظة لمنصة المدرب العربى
          </div>
        </div>
        <button className="scrollTop" onClick={this.scrollTop}>
          <i className="fa fa-arrow-up"></i>
        </button>
      </React.Fragment>
    );
  }
}

export default HomePage;
