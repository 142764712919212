import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import avatar from "../../../assets/imgs/user_avatar.jpg";
import { UserConsumer } from "../../../Context/User-context";
import { siteUrl, toastr } from "../../../Helper/Helper";

export default class SingleChat extends Component {
  state = {
    msgs: [],
    images: [],
    msg: "",
    title: "",
    desc: "",
    rate: 1,
  };
  handleChangeStatus = ({ meta, file }, status) => {
    const { images } = this.state;
    if (status === "done") {
      images.push(file);
      this.forceUpdate();
    } else if (status === "removed") {
      const newImages = images.filter((f) => f.name !== file.name);
      this.setState({ images: newImages });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    this.fetchChat();
  }
  fetchChat = () => {
    this.setState({ isLoading: true });

    const {
      match: { params },
    } = this.props;
    const search = this.props.location.search;
    const pageParam = new URLSearchParams(search);
    const type = pageParam.get("type");

    this.setState({ type });
    axios
      .get(this.getUrl(type, params.chatId))
      .then((res) => this.setState({ msgs: res.data, isLoading: false }))
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };
  getUrl = (type, id) => {
    if (type === "orderMsg") {
      return `/services/order/messages/${id}`;
    } else if (type === "serviceMsg") {
      return `/services/messages/${id}`;
    } else if (type === "ticket") {
      return `/tickets/${id}`;
    }
  };
  handleSubmit = (e) => {
    const { msg, msgs, images } = this.state;
    const epURL = msgs.service
      ? `/services/store/messages/${msgs.id}`
      : `/services/orders/messsage/${msgs.id}`;
    const data = new FormData();

    data.append("message", msg);
    images.forEach((p, i) => data.append(`images[${i}]`, p));

    this.setState({ isLoading: true });
    e.preventDefault();
    axios
      .post(epURL, data)
      .then((res) => {
        msgs.items.push(res.data);
        this.setState({ isLoading: false, msg: "" });
        this.forceUpdate();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  ticketReplay = (e) => {
    e.preventDefault();
    const { id } = this.state.msgs;
    const { msg } = this.state;
    this.setState({ isLoading: true });
    axios
      .post(`/tickets/${id}/replay`, { desc: msg })
      .then((res) => {
        this.setState({ isLoading: false });
        toastr("success", "تم الارسال بنجاح");
      })
      .catch((err) => {
        toastr("warning", err.response.data.errors.desc[0]);
        this.setState({ isLoading: false });
      });
  };
  handleDeliever = (e) => {
    const {
      order: { id, delivery_request },
    } = this.state.msgs;
    const epURL = delivery_request
      ? `/services/order/action/delivery/${id}`
      : `/services/order/delivery/${id}`;
    this.setState({ isLoading: true });
    e.preventDefault();
    axios
      .post(epURL)
      .then((res) => this.setState({ isLoading: false }))
      .catch((err) => this.setState({ isLoading: false }));
  };
  generateLabelColor = (status) => {
    switch (status) {
      case "needs_action":
        return "bg-primary";
      case "active":
        return "bg-success";
      case "done":
        return "bg-secondary";
      case "canceled":
        return "bg-danger";

      default:
        break;
    }
  };
  submitTicket = (e) => {
    const {
      msgs: { service_order_id },
      title,
      desc,
    } = this.state;
    const data = {
      title,
      desc,
      service_order_id,
    };
    this.setState({ isLoading: true });
    e.preventDefault();
    axios
      .post(`/tickets/store`, data)
      .then((res) => {
        toastr("success", "تم ارسال التذكرة بنجاح");
        this.setState({ isLoading: false });
        document.querySelector(".modalCloser").click();
      })
      .catch(() => this.setState({ isLoading: false }));
  };
  handleRate = (e) => {
    const {
      msgs: { service_order_id },
      rate,
      rate_desc,
    } = this.state;
    e.preventDefault();

    this.setState({ isLoading: true });
    const data = {
      rate,
      rate_desc,
    };
    this.setState({ isLoading: true });
    axios
      .post(`/services/order/rate/${service_order_id}`, data)
      .then((res) => {
        toastr("success", "تم التقييم بنجاح");
        if (res.errors === "order_already_rated") {
          toastr("warning", "هذا الطلب مقيم بالفعل");
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.fetchChat();
    }
  }
  render() {
    const {
      isLoading,
      msgs,
      msg,
      msgs: { order, service },
    } = this.state;

    return (
      <React.Fragment>
        {isLoading ? <Loader /> : null}
        {/* service head  */}
        <div className="service_head">
          <div className="container-lg container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <img
                    className="rounded-circle ml-2"
                    src="https://avatars.hsoubcdn.com/2e765771c14c6a4e2d53bfaf0d7bd345?s=128"
                    width="64"
                    alt="ahmed"
                  />
                  <div>
                    <h3 className="h6 medium_font">
                      {msgs.rate ? msgs.title : null}
                      {order ? (
                        <span
                          className={
                            "badge rounded-pill pt-0 bg-primary ml-2 " +
                            this.generateLabelColor(order.status)
                          }
                        >
                          {order?.status_label}
                        </span>
                      ) : null}
                      <Link
                        className="primary"
                        to={`/services/${
                          order ? order?.service_id : service?.id
                        }`}
                      >
                        {service?.title}
                        {order?.title}
                      </Link>
                    </h3>
                    <div>
                      {order?.created_humans}
                      {msgs.created_at_human ? (
                        <span className="ml-3 grey">
                          <i className="fa fa-clock-o ml-2"></i>
                          {msgs.created_at_human}
                        </span>
                      ) : null}

                      {service ? (
                        <span className="ml-3 grey">
                          <i className="fa fa-info ml-2"></i>
                          {service.category?.title}
                        </span>
                      ) : null}

                      {order ? (
                        <span className="ml-3 grey">
                          <i className="fa fa-ticket ml-2"></i>
                          {order.status_label}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {order ? (
                <div className="col-md-6 text-md-left text-center mt-3 mt-md-0">
                  <button
                    data-toggle="modal"
                    data-target="#ticketModal"
                    className={classes.Primary_btn}
                  >
                    فتح تذكرة
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* single chat components  */}
        <UserConsumer>
          {(user) => (
            <div className="container-lg container-fluid">
              <div className="bg-white mt-4 mb-4">
                {msgs?.items?.map((m, i) => (
                  <div className="px-3 py-4 border-bottom" key={i}>
                    <div className="d-flex align-items-center">
                      <img
                        className="rounded-circle ml-2"
                        src={
                          m.user_id === msgs.user_id
                            ? "https://avatars.hsoubcdn.com/2e765771c14c6a4e2d53bfaf0d7bd345?s=128"
                            : avatar
                        }
                        width="64"
                        alt="ahmed"
                      />
                      <div>
                        <h3 className="h6 medium_font">
                          {m.user_id !== user.id ? (
                            <Link
                              className="primary"
                              to={`/trainer/${m.user_id}`}
                            >
                              {msgs.user?.name}
                            </Link>
                          ) : (
                            "Me"
                          )}
                        </h3>
                        <div>
                          <span className="ml-3 grey">
                            <i className="fa fa-clock-o ml-2"></i>
                            {m.created_at_human}
                          </span>
                        </div>
                      </div>
                    </div>

                    <article className="mt-2">
                      {m.message}
                      {m.desc ? m.desc : null}
                      <div className="mt-2 d-flex">
                        {m.attachments?.map((a, index) => (
                          <a
                            href={siteUrl + a.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-3"
                          >
                            <img
                              width="72"
                              className="rounded"
                              src={siteUrl + a.path}
                              alt={`مرفق ${index + 1}`}
                            />
                          </a>
                        ))}
                      </div>
                    </article>
                  </div>
                ))}
                <form
                  className="p-3 mt-3"
                  onSubmit={
                    this.state.pageParam === "ticket"
                      ? this.ticketReplay.bind(this)
                      : this.handleSubmit.bind(this)
                  }
                >
                  <textarea
                    name="msg"
                    onChange={this.handleChange}
                    className={classes.FormControl + " mb-3"}
                    rows="4"
                    value={msg}
                    required
                  ></textarea>
                  <div>
                    {this.state.pageParam !== "ticket" ? (
                      <button
                        className={classes.Transparent_btn + " mb-3 small_font"}
                        data-toggle="collapse"
                        data-target="#attachCollapse"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        type="button"
                      >
                        <i className="fa fa-upload ml-2"></i>
                        إرفاق مرفقات
                      </button>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div class="collapse mb-3" id="attachCollapse">
                        <Dropzone
                          key="2"
                          onChangeStatus={this.handleChangeStatus}
                          inputContent={
                            <div key="3">
                              <i className="fa fa-upload ml-2"></i>
                              <span>اسحب الملفات او انقر هنا للارفاق</span>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <button className={classes.Primary_btn}>ارسل الرسالة</button>
                </form>
              </div>

              {order && order.status !== "done" ? (
                <div className="bg-white mt-4 mb-4">
                  <div className="border-bottom px-4 py-3 h6 medium_font mb-0">
                    إدارة الطلب
                  </div>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-md-6">
                        الحالة : {msgs.order?.status_label}
                      </div>
                      <div className="col-md-6 text-md-left">
                        <form onSubmit={this.handleDeliever}>
                          <button className={classes.Transparent_btn}>
                            تسليم الخدمة
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {order && order.status === "done" ? (
                <div className="bg-white mt-4 mb-4">
                  <div className="border-bottom px-4 py-3 h6 medium_font mb-0">
                    تقييم الطلب
                  </div>
                  <div className="p-4">
                    <div className="row">
                      <div className="col-md-6">
                        <form onSubmit={this.handleRate}>
                          <label htmlFor="rate">التقييم</label>
                          <select
                            onChange={this.handleChange}
                            type="text"
                            name="rate"
                            id="rate"
                            required
                            className={classes.FormControl + " mb-4"}
                          >
                            {Array.from(Array(5).keys()).map((e, i) => (
                              <option key={i} value={e + 1} selected={false}>
                                {e + 1}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor="rate_desc"
                            className="grey small_font"
                          >
                            وصف التقييم
                          </label>
                          <textarea
                            id="rate_desc"
                            name="rate_desc"
                            className={classes.FormControl}
                            row="4"
                            required
                            placeholder="وصف التقييم"
                            onChange={this.handleChange}
                          ></textarea>
                          <button className={classes.Transparent_btn + " mt-4"}>
                            تقييم الطلب
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {order ? (
                <div
                  className="modal fade"
                  id="ticketModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <form onSubmit={this.submitTicket}>
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            فتح تذكرة جديدة
                          </h5>
                        </div>
                        <div className="modal-body">
                          <label htmlFor="title" className="grey small_font">
                            عنوان التذكرة
                          </label>
                          <input
                            type="text"
                            name="title"
                            className={classes.FormControl + " mb-4"}
                            placeholder="عنوان التذكرة"
                            onChange={this.handleChange}
                          />
                          <label htmlFor="msg" className="grey small_font">
                            الرسالة
                          </label>
                          <textarea
                            name="desc"
                            className={classes.FormControl}
                            row="4"
                            required
                            placeholder="وصف التذكرة"
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="modalCloser"
                          ></button>
                          <button type="submit" className={classes.Primary_btn}>
                            إرسال
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </UserConsumer>
      </React.Fragment>
    );
  }
}
