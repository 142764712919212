import React, { Component } from "react";
import UserContext from "../../../Context/User-context";
import { Link } from "react-router-dom";
import classes from "../../UI/UI.module.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class Orders extends Component {
  static contextType = UserContext;

  checkStatus = (status) => {
    switch (status) {
      case "pending":
        return "تحت المراجعة";
      case "completed":
        return "مكتمل";
      case "canceled":
        return "ملغى";
      default:
        return;
    }
  };
  render() {
    const { orders, service_orders } = this.context;
    return (
      <React.Fragment>
        <div className="container-lg container-fluid mt-4">
          <Tabs>
            <TabList className="d-flex justify-content-center mb-0">
              <Tab>الخطط</Tab>
              <Tab>الطلبات الواردة</Tab>
            </TabList>

            <TabPanel>
              <div className="bg-white p-4">
                <h1 className="medium_font h4 text-center mb-3">طلبات الخطط</h1>
                <div className="table-responsive-sm">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>الخطة المطلوبة</th>
                        <th>عدد الشهادات</th>
                        <th>السعر</th>
                        <th>حالة الطلب</th>
                        <th>التفاصيل والدفع</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orders.map((o, i) => {
                        return (
                          <tr>
                            <td>{o.id}</td>
                            <td>{o.plan?.title}</td>
                            <td>{o.plan?.cert_count}</td>
                            <td>{`${o.plan?.price} ر.س`}</td>
                            <td>{this.checkStatus(o.status)}</td>
                            <td>
                              <Link
                                className={
                                  classes.Transparent_btn +
                                  " " +
                                  classes.Btn_Small
                                }
                                to={`/account/orders/${o.id}`}
                              >
                                الدفع والتفاصيل
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="bg-white p-4">
                <h1 className="medium_font h4 text-center mb-3">
                  الطلبات الواردة
                </h1>
                <div className="table-responsive-sm">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>الخدمة</th>
                        <th>الكمية</th>
                        <th>حالة الطلب</th>
                        <th>السعر</th>
                        <th>التفاصيل والدفع</th>
                      </tr>
                    </thead>

                    <tbody>
                      {service_orders?.map((o, index) => {
                        return (
                          <tr key={index}>
                            <td>{o.id}</td>
                            <td>{o.title}</td>
                            <td>{o.qty}</td>
                            <td></td>
                            <td>{o.total_price}</td>
                            <td>{o.status_label}</td>
                            <td>
                              <Link
                                className={
                                  classes.Transparent_btn +
                                  " " +
                                  classes.Btn_Small
                                }
                                to={`/account/orders/${o.id}`}
                              >
                                الدفع والتفاصيل
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
export default Orders;
