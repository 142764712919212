import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotifyPopup extends Component {
  render() {
    const { id, order } = this.props.service;
    return (
      <div
        className="modal fade"
        id="notifyModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                إشعار جديد
              </h5>
              <button
                type="button"
                className="close mr-auto ml-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h3 className="h5 medium_font">
                <Link to={`/account/chat/${id}?type=orderMsg`}>
                  {order?.title}
                </Link>
              </h3>
            </div>
            <div className="modal-footer">
              <button data-dismiss="modal" className="btn btn-secondary">
                اغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
