import { toast } from "react-toastify";
import avatar from "../assets/imgs/Male-Avatar.png";

export const copyUrl = function (path, id) {
  console.log(path, id);
  const absoultePath = window.location.hostname;
  const courseURL = `${absoultePath}/${path}/${id}`;
  const copyElm = document.createElement("input");
  copyElm.value = courseURL;
  document.body.appendChild(copyElm);
  copyElm.select();
  document.execCommand("copy");
  document.body.removeChild(copyElm);

  toastr("success", "تم نسخ الرابط بنجاح");
};

export const toastr = function (type, msg) {
  toast(msg, {
    type,
    position: "top-left",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export const siteUrl = "https://acplatform.net/storage/";

export const getMainImg = (imgs) => {
  const img = imgs.find((img) => img.main);
  if (img) {
    return img.logo;
  } else {
    return avatar;
  }
};

export const paypalParams = (search) => {
  const payment = search.get("paymentId");
  const token = search.get("token");
  const payerId = search.get("PayerID");
  const canceled = search.get("canceled");
  return payment || canceled ? { canceled, payment, token, payerId } : false;
};
