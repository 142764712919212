import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import Loader from "../../UI/Loader/Loader";
import DataTable from "react-data-table-component";
import { tableStyle } from "../../UI/DataTable/TableStyle";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import printJS from "print-js";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
  },
  {
    name: "اسم الطالب",
    selector: "name",
    sortable: true,
  },
  {
    name: "البريد الإلكترونى",
    selector: "email",
    sortable: true,
  },
  {
    name: "الدرجة",
    selector: "degree",
    sortable: true,
  },
  {
    name: "النسبة المئوية",
    selector: "degree_percentage",
    sortable: true,
  },
  {
    name: "الإجابات",
    selector: "options",
    sortable: false,
  },
];

class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      students: [],
      results: [],
      activeStudent: {},
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    const {
      match: { params },
    } = this.props;
    axios
      .get(`/account/exams/${params.testId}`)
      .then((res) => {
        const { title, students, id } = res.data;
        const newStudents = students.map((s) => {
          const { degree_percentage, degree } = s;
          const { id, name, email } = s.student;
          const student = {
            id,
            name,
            email,
            degree: (
              <span
                style={{ minWidth: 44 }}
                className={
                  "badge badge-pill pt-0 " +
                  (degree > 0 ? "bg-success" : "bg-danger")
                }
              >
                {degree ? degree : "0"}
              </span>
            ),
            degree_percentage: (
              <span
                style={{ minWidth: 44 }}
                className={
                  "badge badge-pill pt-0 " +
                  (degree_percentage > 0 ? "bg-success" : "bg-danger")
                }
              >
                {`${degree_percentage ? degree_percentage : "0"}%`}
              </span>
            ),
            options: (
              <button
                data-tip="عرض الإجابات"
                data-toggle="modal"
                data-target="#studentAnswers"
                onClick={() => this.setActiveStudent(id)}
                className="btn btn-outline-dark"
              >
                <i className="fa fa-eye"></i>
              </button>
            ),
          };
          return student;
        });
        this.setState({ title, students: newStudents, id, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
    this.fetchAnswers();
  }
  fetchAnswers = () => {
    const {
      match: { params },
    } = this.props;
    axios
      .get(`/account/exams/${params.testId}/students`)
      .then((res) => this.setState({ isLoading: false, results: res.data }));
  };
  setActiveStudent = (id) => {
    const { results } = this.state;
    const activeStudent = results.find((e) => e.exam_student_id == id);
    this.setState({ activeStudent });
  };
  exportResult = () => {
    const {
      activeStudent: {
        degree,
        exam_answers,
        student: { name, phone },
      },
    } = this.state;
    const allowed = ["degree", "question", "answer"];
    const answers = exam_answers.map((e) => {
      const filtered = Object.keys(e)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = e[key];
          return obj;
        }, {});
      return filtered;
    });

    printJS({
      printable: answers,
      header: `${name} رقم الهاتف : ${phone} الدرجة :${degree}`,
      gridHeaderStyle: "color: blue;  border: 2px solid #2c05c8;",
      gridStyle: "border: 2px solid #3971A5;",
      properties: ["question", "answer", "degree"],
      type: "json",
    });
  };
  downloadFile = () => {
    const { id, title } = this.state;
    axios
      .get(`/account/excel/exam/${id}`, {
        headers: {
          "Content-Disposition": "attachment; filename=template.xlsx",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  render() {
    const { students, id, isLoading, activeStudent } = this.state;

    // adding
    return (
      <React.Fragment>
        <div className="container-lg container-fluid">
          {isLoading ? <Loader /> : null}
          <div className="row mt-3 mb-3 align-items-center">
            <div className="col-md-6">
              <h1 className="h5 medium_font mb-0">
                <Link to="/account/tests" className={classes.Primary + " ml-2"}>
                  <i className="fa fa-arrow-right"></i>
                </Link>
                تقرير الاختبار
              </h1>
            </div>
            <div className="col-md-6 text-md-left text-center">
              <div className="dropdown">
                <button
                  className={`dropdown-toggle ${classes.Primary_btn}`}
                  data-toggle="dropdown"
                >
                  خيارات الاختبار
                </button>
                <div className="dropdown-menu">
                  <Link to={`/account/test/${id}`} className="dropdown-item">
                    <i className="fa fa-edit ml-2"></i>
                    تعديل الاختبار
                  </Link>
                  <button class="dropdown-item" onClick={this.downloadFile}>
                    <i className="fa fa-download ml-2"></i>
                    تحميل ملف الاختبار
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-sm rounded px-4 pb-4">
            {isLoading ? <Loader /> : null}

            {students.length ? (
              <React.Fragment>
                <DataTable
                  noHeader={true}
                  columns={columns}
                  customStyles={tableStyle}
                  data={students}
                />
                <ReactTooltip data-place="top" effect="solid" />
              </React.Fragment>
            ) : (
              <h3 className={classes.Grey + " text-center"}>
                لايوجد طلاب حتى الأن
              </h3>
            )}
          </div>
        </div>
        <div
          className="modal fade"
          id="studentAnswers"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  اجابات الطالب ({activeStudent?.student?.name})
                </h5>
                <button
                  type="button"
                  className="close mr-auto ml-0"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {activeStudent.exam_answers
                  ? activeStudent.exam_answers.map((a, i) => {
                      return (
                        <div className="single_student_answer align-items-start">
                          <span>{i + 1}</span>
                          <div>
                            <h3 className="medium_font h6 ml-3">
                              {a.question}
                            </h3>
                            <p className="mb-0">{Math.floor(a.degree)} درجة</p>
                          </div>
                          <div
                            className={
                              `badge mr-auto ` +
                              (a.degree > 0 ? "bg-success" : "bg-danger")
                            }
                          >
                            {a.degree > 0 ? "صحيحة" : "خاطئة"}
                          </div>
                        </div>
                      );
                    })
                  : null}

                <div className="medium_font d-flex justify-content-between">
                  <div>
                    الدرجة النهائية : {Math.floor(activeStudent.degree)} درجة
                  </div>
                  <div>
                    تقييم الطالب :{" "}
                    <span
                      className={
                        " h6 " +
                        (activeStudent.is_pass == 1
                          ? "text-success"
                          : "text-danger")
                      }
                    >
                      {activeStudent.is_pass == 1 ? "ناجح" : "راسب"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={this.exportResult}
                  className={classes.Primary_btn}
                >
                  تصدير النتيجة
                </button>
                <button data-dismiss="modal" className="btn btn-secondary">
                  اغلاق
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Students;
