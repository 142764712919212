import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import HijriDatePicker from "hijri-date-picker";
import Loader from "../../UI/Loader/Loader";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "./addcourse.css";
import UserContext from "../../../Context/User-context";
import { copyUrl, toastr } from "../../../Helper/Helper";
import { OptionsConsumer } from "../../../Context/Options-context";
class EditCourse extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      id: "",
      title: "",
      content: "",
      start_date: "",
      image: "",
      end_date: "",
      fields: [],
      isLoading: false,
      price_status: 0,
      price: "",
      cert_price_status: 0,
      cert_price: "",
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    axios
      .get(`/account/course/${params.courseId}`)
      .then((res) => {
        const {
          id,
          title,
          start_date,
          end_date,
          image,
          content,
          price,
          price_status,
          cert_price_status,
          cert_price,
        } = res.data;

        this.setState({
          id,
          title,
          start_date,
          end_date,
          content,
          image,
          fields: res.data.form ? res.data.form.form_data : [],
          isLoading: false,
          price,
          price_status,
          cert_price_status,
          cert_price,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errors: err, isLoading: false });
      });
  }

  handleChange = (e, type) => {
    if (type) {
      console.log(e, type);
      this.setState((prevState) => ({
        ...prevState,
        [type]: e,
      }));
    } else {
      const { name, value } = e.target;
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  addField = (type, e) => {
    switch (type) {
      case "input":
        return this.setState((prev) => ({
          fields: [...prev.fields, { type, value: "", required: false }],
        }));
      case "text":
        return this.setState((prev) => ({
          fields: [...prev.fields, { type: type, value: "" }],
        }));
      case "image":
        return this.setState((prev) => ({
          fields: [...prev.fields, { type: type, image: {} }],
        }));
      case "select":
        return this.setState((prev) => ({
          fields: [
            ...prev.fields,
            {
              type,
              value: "",
              answers: [],
              multiple: false,
              choosedAnswers: [],
              required: false,
            },
          ],
        }));
      default:
        return false;
    }
  };

  addAnswer = (id) => {
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (id === i) e.answers.push("");
      return e;
    });
    this.setState({ fields: newFields });
  };

  handleChangeStatus = ({ meta, file }, status) => {
    if (status === "removed") {
      this.setState({ image: "" });
    } else if (status === "done") {
      this.setState({ image: file });
    }
  };

  handleInputChange = (id, evt) => {
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (id === i) {
        if (evt.target.files?.length) {
          e.image = evt.target.files[0];
        } else {
          e.value = evt.target.value;
        }
      }
      return e;
    });
    this.setState({ fields: newFields });
  };

  handleInputDelete = (id) => {
    const { fields } = this.state;
    fields.splice(id, 1);
    this.setState({ fields });
  };

  handleAnswerChange = (questionId, answerId, event) => {
    const { fields } = this.state;
    const newFields = fields.map((e, index) => {
      if (questionId === index) {
        e.answers[answerId] = event.target.value;
      }
      return e;
    });
    this.setState({ fields: newFields });
  };

  handleDeleteAnswer = (questionId, answerId) => {
    console.log(questionId, answerId);
    const { fields } = this.state;
    const newFields = fields.map((e, i) => {
      if (questionId === i) {
        e.answers.splice(answerId, 1);
      }
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleMultiple = (id, e) => {
    const { checked } = e.target;
    const { fields } = this.state;

    if (checked) {
      fields[id].multiple = true;
      this.setState({ fields });
    } else {
      fields[id].multiple = false;
      this.setState({ fields });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      title,
      content,
      start_date,
      end_date,
      fields,
      image,
      id,
      price,
      price_status,
      cert_price,
      cert_price_status,
    } = this.state;

    const courseData = {
      title,
      content: content?.split(/\n/).join(" <br/> "),
      start_date,
      end_date,
      data: fields,
      price,
      price_status,
      cert_price,
      cert_price_status,
    };

    const brocureData = new FormData();
    brocureData.append("image", image);

    this.setState({ isLoading: true });

    axios
      .post(`/account/course/${id}/edit`, courseData)
      .then((res) => {
        const id = res.data.id;
        if (image && typeof image === "object") {
          return axios.post(`/account/course/${id}/edit`, brocureData);
        } else {
          return res;
        }
      })
      .then((res) => {
        toastr("success", "تم تعديل الدورة بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log(err);
      });
  };
  handleRequired = (id, e) => {
    const { checked } = e.target;
    const { fields } = this.state;

    if (checked) {
      fields[id].required = true;
      this.setState({ fields });
    } else {
      fields[id].required = false;
      this.setState({ fields });
    }
  };
  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked ? 1 : 0 });
  };
  handleUploadImage = (e) => {
    e.preventDefault();
    const { fields } = this.state;

    const newFields = fields.map((e, i) => {
      if (typeof e.image === "object") {
        this.setState({ isLoading: true });
        const images = new FormData();
        images.append(`images[]`, e.image);
        axios
          .post("/account/uploadSingleImage", images)
          .then((res) => {
            e.image = res.data[0];
            this.setState({ isLoading: false });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            toastr("warning", "لم يتم رفع الصورة , حاول مرة اخرى");
          });
      }
      return e;
    });
    this.setState({ fields: newFields });
  };
  handleDeleteImg = (id) => {
    const { fields } = this.state;
    const image = fields[id].image;
    this.setState({ isLoading: true });
    axios
      .post("/account/detroySingleImage", { images: [image] })
      .then((res) => {
        fields.splice(id, 1);
        this.setState({ isLoading: false, fields });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  removeBrocure = () => {
    const { id } = this.state;

    if (window.confirm("هل انت متأكد من حذف البرشور")) {
      this.setState({ isLoading: true });
      axios
        .post(`/account/course/${id}/edit`, { image_delete: 1 })
        .then(() => {
          this.setState({ isLoading: false });
          toastr("success", "تم حذف البروشور بنجاح");
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    }
  };
  showCommision = (options) => {
    const minWithObj = options.find((o) => o.key === "commission_percent");
    return minWithObj.value;
  };
  render() {
    const path = "https://acplatform.net/storage/";
    const {
      id,
      title,
      content,
      start_date,
      end_date,
      errors,
      fields,
      isLoading,
      image,
      price,
      price_status,
      cert_price_status,
      cert_price,
    } = this.state;

    return (
      <main className="course_wrapper">
        <div className="container-lg container-fluid">
          {isLoading === true ? <Loader /> : null}
          <div className="mt-md-4">
            <div className="d-flex mb-3 align-items-center">
              <h1 className="h5 bold_font mb-0">{`التعديل على دورة ${title}`}</h1>
              <button
                className={classes.Primary_btn + " mr-auto"}
                onClick={() => copyUrl("course", id)}
              >
                <i className="fa fa-copy ml-2"></i>
                مشاركة
                <span className="d-none">courseURL</span>
              </button>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="rounded shadow-sm p-md-4 bg-white">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="right_course_sidebar">
                        {image ? (
                          <div className="brocure_wrapper">
                            <img
                              className="mb-3"
                              src={image}
                              alt="صورة الدورة"
                            />
                            <button
                              className="btn btn-danger"
                              onClick={this.removeBrocure.bind(this)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        ) : null}
                        <h3 className="medium_font h6 mb-4">
                          إضافة حقول للدورة
                        </h3>

                        <div className="mb-3">
                          <Dropzone
                            onChangeStatus={this.handleChangeStatus}
                            onSubmit={this.handleUpload}
                            maxFiles="1"
                            inputContent={
                              <div>
                                <i className="fa fa-upload ml-2"></i>
                                رفع بروشور الدورة
                              </div>
                            }
                            accept="image/*"
                          />
                        </div>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "input")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة حقل نصى
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "select")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة سؤال
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "image")}
                        >
                          <i className="fa fa-image ml-2"></i>
                          إضافة صورة
                        </button>
                        <button
                          className={
                            classes.Transparent_btn + " mb-3 btn-block"
                          }
                          onClick={this.addField.bind(this, "text")}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          إضافة عبارة نصية
                        </button>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <h3 className="medium_font h6 mb-4">
                        حقول الدورة المخصصة
                      </h3>
                      {fields && Array.isArray(fields)
                        ? fields.map((e, i) => {
                            return e.type === "input" || e.type === "image" ? (
                              <div className="custom_field" key={i}>
                                <div className="row">
                                  <div className="col-md-10 col-9">
                                    {typeof e.image === "string" ? (
                                      <div>
                                        <img
                                          className="rounded"
                                          width="40"
                                          src={`${path}${e.image}`}
                                          alt={e.image}
                                        />
                                        <span className="text-success mr-3">
                                          <i className="fa fa-check ml-2"></i>تم
                                          الرفع بنجاح
                                        </span>
                                      </div>
                                    ) : (
                                      <input
                                        type={
                                          e.type === "input" ? "text" : "file"
                                        }
                                        value={
                                          e.type === "input" ? e.value : null
                                        }
                                        className={classes.FormControl}
                                        placeholder="اكتب اسم الحقل هنا "
                                        onChange={this.handleInputChange.bind(
                                          this,
                                          i
                                        )}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-2 col-3">
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={
                                        typeof e.image === "string"
                                          ? this.handleDeleteImg.bind(this, i)
                                          : this.handleInputDelete.bind(this, i)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>

                                {e.type === "input" ? (
                                  <label
                                    htmlFor={`required` + i}
                                    className={
                                      classes.CustomCheckbox + " mb-0 mt-2"
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      id={`required` + i}
                                      checked={e.required}
                                      onChange={this.handleRequired.bind(
                                        this,
                                        i
                                      )}
                                    />
                                    <span className="ml-2"></span>
                                    حقل مطلوب
                                  </label>
                                ) : typeof e.image === "string" ? null : (
                                  <button
                                    className={classes.Primary}
                                    onClick={this.handleUploadImage}
                                  >
                                    <i className="fa fa-upload ml-2"></i>
                                    رفع
                                  </button>
                                )}
                              </div>
                            ) : e.type === "text" ? (
                              <div className="custom_field" key={i}>
                                <div className="row">
                                  <div className="col-md-10 col-9">
                                    <input
                                      type="text"
                                      value={e.value}
                                      className={classes.FormControl}
                                      placeholder="اكتب اسم العبارة النصية هنا "
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-2 col-3">
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={this.handleInputDelete.bind(
                                        this,
                                        i
                                      )}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="custom_field" key={i}>
                                <div className="row">
                                  <div className="col-md-10 col-9">
                                    <input
                                      type="text"
                                      value={e.value}
                                      className={classes.FormControl}
                                      placeholder="اكتب السؤال هنا "
                                      onChange={this.handleInputChange.bind(
                                        this,
                                        i
                                      )}
                                    />
                                  </div>
                                  <div className="col-md-2 col-3">
                                    <button
                                      className="btn btn-outline-danger"
                                      onClick={this.handleInputDelete.bind(
                                        this,
                                        i
                                      )}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                                {e.answers
                                  ? e.answers.map((answer, answerId) => {
                                      return (
                                        <div key={answerId}>
                                          <div className="row align-items-end mt-3 answer_wrapper">
                                            <div className="col-md-10 col-9">
                                              <input
                                                type="text"
                                                value={answer}
                                                className="answer_input"
                                                placeholder="اكتب الاجابة هنا"
                                                onChange={this.handleAnswerChange.bind(
                                                  this,
                                                  i,
                                                  answerId
                                                )}
                                              />
                                            </div>
                                            <div className="col-md-2 col-3">
                                              <button
                                                onClick={this.handleDeleteAnswer.bind(
                                                  this,
                                                  i,
                                                  answerId
                                                )}
                                              >
                                                <i className="fa fa-times"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}

                                <div className="d-flex align-items-center mt-3 mb-2">
                                  <button
                                    onClick={this.addAnswer.bind(this, i)}
                                    className="btn btn-secondary"
                                  >
                                    <i className="fa fa-plus ml-2"></i>
                                    إضافة إجابة جديدة
                                  </button>

                                  <div></div>
                                </div>

                                <label
                                  htmlFor={`multiple` + i}
                                  className={
                                    classes.CustomCheckbox +
                                    " mb-0 d-md-inline-flex d-flex"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id={`multiple` + i}
                                    checked={e.multiple}
                                    onChange={this.handleMultiple.bind(this, i)}
                                  />
                                  <span className="ml-2"></span>
                                  سؤال متعدد الاجابات
                                </label>

                                <label
                                  htmlFor={`required` + i}
                                  className={
                                    classes.CustomCheckbox + " mr-md-3 mb-0"
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id={`required` + i}
                                    checked={e.required}
                                    onChange={this.handleRequired.bind(this, i)}
                                  />
                                  <span className="ml-2"></span>
                                  سؤال مطلوب
                                </label>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 mt-md-0 mt-3">
                <OptionsConsumer>
                  {(options) => (
                    <div className="course_main_info">
                      <h3 className="medium_font h6 mb-4">
                        حقول الدورة الاساسية
                      </h3>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="courseName"
                          >
                            اسم الدورة
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-book"}
                            ></i>
                            <input
                              type="text"
                              className={classes.FormControl}
                              name="title"
                              id="courseName"
                              onChange={this.handleChange}
                              value={title}
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.email}
                            </p>
                          ) : null}
                        </div>
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="startDate"
                          >
                            تاريخ بدء الدورة (اختيارى)
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-calendar"}
                            ></i>
                            <HijriDatePicker
                              onChange={(e, type) =>
                                this.handleChange(e, "start_date")
                              }
                              inputName="start_date"
                              selectedDate={start_date}
                              className={classes.FormControl}
                              dateFormat="iYYYY/iMM/iDD"
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.email}
                            </p>
                          ) : null}
                        </div>
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="endDate"
                          >
                            تاريخ انتهاء الدورة (اختيارى)
                          </label>
                          <div className="position-relative">
                            <i
                              className={
                                classes.Input_icon + " fa fa-calendar-times-o"
                              }
                            ></i>
                            <HijriDatePicker
                              onChange={(e, type) =>
                                this.handleChange(e, "end_date")
                              }
                              inputName="start_date"
                              selectedDate={end_date}
                              className={classes.FormControl}
                              dateFormat="iYYYY/iMM/iDD"
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.email}
                            </p>
                          ) : null}
                        </div>

                        {this.context.can_sell_online ? (
                          <div className="mb-2">
                            <label
                              className={[
                                classes.Grey,
                                classes.Small_font,
                                " d-block",
                              ].join(" ")}
                            ></label>
                            <div className="row gx-2 align-items-center mb-2">
                              <div className="col-5">
                                <label
                                  htmlFor="courseMoney"
                                  className={classes.CustomCheckbox}
                                >
                                  <input
                                    type="checkbox"
                                    id="courseMoney"
                                    onChange={this.handleCheckbox}
                                    name="price_status"
                                    checked={price_status ? true : false}
                                  />
                                  <span className="ml-2"></span>
                                  دورة مدفوعة
                                </label>
                              </div>
                              <div className="col-7">
                                {price_status ? (
                                  <div className="position-relative mr-2">
                                    <input
                                      type="number"
                                      className={classes.FormControl}
                                      name="price"
                                      id="courseName"
                                      onChange={this.handleChange}
                                      value={price}
                                      placeholder="سعر الدورة"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row gx-2 align-items-center">
                              <div className="col-5">
                                <label
                                  htmlFor="priceStatus"
                                  className={classes.CustomCheckbox}
                                >
                                  <input
                                    type="checkbox"
                                    id="priceStatus"
                                    onChange={this.handleCheckbox}
                                    name="cert_price_status"
                                    checked={cert_price_status ? true : false}
                                  />
                                  <span className="ml-2"></span>
                                  شهادة مدفوعة
                                </label>
                              </div>
                              <div className="col-7">
                                {cert_price_status ? (
                                  <div className="position-relative mr-2">
                                    <input
                                      type="number"
                                      className={classes.FormControl}
                                      name="cert_price"
                                      onChange={this.handleChange}
                                      value={cert_price}
                                      placeholder="سعر الشهادة"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {price_status || cert_price_status ? (
                          <div className="mb-3">
                            <small className={classes.Grey}>
                              نسبة المنصة من بيع الدورة او الشهادة هو{" "}
                              {this.showCommision(options)}% شاملة لضريبة القيمة
                              المضافة
                            </small>
                          </div>
                        ) : null}
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="courseContent"
                          >
                            محتوى الدورة
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-book"}
                            ></i>
                            <textarea
                              className={classes.FormControl}
                              name="content"
                              id="courseContent"
                              onChange={this.handleChange}
                              value={content?.replace(/<br\s*[\/]?>/gi, "\n")}
                            ></textarea>
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.email}
                            </p>
                          ) : null}
                        </div>
                        <button
                          type="submit"
                          className={classes.Primary_btn + " btn-block"}
                        >
                          تعديل الدورة
                        </button>
                      </form>
                    </div>
                  )}
                </OptionsConsumer>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default EditCourse;
