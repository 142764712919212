import axios from "axios";
import { toastr } from "./Helper/Helper";

const instance = axios.create({
  baseURL: "https://acplatform.net/api",
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : null;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 500) {
      toastr("warning", "حدث خطاً ما, جارى اصلاحه ..");
    }
  }
);
export default instance;
