import React, { Component } from "react";
import PricingBlock from "./PricingBlock/PricingBlock";
import axios from "../../axios-api";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../UI/Loader/Loader";
import OptionsContext from "../../Context/Options-context";
import { UserConsumer } from "../../Context/User-context";
import { toastr } from "../../Helper/Helper";

class Pricing extends Component {
  static contextType = OptionsContext;
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      isLoading: false,
      defaultPlan: {},
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios.get("/auth/plans").then((res) => {
      const plansResponse = res.data;
      const plans = plansResponse.map((e) => {
        e.data = JSON.parse(e.data);
        return e;
      });
      this.setState({ plans: plans, isLoading: false });
    });
    this.setState({ isLoading: false });
  }

  createNewOrder = (plan_id, user) => {
    const options = this.context;
    const defaultPlan = options.find((e) => e.key === "default_plan");
    const choosedPlan = this.state.plans.find((p) => p.id === plan_id);
    const data = {
      plan_id,
    };
    if (choosedPlan.isAnnual && choosedPlan.y_certs && choosedPlan.y_price) {
      data.plan_type = "year";
    } else {
      data.plan_type = "month";
    }
    if (parseInt(defaultPlan.value) === plan_id && user) {
      toastr("warning", "انت بالفعل مشترك فى خطة");
    } else if (
      this.props.isLogged &&
      window.confirm("هل انت متأكد من الاشتراك ")
    ) {
      this.setState({ isLoading: true });
      axios
        .post(`account/setplan`, data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.errors) {
            toastr("warning", "لديك طلب تحت المراجعة");
          } else if (parseInt(defaultPlan.value) === res.data.plan_id) {
            toastr("success", "تم ارسال طلبك بنجاح");
            this.props.history.push(`/account`);
            this.props.handleSuccessfulOrder();
          } else {
            this.props.handleSuccessfulOrder();
            toastr("success", "تم ارسال طلبك بنجاح, جارى تحويلك ...");
            setTimeout(() => {
              this.props.history.push(`/account/orders/${res.data.id}`);
            }, 2000);
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };
  handleChangeYear = (e) => {
    console.log(e.target.checked);
  };
  setAnnualActive = (e, id) => {
    const { plans } = this.state;
    plans[id].isAnnual = e.target.checked;
    this.forceUpdate();
  };
  render() {
    const { isLoading, plans } = this.state;

    return (
      <UserConsumer>
        {(user) => (
          <div className="container-lg container-fluid mb-4">
            {isLoading === true ? <Loader /> : null}
            <h1 className="bold_font h4 text-center pt-4 mb-4">
              الباقات والأسعار
            </h1>
            <div className="row">
              {plans.map((plan, index) => {
                return (
                  <PricingBlock
                    key={plan.id}
                    index={index}
                    plan={plan}
                    paidOne={!plans[index + 1] ? true : false}
                    setAnnualActive={this.setAnnualActive}
                    createOrder={this.createNewOrder.bind(
                      this,
                      plan.id,
                      user.plan
                    )}
                  />
                );
              })}
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}

export default Pricing;
