import React from "react";
import { Link } from "react-router-dom";
import classes from "../../UI/UI.module.css";
import { toastr, copyUrl } from "../../../Helper/Helper";
const course = (props) => {
  return (
    <tr className="single_course">
      <td>{props.courseName}</td>

      <td>
        <i className="fa fa-calendar ml-1"></i>
        {props.startDate ? props.startDate : "غير محدد"}
      </td>
      <td>
        <i className="fa fa-calendar-times ml-1"></i>
        {props.endDate ? props.endDate : "غير محدد"}
      </td>
      <td>
        <i className="fa fa-user ml-1"></i>
        {props.students}
      </td>
      <td>
        {props.plan ? (
          <Link
            className="light_btn ml-2"
            to={`/account/add-certifcate/${props.id}`}
          >
            تعيين شهادة
          </Link>
        ) : null}

        <Link
          className={[classes.Transparent_btn, classes.Btn_Small, " py-1"].join(
            " "
          )}
          to={`/account/courses/${props.id}`}
        >
          التفاصيل
        </Link>

        <button
          className="border py-1 px-2 rounded mr-2"
          onClick={() => copyUrl("course", props.id)}
        >
          <i className="fa fa-copy ml-2"></i>
          مشاركة
          <span className="d-none">courseURL</span>
        </button>
        <button
          className="btn btn-outline-danger mr-2 py-1"
          onClick={() => props.deleteCourse(props.id)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};
export default course;
