import React, { Component } from "react";
import NavItem from "./NavItem/NavItem";
import classes from "../../UI/UI.module.css";
import "./NavItems.css";
import axios from "../../../axios-api";
import OptionsContext from "../../../Context/Options-context";
import { Link, Redirect } from "react-router-dom";
import UserConsumer from "../../../Context/User-context";

class NavigationItems extends Component {
  static contextType = OptionsContext;
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
    };
  }
  componentDidMount() {
    axios.get("/pages/menus").then((res) => {
      this.setState({ pages: res.data });
    });
    document.addEventListener("click", () => {
      document.querySelector(".navbar-collapse").classList.remove("show");
    });
  }
  closeModal = () => {
    document.getElementById("modalCloser").click();
  };
  render() {
    const options = this.context;
    const socialMedia = options.filter((o) => {
      return (
        o.key === "facebook" || o.key === "twitter" || o.key === "instagram"
      );
    });
    const { pages } = this.state;

    return (
      <div
        className="modal fade right"
        id="menu_modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="navbar-collapse position-relative"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <UserConsumer>
                    {(user) =>
                      user.id ? (
                        <React.Fragment>
                          <NavItem
                            close={this.closeModal.bind(this, "/account")}
                            link="/account"
                          >
                            ركن التدريب
                          </NavItem>
                          <NavItem
                            close={this.closeModal}
                            link="/account/tests"
                          >
                            الاختبارات
                          </NavItem>
                        </React.Fragment>
                      ) : (
                        <NavItem close={this.closeModal} link="/">
                          الرئيسية
                        </NavItem>
                      )
                    }
                  </UserConsumer>
                  <NavItem close={this.closeModal} link="/marketplace">
                    <span className="badge rounded-pill pt-0 text-dark bg-warning ml-2">
                      جديد
                    </span>
                    خدمات البائعين
                  </NavItem>
                  <UserConsumer>
                    {(user) =>
                      user.plan ? (
                        <React.Fragment>
                          <NavItem
                            close={this.closeModal}
                            link="/account/add-course"
                          >
                            إنشاء دورة جديدة
                          </NavItem>
                          <NavItem
                            close={this.closeModal}
                            link="/account/add-test"
                          >
                            إنشاء اختبار جديد
                          </NavItem>
                        </React.Fragment>
                      ) : null
                    }
                  </UserConsumer>
                  <UserConsumer>
                    {(user) =>
                      Object.keys(user).length ? (
                        <React.Fragment>
                          <NavItem
                            close={this.closeModal}
                            link="/account/add-service"
                          >
                            إنشاء خدمة جديدة
                          </NavItem>
                          <NavItem
                            close={this.closeModal}
                            link="/account/purchases"
                          >
                            المشتريات
                            {user?.user_service_orders?.length ? (
                              <span className="badge rounded-pill pt-0 text-dark bg-warning mr-2">
                                {user.user_service_orders?.length}
                              </span>
                            ) : null}
                          </NavItem>
                          <NavItem
                            close={this.closeModal}
                            link="/account/my-orders"
                          >
                            الطبات الواردة
                            {user.service_orders?.length ? (
                              <span className="badge rounded-pill pt-0 text-dark bg-warning mr-2">
                                {user.service_orders?.length}
                              </span>
                            ) : null}
                          </NavItem>
                          <NavItem
                            close={this.closeModal}
                            link="/account/tickets"
                          >
                            التذاكر
                          </NavItem>
                        </React.Fragment>
                      ) : null
                    }
                  </UserConsumer>
                  {pages && pages.outside
                    ? pages.outside.map((page, i) => {
                        return (
                          <NavItem
                            close={this.closeModal}
                            key={i}
                            link={`/page/${page.id}`}
                          >
                            {page.title}
                          </NavItem>
                        );
                      })
                    : null}

                  <NavItem close={this.closeModal} link="/pricing-plans">
                    خطط الاسعار
                  </NavItem>
                  <NavItem close={this.closeModal} link="/contact-us">
                    اتصل بنا
                  </NavItem>
                </ul>
                {this.props.isLogged === false ? (
                  <React.Fragment>
                    <Link
                      onClick={this.closeModal}
                      className={
                        classes.Primary +
                        " medium_font mb-3 text-center d-block mt-3"
                      }
                      to="/register"
                    >
                      انشئ حساب
                    </Link>
                    <Link
                      onClick={this.closeModal}
                      className={classes.Primary_btn + " btn-block text-center"}
                      to="/login"
                    >
                      تسجيل دخول
                    </Link>
                  </React.Fragment>
                ) : null}
                <button data-dismiss="modal" id="modalCloser"></button>
                <div className=" mb-md-0 mt-3">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[0].value}` : null}
                    className="social_link ml-3"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[1].value}` : null}
                    className="social_link ml-3"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialMedia.length ? `${socialMedia[2].value}` : null}
                    className="social_link"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationItems;
