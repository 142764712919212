import React, { Component } from "react";
import { OptionsProvider } from "../Context/Options-context";
import { UserProvider } from "../Context/User-context";
import Navbar from "../components/Navigation/Navbar";
import HomePage from "../components/HomePage/HomePage";
import Login from "../components/Login/Login";
import ForgetPw from "../components/ForgetPasword/ForgetPw";
import Account from "../components/Account/Account";
import Course from "../components/Course/Course";
import FrontTest from "../components/FrontTest/FrontTest";
import SinglePage from "../components/AboutUs/SinglePage";
import Register from "../components/Register/Register";
import Loader from "../components/UI/Loader/Loader";
import Pricing from "../components/Pricing/Pricing";
import Trainer from "../components/Trainer/Trainer";
import NoMatch from "../components/Error404/NoMatch";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from "../axios-api";
import Contact from "../components/Contact/Contact";
import { ToastContainer } from "react-toastify";
import MarketHome from "../components/Account/MarketHome/MarketHome";
import SingleService from "../components/Account/SingleService/SingleService";
import ServiceCat from "../components/Account/ServicesCat/ServiceCat";
import CartContext from "../Context/Cart-context";

class SiteContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      isLoading: false,
      user: {},
      cart: [],
      options: [],
      tokenExist: false,
    };
  }
  logOutHandler = () => {
    this.setState({ loggedIn: false, user: {}, tokenExist: false });
    localStorage.clear("token");
  };

  handleSuccessfulAuth = (data) => {
    this.setState({
      loggedIn: true,
      isLoading: false,
      user: data,
    });

    const token = this.state.user.access_token;
    localStorage.setItem("token", `Bearer ${token}`);
    this.getUserData();
  };

  handleSuccessfulRegister = (data) => {
    this.setState({ user: data, newUser: true });
    const token = this.state.user.access_token;
    localStorage.setItem("token", `Bearer ${token}`);
    this.getUserData();
  };

  getUserData = () => {
    const access_token = localStorage.getItem("token");
    if (access_token) {
      this.setState({ isLoading: true });
      axios
        .get("/account", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((res) => {
          this.setState({
            user: res.data,
            tokenExist: true,
            loggedIn: true,
            isLoading: false,
          });
          this.getCart();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
        });
    }
  };

  // check user token
  componentDidMount() {
    this.getUserData();
    axios
      .get("/options")
      .then((res) => {
        this.setState({ options: res.data });
      })
      .catch((err) => console.log(err));
  }

  handleOrder = () => {
    this.getUserData();
  };
  updateCourses = (data) => {
    this.getUserData();
  };
  handleUpdateLogos = (data) => {
    this.getUserData();
  };
  getCart = () => {
    axios
      .get("/cart")
      .then((res) => this.setState({ cart: res.data.cart }))
      .catch((err) => console.log(err));
  };
  updateCart = (action, service) => {
    const { cart } = this.state;
    switch (action) {
      case "add":
        cart.push(service);
        this.forceUpdate();
        break;
      case "delete":
        const newCart = cart.filter((c) => c.id !== service);
        this.setState({ cart: newCart });
        break;
      default:
        return;
    }
  };
  render() {
    const { user, options, cart } = this.state;
    for (let option in options) {
      if (options[option].key === "site_name") {
        document.title = options[option].value;
      }
    }

    return (
      <OptionsProvider value={options}>
        <UserProvider value={user}>
          <CartContext.Provider value={{ cart, updateCart: this.updateCart }}>
            <Navbar
              logo={this.state.user.logos}
              login={this.state.loggedIn}
              logout={this.logOutHandler}
              user_id={user.id}
            />

            {this.state.isLoading === true ? <Loader home="bg-white" /> : null}
            <Switch>
              {this.state.tokenExist === true ? (
                <Route
                  path={"/account"}
                  render={(props) => (
                    <Account
                      {...props}
                      updateMedia={this.handleUpdateLogos}
                      updateLogos={this.handleUpdateLogos}
                      updateCourse={this.updateCourses}
                      fetchCourse={this.updateCourses}
                      handleOrder={this.handleOrder}
                      loggedInStatus={this.state.loggedIn}
                      userData={this.state.user}
                    />
                  )}
                />
              ) : null}

              {this.state.tokenExist === true ? (
                <Redirect to="/marketplace" from="/login" />
              ) : null}
              <Route
                exact
                path={"/"}
                render={(props) => (
                  <HomePage {...props} loggedInStatus={this.state.loggedIn} />
                )}
              />
              <Route
                path="/login"
                render={(props) => (
                  <Login
                    {...props}
                    loggedInStatus={this.state.loggedIn}
                    handleSuccessfulAuth={this.handleSuccessfulAuth}
                  />
                )}
              />
              <Route
                path="/trainer/:userId"
                render={(props) => <Trainer {...props} />}
              />
              <Route
                path="/forget-password"
                render={(props) => (
                  <ForgetPw {...props} loggedInStatus={this.state.loggedIn} />
                )}
              />
              <Route
                path="/api/auth/password/reset"
                render={(props) => (
                  <ForgetPw {...props} loggedInStatus={this.state.loggedIn} />
                )}
              />
              <Route
                path="/register"
                render={(props) => (
                  <Register
                    {...props}
                    handleSuccessfulRegister={this.handleSuccessfulRegister}
                    loggedInStatus={this.state.loggedIn}
                  />
                )}
              />
              <Route
                path="/course/:courseId"
                render={(props) => <Course {...props} />}
              />
              <Route
                path="/quiz/:testId"
                render={(props) => <FrontTest {...props} />}
              />
              <Route
                path="/pricing-plans"
                render={(props) => (
                  <Pricing
                    {...props}
                    handleSuccessfulOrder={this.handleOrder}
                    isLogged={this.state.loggedIn}
                  />
                )}
              />
              <Route
                path="/page/:pageId"
                render={(props) => <SinglePage {...props} />}
              />
              <Route
                path="/marketplace"
                render={(props) => <MarketHome {...props} />}
              />
              <Route
                render={(props) => <ServiceCat {...props} />}
                path="/category/:catId"
              />
              <Route
                render={(props) => <SingleService {...props} />}
                path="/services/:serviceId"
              />
              <Route path="/contact-us" component={Contact} />
              <Route path="*" component={NoMatch} />
            </Switch>
            <ToastContainer />
          </CartContext.Provider>
        </UserProvider>
      </OptionsProvider>
    );
  }
}

export default SiteContainer;
