import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { toastr } from "../../../Helper/Helper";

export default class AddService extends Component {
  state = {
    isLoading: false,
    payload: {
      service_category_id: null,
      title: "",
      desc: "",
      delivery_time: 1,
      instructions: "",
      price: null,
    },
    cats: [],
    sub_cats: [],
    additions: [],
    images: [],
  };
  componentDidMount() {
    axios
      .get("/services/category/parents")
      .then((res) => this.setState({ cats: res.data }))
      .catch((err) => this.setState({ isLoading: false }));
  }
  handleCatChange = (id) => {
    axios
      .get(`/services/category/childs/${id}`)
      .then((res) => {
        this.setState({ sub_cats: res.data });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  handleChange = (type = null, id = null, e) => {
    const { name, value } = e.target;
    const { payload, additions, images } = this.state;

    // lines

    if (type === "addition") {
      additions[id][name] = value;
    } else if (type === "video") {
      images[id][name] = value;
    } else {
      payload[name] =
        name === "desc" ? value.split(/\n/).join(" <br/> ") : value;
    }
    this.forceUpdate();
  };

  handleSelect = (e) => {
    const { value } = e.target;
    const { payload } = this.state;

    this.setState({ isLoading: true });
    axios
      .get(`/services/category/childs/${value}`)
      .then((res) => {
        if (res.data.length) {
          this.setState({
            sub_cats: res.data,
          });
        } else {
          this.setState({ sub_cats: [] });
          payload.service_category_id = 0;
          this.forceUpdate();
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  addNewVideo = () => {
    const { images } = this.state;
    images.push({ type: "youtube", image: "" });
    this.forceUpdate();
  };
  addNewAddition = () => {
    const additionObj = {
      title: "",
      is_addition_time: 1,
      price: null,
      addition_time: 1,
    };
    this.setState((prev) => ({
      additions: [...prev.additions, additionObj],
    }));
  };
  deleteAddition = (id) => {
    const { additions } = this.state;
    additions.splice(id, 1);
    this.forceUpdate();
  };
  handleIncrease = (id, e) => {
    const { value } = e.target;
    const { additions } = this.state;
    additions[id].is_addition_time = Math.floor(value) ? 1 : 0;
    this.forceUpdate();
  };
  handleChangeStatus = ({ meta, file }, status) => {
    const { images } = this.state;
    if (status === "done") {
      images.push(file);
      this.forceUpdate();
    } else if (status === "removed") {
      const newImages = images.filter((f) => f.name !== file.name);
      this.setState({ images: newImages });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      payload: {
        service_category_id,
        title,
        desc,
        delivery_time,
        instructions,
        price,
      },
      images,
      additions,
    } = this.state;
    const data = {
      service_category_id,
      title,
      desc: desc.split(/\n/).join("<br/>"),
      delivery_time,
      instructions,
      price,
      add: additions,
      images,
    };

    if (!service_category_id) {
      toastr("warning", "يجب عليك اختيار تصنيف فرعى");
    } else {
      this.setState({ isLoading: true });

      const formData = new FormData();
      for (const key in data) {
        if (key === "images" && data[key].length) {
          data[key].forEach((e, i) => {
            formData.append(
              `images[${i}][image]`,
              e.type === "youtube" ? e.image : e
            );
            formData.append(
              `images[${i}][type]`,
              e.type === "youtube" ? "youtube" : "image"
            );
            if (i === 0) {
              formData.append(`images[${i}][main]`, 1);
            }
          });
        } else if (key === "add") {
          data[key].forEach((a, i) => {
            Object.keys(a).forEach((o, index) => {
              formData.append(`${key}[${i}][${o}]`, a[o]);
            });
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      axios
        .post("/services/create", formData)
        .then((res) => {
          toastr("success", "تمت إضافة الخدمة , بإنتظار موافقة الإدارة");
          this.setState({ isLoading: false });
          this.props.history.push(`/account`);
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          if (errors.images) {
            toastr("warning", "يجب إرفاق صورة واحدة للخدمة على الاقل");
          }
          this.setState({ isLoading: false });
        });
    }
  };
  deleteVideo = (i) => {
    const { images } = this.state;
    images.splice(i, 1);
    this.forceUpdate();
  };
  render() {
    const { sub_cats, additions, cats, isLoading, images } = this.state;

    return (
      <div className="container-lg container-fluid mt-3" key="ww-container">
        {isLoading ? <Loader /> : null}
        <div className="col-md-8 mx-auto p-0" key="col-24de">
          <h1 className="h5 bold_font mb-3">إضافة خدمة</h1>
          <div className="bg-white p-4 mb-4">
            {/*  New Service content  */}
            <form onSubmit={this.handleSubmit} key={23}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="serviceName"
                    >
                      اسم الخدمة
                    </label>
                    <input
                      id="serviceName"
                      type="text"
                      name="title"
                      required
                      className={classes.FormControl}
                      onChange={this.handleChange.bind(this, null, null)}
                    />
                    <small className="grey small_font">
                      أدخل عنواناً واضحاً باللغة العربية يصف الخدمة التي تريد أن
                      تقدمها
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="price"
                    >
                      سعر الخدمة
                    </label>
                    <input
                      id="price"
                      type="number"
                      name="price"
                      required
                      className={classes.FormControl}
                      onChange={this.handleChange.bind(this, null, null)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="serviceCat"
                    >
                      تصنيف الخدمة
                    </label>
                    <select
                      name="service_category_id"
                      id="serviceCat"
                      className={classes.FormControl}
                      onChange={this.handleSelect}
                      required
                    >
                      <option value="">اختر</option>
                      {cats.map((e, i) => (
                        <option key={i} value={e.id}>
                          {e.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label className="invisible">تصنيف الخدمة</label>
                    {sub_cats.length ? (
                      <select
                        name="service_category_id"
                        id="serviceCat"
                        className={classes.FormControl}
                        required
                        onChange={this.handleChange.bind(this, null, null)}
                      >
                        <option value="">اختر</option>
                        {sub_cats.map((e, i) => (
                          <option value={e.id} key={i}>
                            {e.title}
                          </option>
                        ))}
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="caption"
                >
                  وصف الخدمة
                </label>
                <textarea
                  onChange={this.handleChange.bind(this, null, null)}
                  name="desc"
                  id="caption"
                  className={classes.FormControl}
                  rows="2"
                  required
                ></textarea>
                <small className="grey small_font">
                  أدخل وصف الخدمة بدقة يتضمن جميع المعلومات والشروط . يمنع وضع
                  البريد الالكتروني، رقم الهاتف أو أي معلومات اتصال أخرى.
                </small>
              </div>
              <div key="w30x1" className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="serviceName"
                >
                  معرض صور الخدمة
                </label>
                <div className="mb-3" key="ow-2">
                  <Dropzone
                    key="dropzone-2"
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleUpload}
                    inputContent={
                      <div>
                        <i className="fa fa-upload ml-2"></i>
                        رفع صور الخدمة
                      </div>
                    }
                    accept="image/*"
                  />
                </div>
              </div>

              <div className="form-group mb-4">
                <label className="grey small_font">معرض فيديو</label>
                <div className="row">
                  {images.map((e, i) =>
                    e.type === "youtube" ? (
                      <div className="col-md-6 mb-3" key={i}>
                        <div className="d-flex">
                          <input
                            type="url"
                            name="image"
                            placeholder="مثال على رابط يوتيوب (https://www.youtube.com/embed/42-k6YQLa_I)"
                            onChange={this.handleChange.bind(this, "video", i)}
                            className={classes.FormControl}
                            required
                          />
                          <button
                            type="button"
                            className="btn btn-outline-danger mr-2"
                            onClick={this.deleteVideo.bind(this, i)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    ) : null
                  )}
                </div>
                <button
                  onClick={this.addNewVideo}
                  className={classes.Transparent_btn}
                  type="button"
                >
                  <i className="fa fa-plus ml-2"></i>
                  فيديو يوتيوب جديد
                </button>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="delivery_time"
                >
                  مدة التسليم
                </label>
                <select
                  onChange={this.handleChange.bind(this, null, null)}
                  className={classes.FormControl}
                  name="delivery_time"
                  required
                >
                  <option value="1">يوم واحد</option>
                  <option value="2">يومين</option>
                  <option value="3">ثلاثة أيام</option>
                  <option value="4">أربعة أيام</option>
                  <option value="5">خمس أيام</option>
                  <option value="6">ستة أيام</option>
                  <option value="7">أسبوع</option>
                  <option value="14">أسبوعين</option>
                  <option value="21">ثلاثة أسابيع</option>
                  <option value="30">شهر</option>
                </select>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="caption"
                >
                  تعليمات للمشترى
                </label>
                <textarea
                  name="instructions"
                  onChange={this.handleChange.bind(this, null, null)}
                  id="caption"
                  className={classes.FormControl}
                  rows="2"
                ></textarea>
                <small className="grey small_font">
                  المعلومات التي تحتاجها من المشتري لتنفيذ الخدمة. تظهر هذه
                  المعلومات بعد شراء الخدمة فقط.
                </small>
              </div>
              <div className="text-left">
                <button
                  onClick={this.addNewAddition}
                  className={classes.Transparent_btn}
                  type="button"
                >
                  <i className="fa fa-plus ml-2"></i>
                  اضف تطوير جديد
                </button>
              </div>
              {additions.length
                ? additions.map((a, i) => (
                    <React.Fragment key={i}>
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                        >
                          عنوان التطوير
                        </label>
                        <input
                          type="text"
                          name="title"
                          onChange={this.handleChange.bind(this, "addition", i)}
                          className={classes.FormControl}
                        />
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <div className="form-group mb-4">
                            <select
                              name="is_addition_time"
                              onChange={this.handleIncrease.bind(this, i)}
                              className={classes.FormControl}
                            >
                              <option value="1">
                                سيزيد من مدة تنفيذ الخدمة
                              </option>
                              <option value="0">لن يزيد من مدة الخدمة</option>
                            </select>
                          </div>
                        </div>

                        {a.is_addition_time ? (
                          <div className="col-md-3">
                            <select
                              name="addition_time"
                              onChange={this.handleChange.bind(
                                this,
                                "addition",
                                i
                              )}
                              className={classes.FormControl + " mb-4"}
                            >
                              <option value="1">يوم واحد</option>
                              <option value="2">يومين</option>
                              <option value="3">ثلاثة أيام</option>
                              <option value="4">أربعة أيام</option>
                              <option value="5">خمس أيام</option>
                              <option value="6">ستة أيام</option>
                              <option value="7">أسبوع</option>
                              <option value="14">أسبوعين</option>
                              <option value="21">ثلاثة أسابيع</option>
                              <option value="30">شهر</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="col-md-3">
                          <div className="form-group mb-4">
                            <input
                              onChange={this.handleChange.bind(
                                this,
                                "addition",
                                i
                              )}
                              type="number"
                              placeholder="سعر الإضافة"
                              className={classes.FormControl}
                              name="price"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 mb-4">
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={this.deleteAddition.bind(this, i)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                : null}

              <button className={classes.Primary_btn}>أضف الخدمة</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
