import React, { Component } from "react";
import { fabric } from "fabric";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { SketchPicker } from "react-color";
import classes from "../../UI/UI.module.css";
import Loader from "../../UI/Loader/Loader";
import axios from "../../../axios-api";
import { toastr } from "../../../Helper/Helper";
import Dropzone from "react-dropzone-uploader";
import "react-tabs/style/react-tabs.css";
import "./addCert.css";
import { Link, Prompt, Redirect } from "react-router-dom";

let canvas = null;

class QuizCert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLogo: 0,
      image: "",
      fonts: [],
      selectedFont: "Tajawal",
      textColor: "",
      courseName: "",
      certifcates: [],
      myCerts: [],
      prevPage: "",
      nextPage: "",
      cert: {},
      course_id: "",
      saved: null,
    };
  }
  // if user has not
  async componentDidMount() {
    // get all certifcates
    canvas = new fabric.Canvas("canvas");
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });

    await axios.get(`/certs`).then((res) => {
      this.setState({
        myCerts: res.data.own.data,
        certifcates: res.data.all.data,
        isLoading: false,
        prevPage: res.data.all.prev_page_url,
        nextPage: res.data.all.next_page_url,
      });
    });
    this.setState({ isLoading: false });
    this.setState({ isLoading: true });

    await axios
      .get(`/account/exams/${params.testId}`)
      .then((res) => {
        this.setState({
          title: res.data.title,
          content: res.data.content,
          pass_degree_percentage: res.data.pass_degree_percentage,
          exam_duration: res.data.exam_duration,
          isLoading: false,
          course_id: res.data.id,
          cert: res.data.cert_payload,
        });

        const defaultLogo = this.props.logos[0];

        if (res.data.cert_payload !== null) {
          const cords = res.data.cert_payload;
          canvas.loadFromJSON(cords, () => {
            const certs = canvas.getObjects();
            certs.map((o) => {
              if (
                (o.type === "textbox" && certs.indexOf(o) === 0) ||
                certs.indexOf(o) === 1
              ) {
                o["static"] = true;
              }
            });
          });
          canvas.renderAll();
        } else {
          const textOptions = {
            top: 250,
            left: 250,
            color: "#fff",
            width: 200,
            textAlign: "right",
            fontSize: 20,
            fontFamily: this.state.selectedFont,
          };

          let studentName = new fabric.Textbox("اسم الطالب", {
            ...textOptions,
            editable: false,
          });
          studentName.set("userType", "student");
          let trainerName = new fabric.Textbox("اسم المدرب", {
            ...textOptions,
            top: 350,
          });

          studentName["static"] = true;

          trainerName["static"] = true;

          canvas.add(studentName).add(trainerName);
          canvas.renderAll();

          // load default certicate
          const defaultCert = this.state.certifcates[0];
          this.setState({ cert: { certificate_id: defaultCert.id } });

          fabric.Image.fromURL(
            defaultCert.url,
            // Certifcate,
            (img) => {
              canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height,
              });
            },
            null,
            { crossOrigin: "Anonyomus" }
          );

          // load default logo
          fabric.Image.fromURL(
            // Certifcate,
            defaultLogo.logo,
            (img) => {
              canvas.add(
                img.set({
                  top: 50,
                  scaleX: 200 / img.width,
                  scaleY: 200 / img.width,
                })
              );
            },
            null,
            { crossOrigin: "Anonyomus" }
          );
        }
      })
      .catch((err) => this.setState({ isLoading: false }));

    // handle delete
    document.addEventListener("keydown", this.deleteObjHandler);

    // get fonts
    axios
      .get("/account/fonts")
      .then((res) => {
        this.setState({ fonts: res.data, isLoading: false });
      })
      .catch((err) => {});
  }

  componentWillUnmount() {
    // remove delete listner
    document.removeEventListener("keydown", this.deleteObjHandler);
  }

  changeBg = (url, id) => {
    this.setState({
      certificate_id: id,
      saved: false,
    });
    fabric.Image.fromURL(url, (img) => {
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
        scaleX: canvas.width / img.width,
        scaleY: canvas.height / img.height,
      });
    });
  };

  changeFont = (e) => {
    this.setState({ saved: false });
    const font = e.target.dataset.font;
    try {
      this.setState({ selectedFont: font });
      canvas.getActiveObject().set("fontFamily", font);
      canvas.requestRenderAll();
    } catch (err) {
      console.log(err);
    }
  };

  addNewText = () => {
    const newText = new fabric.Textbox("نص جديد قابل للتعديل", {
      fontSize: 16,
      width: 150,
      top: 100,
      fontFamily: this.state.selectedFont,
      textAlign: "right",
    });
    this.setState({ saved: false });
    canvas.add(newText);
    canvas.renderAll();
  };

  deleteObjHandler = (e) => {
    if (e.keyCode === 46) {
      const activeObj = canvas.getActiveObject();
      if (activeObj && !activeObj["static"]) {
        this.setState({ saved: false });
        canvas.remove(activeObj);
        canvas.renderAll();
      } else {
        toastr("warning", "لايمكنك حذف اسم الطالب او المدرب");
      }
    }
  };

  handleChangeColor = (color, event) => {
    this.setState({ saved: false });
    this.setState({ textColor: color.hex });

    if (canvas.getActiveObject()) {
      canvas.getActiveObject().set("fill", this.state.textColor);
      canvas.requestRenderAll();
    }
  };

  addLogo = (e) => {
    this.setState({ saved: false });
    const url = e.target.src;
    // load default logo
    fabric.Image.fromURL(
      // Certifcate,
      url,
      (img) => {
        canvas.add(
          img.set({
            top: 50,
            scaleX: 200 / img.width,
            scaleY: 200 / img.width,
          })
        );
      },
      null,
      { crossOrigin: "Anonyomus" }
    );
  };

  canvasProc = null;

  saveAllObj = () => {
    const {
      match: { params },
    } = this.props;
    const { exam_duration, pass_degree_percentage, title, content } =
      this.state;
    this.canvasProc = canvas.toJSON();
    this.setState({ isLoading: true });
    axios
      .post(`/account/exams/${params.testId}`, {
        cert_payload: this.canvasProc,
        exam_duration: Number(exam_duration),
        pass_degree_percentage,
        title,
        content,
      })
      .then((res) => {
        toastr("success", "تم اعتماد نموذج الشهادة");
        this.setState({ isLoading: false, saved: true });
      })
      .catch(() => {
        toastr("success", "تم اعتماد نموذج الشهادة");
        this.setState({ isLoading: false });
      });
  };

  jumpPage = (type) => {
    this.setState({ isLoading: true });
    const { prevPage, nextPage } = this.state;
    axios
      .get(type === "next" ? prevPage : nextPage)
      .then((res) => {
        const { data, next_page_url, prev_page_url } = res.data.all;
        this.setState({
          certifcates: data,
          nextPage: next_page_url,
          prevPage: prev_page_url,
          isLoading: false,
        });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };

  uploadCert = (files, allFiles) => {
    this.setState({ isLoading: true });
    const id = this.props.userId;
    const data = new FormData();

    //title && url && user_id

    files.map((f) => {
      data.append("url", f.file);
      data.append("title", f.file.name);
    });

    axios
      .post("/account/upload/cert/new", data)
      .then((res) => {
        const newCerts = this.state.myCerts;
        newCerts.push(res.data);
        toastr("success", "تم رفع الشهادة بنجاح , ستجدها فى شهاداتى");
        this.setState({ isLoading: false, myCerts: newCerts });
      })
      .catch((err) => {
        toastr("warning", "حدث خطأ اثناء رفع الشهادة");
        this.setState({ isLoading: false });
      });
  };

  handleUpload = (files, allFiles) => {
    const main = this.state.mainLogo;
    const body = new FormData();
    files.forEach((f) => {
      if (!this.props.logos.length) {
        body.append("main", 1);
      } else {
        body.append("main", main);
      }
      body.append("image", f.file);
    });
    this.setState({ isLoading: true });
    axios
      .post("/account/update/logos", body)
      .then((res) => {
        this.setState({ isLoading: false });
        toastr("success", "تم رفع الشعار بنجاح");
        this.props.updateMedia();
        allFiles.forEach((f) => f.remove());
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطأ ما حاول مرة اخرى");
      });
  };

  mainLogoHandler = (e) => {
    if (e.target.checked) {
      this.setState({ mainLogo: 1 });
    } else {
      this.setState({ mainLogo: 0 });
    }
  };

  handeDeleteCert = (id) => {
    if (window.confirm("هل انت متأكد من حذف الشعار")) {
      axios
        .delete(`/account/trainer/cert/delete/${id}`)
        .then((res) => {
          const certs = this.state.myCerts;
          const newCerts = certs.filter((c) => c.id !== id);
          this.setState({ myCerts: newCerts });
          toastr("success", "تم حذف شعارك بنجاح");
        })
        .catch((err) => {
          toastr("warning", "حدث خطأ ما اثناء الحذف");
        });
    }
  };

  handleChangeStatus = ({ meta, file }, status) => {
    const elm = document.getElementsByClassName("dzu-submitButton");
    if (elm.length) {
      elm[0].textContent = "اعتماد الصورة";
    }
  };
  render() {
    const {
      fonts,
      selectedFont,
      isLoading,
      title,
      prevPage,
      nextPage,
      myCerts,
      saved,
    } = this.state;
    const certifcates = this.state.certifcates;

    // append fonts to tag
    const headTag = document.getElementsByTagName("head")[0];
    if (fonts.length > 0) {
      fonts.map((f) => {
        headTag.innerHTML += f.path;
      });
    }
    // return jsx
    return (
      <div className="container-lg container-fluid">
        {saved ? <Redirect to="/account/tests" /> : null}
        {saved === false ? (
          <Prompt
            message={(location) =>
              `لديك شهادة غير محفوظة , هل انت متأكد من مغادرة الصفحة ؟`
            }
          />
        ) : null}
        {isLoading === true ? <Loader /> : null}
        <div className="row align-items-center mt-4 mb-3">
          <div className="col-md-6">
            <div className="d-flex">
              <Link
                className={[classes.Primary, "ml-3"].join(" ")}
                to={`/account/test/${this.state.course_id}`}
              >
                <i className="fa fa-arrow-right"></i>
              </Link>
              <h1 className="h5 bold_font mb-md-0 mb-3 text-md-right text-center">
                تعيين شهادة ل {title}
              </h1>
            </div>
          </div>

          <div className="col-md-6 text-md-left text-center">
            <button className={classes.Primary_btn} onClick={this.saveAllObj}>
              اعتماد الشهادة
            </button>
          </div>

          <div className="col-md-12">
            <div
              className="alert alert-info alert-dismissible fade show mt-3 py-2"
              role="alert"
            >
              اسم الطالب يتحول تلقائياً لاسم الطالب المشترك فى الدورة , يمكنك
              تغيير اسم المدرب حسب رغبتك او إضافة نصوص جديدة
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <Tabs className="d-flex h-100 bg-white rounded">
              <TabList className="d-flex flex-column border-left">
                <Tab className="tool_tab">
                  <i className="fa fa-image d-block mb-2"></i>
                  الشهادات
                </Tab>
                <Tab className="tool_tab">
                  <i className="fa fa-image d-block mb-2"></i>
                  شهاداتى
                </Tab>
                <Tab className="tool_tab">
                  <i className="fa fa-font d-block mb-2"></i>
                  الخطوط
                </Tab>
                <Tab className="tool_tab">
                  <i className="fa fa-paint-brush d-block mb-2"></i>
                  اللون
                </Tab>
                <Tab className="tool_tab">
                  <i className="fa fa-file-image-o d-block mb-2"></i>
                  تحميلاتى
                </Tab>
              </TabList>

              <div className="flex-grow-1">
                <TabPanel className="tab_panel">
                  <div className="certs_wrapper">
                    <Dropzone
                      key="2"
                      maxFiles="1"
                      onChangeStatus={this.handleChangeStatus}
                      inputContent={
                        <div key="3">
                          <i className="fa fa-upload ml-2"></i>
                          <span>ارفع شهادتك</span>
                        </div>
                      }
                      onSubmit={this.uploadCert}
                    />

                    <div className="row mt-3">
                      {certifcates.map((cert, key) => {
                        return (
                          <div className="col-md-6" key={key}>
                            <div className="cert_temp">
                              <button
                                className="w-100 d-block"
                                onClick={this.changeBg.bind(
                                  this,
                                  cert.url,
                                  cert.id
                                )}
                              >
                                <img
                                  className="w-100"
                                  src={cert.url}
                                  alt="نموذج شهادة"
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="row mb-3 mt-3">
                      <div className="col-6">
                        <button
                          disabled={prevPage ? false : true}
                          onClick={this.jumpPage.bind(this, "next")}
                          className="d-block w-100 border p-2 text-center"
                        >
                          السابق
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          disabled={nextPage ? false : true}
                          onClick={this.jumpPage.bind(this, "prev")}
                          className="d-block w-100 border p-2 text-center"
                        >
                          التالى
                        </button>
                      </div>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel className="tab_panel">
                  <div className="cert_wrapper">
                    <div className="row">
                      {myCerts.map((cert, key) => {
                        return (
                          <div className="col-md-6" key={key}>
                            <div className="cert_temp">
                              <button
                                className="w-100 d-block"
                                onClick={this.changeBg.bind(
                                  this,
                                  cert.url,
                                  cert.id
                                )}
                              >
                                <img
                                  className="w-100"
                                  src={cert.url}
                                  alt="نموذج شهادة"
                                />
                              </button>
                              <button
                                className="text-danger"
                                onClick={this.handeDeleteCert.bind(
                                  this,
                                  cert.id
                                )}
                              >
                                حذف
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>

                <TabPanel className="tab_panel">
                  <div className="text-center">
                    <button
                      className="py-3 d-block rounded border w-100 mb-4"
                      onClick={this.addNewText}
                    >
                      إضافة نص جديد
                    </button>
                  </div>
                  <div className="row">
                    {fonts.map((font, i) => (
                      <div className="col-md-6 mb-3" key={i}>
                        <button
                          data-font={font.title_en}
                          className={
                            (font.title_en === selectedFont
                              ? "active_font"
                              : "") +
                            " text-center d-block w-100 border rounded py-3 medium_font font_btn"
                          }
                          style={{ fontFamily: font.title_en }}
                          onClick={this.changeFont}
                        >
                          {font.title_ar}
                        </button>
                      </div>
                    ))}
                  </div>
                </TabPanel>

                <TabPanel className="tab_panel">
                  <SketchPicker
                    className="mb-4"
                    color={this.state.textColor}
                    onChange={this.handleChangeColor}
                    onChangeComplete={this.handleChangeComplete}
                  />
                </TabPanel>

                <TabPanel className="tab_panel">
                  <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleUpload}
                    maxFiles="1"
                    inputContent={
                      <div>
                        <i className="fa fa-upload ml-2"></i>
                        ارفع شعارك هنا
                      </div>
                    }
                    accept="image/*"
                  />
                  <label htmlFor="mainLogo" className="mt-3">
                    <input
                      id="mainLogo"
                      type="checkbox"
                      onChange={this.mainLogoHandler}
                    />
                    <span className="mr-2">الشعار الاساسى</span>
                  </label>
                  <div className="row mt-3">
                    {this.props.logos.map((e, i) => {
                      return (
                        <div className="col-lg-6" key={i}>
                          <button
                            className="w-100 d-block border p-2 rounded mb-3"
                            onClick={this.addLogo}
                          >
                            <img className="w-100" src={e.logo} alt="bg" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
          <div className="col-8">
            <canvas
              id="canvas"
              width="800"
              height="600"
              style={{ maxWidth: "100% !important" }}
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
export default QuizCert;
