import React from "react";
import classes from "./NavItem.module.css";
import { Link } from "react-router-dom";

const navItem = (props) => {
  return (
    <li className={classes.NavItem}>
      <Link
        onClick={() => props.close()}
        to={props.link}
        className={classes.NavLink + " nav-link " + props.log}
      >
        {props.children}
      </Link>
    </li>
  );
};

export default navItem;
