import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import "./singleService.css";
import { Link } from "react-router-dom";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { getMainImg, toastr } from "../../../Helper/Helper";
import CartContext from "../../../Context/Cart-context";
import NotFound from "../../FrontTest/NotFound";
import { UserConsumer } from "../../../Context/User-context";
import siteLogo from "../../../assets/imgs/logo.png";

export default class SingleService extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      service: {},
      title: "",
      desc: "",
      qty: 1,
      msg: "",
      service_additionals: [],
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    const {
      match: { params },
    } = this.props;
    axios
      .get(`/services/${params.serviceId}`)
      .then((res) => this.setState({ service: res.data, isLoading: false }))
      .catch((err) => this.setState({ isLoading: false, error: true }));
  }
  handleAddition = (id, e) => {
    const { checked } = e.target;
    const { service_additionals } = this.state;
    const index = service_additionals.indexOf(id);
    if (checked) {
      service_additionals.push(id);
    } else {
      service_additionals.splice(index, 1);
    }
    this.setState({ service_additionals });
  };
  handleQty = (e) => {
    const { value } = e.target;
    this.setState({ qty: Math.floor(value) });
  };
  handlePurchase = () => {
    const { service, qty, service_additionals } = this.state;
    const data = { service_id: service.id, qty, service_additionals };
    const { updateCart } = this.context;

    this.setState({ isLoading: true });
    axios
      .post("/services/addToCart", data)
      .then((res) => {
        if (res.data.error) {
          toastr("warning", "هذه الخدمة موجودة بالفعل فى السلة");
        } else {
          toastr("success", "تمت اضافة الخدمة للسلة بنجاح");
          updateCart("add", res.data.cart);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  sendMsg = (e) => {
    e.preventDefault();
    const {
      service: { id },
      msg,
    } = this.state;
    axios
      .post("/services/store/messages", { service_id: id, message: msg })
      .then((res) => {
        const { message_id } = res.data;
        toastr("success", "تم إرسال الرسالة بنجاح");
        document.querySelector(".modalCloser").click();
        if (message_id) {
          this.props.history.push(
            `/account/chat/${message_id}?type=serviceMsg`
          );
        }
        console.log(res.data);
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  reportService = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const {
      service: { id },
      title,
      desc,
    } = this.state;
    const data = { service_id: id, title, desc };

    axios
      .post("/notice", data)
      .then((res) => {
        this.setState({ isLoading: false });
        toastr("success", "تم الابلاغ عن الخدمة ");
        document.querySelector(".reportCloser").click();
      })
      .catch(() => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطاً ما");
      });
  };
  render() {
    const { isLoading, service, error } = this.state;

    const generateStars = (rate) => {
      const emptyStar = (
        <span className="grey">
          <i className="fa fa-star-o"></i>
        </span>
      );
      const fullStar = (
        <span>
          <i className="fa fa-star"></i>
        </span>
      );
      const halfStar = (
        <span>
          <i className="fa fa-star-half-o"></i>
        </span>
      );
      let starsArr = [];
      for (let i = rate; i >= 0; i--) {
        if (i === 0.5) {
          starsArr.push(halfStar);
        } else if (i === 0) {
          starsArr = new Array(5).fill(emptyStar);
        } else {
          starsArr.push(fullStar);
        }
      }
      return starsArr;
    };

    const hasVideos = service?.images?.filter((v) => v.type === "youtube");

    return (
      <React.Fragment>
        <main>
          {isLoading ? <Loader /> : null}

          {error ? (
            <NotFound
              msg="خدمة غير موجودة , يمكنك البحث عن خدمة اخرى"
              test={false}
            />
          ) : (
            <UserConsumer>
              {(user) => (
                <React.Fragment>
                  {/* service head  */}
                  <div className="service_head">
                    <div className="container-lg container-fluid">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="d-flex align-items-center">
                            <img
                              className="rounded-circle ml-2"
                              src={
                                service?.user
                                  ? getMainImg(service.user.logos)
                                  : null
                              }
                              width="64"
                              height="64"
                              alt="صورة خدمة"
                              style={{ objectFit: "cover" }}
                            />
                            <div>
                              <h3 className="h6 medium_font">
                                {service.title}
                              </h3>
                              <div>
                                <Link
                                  to={`/trainer/${service.user?.id}`}
                                  className="ml-3 grey"
                                >
                                  <i className="fa fa-user ml-2"></i>
                                  {service.user?.name}
                                </Link>
                                <span className="ml-3 grey">
                                  <i className="fa fa-bars ml-2"></i>
                                  {service.category?.title}
                                </span>
                                <span className="ml-3 grey">
                                  <i className="fa fa-clock-o ml-2"></i>
                                  مدة التسليم : {service.delivery_time} ايام
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="d-flex justify-content-end align-items-stretch">
                            {service.user?.id === user.id ? (
                              <Link
                                to={`/account/edit-service/${service.id}`}
                                className={classes.Primary_btn + " pl-3"}
                              >
                                تعديل الخدمة
                              </Link>
                            ) : Object.keys(user).length ? (
                              <React.Fragment>
                                <button
                                  onClick={this.handlePurchase}
                                  className={classes.Primary_btn + " pl-3"}
                                >
                                  شراء الخدمة
                                </button>
                                <div className="dropdown">
                                  <button
                                    className={
                                      "dropdown-toggle " + classes.Primary_btn
                                    }
                                    data-toggle="dropdown"
                                    style={{
                                      position: "relative",
                                      right: "-.5rem",
                                    }}
                                  ></button>
                                  <div className="dropdown-menu">
                                    <button
                                      data-toggle="modal"
                                      data-target="#msgModal"
                                      className="dropdown-item"
                                    >
                                      <i className="fa fa-envelope ml-2"></i>
                                      تواصل مع البائع
                                    </button>
                                    <button
                                      data-toggle="modal"
                                      data-target="#reportModal"
                                      className="dropdown-item"
                                    >
                                      <i className="fa fa-exclamation ml-2"></i>
                                      ابلاغ عن خدمة
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              <Link to="/login" className={classes.Primary_btn}>
                                شراء الخدمة
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-lg container-fluid mt-4">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="bg-white p-3 mb-4">
                          {service.images?.length ? (
                            <div
                              id="imagesCarousel"
                              className="carousel slide"
                              data-ride="carousel"
                            >
                              <div className="carousel-inner">
                                {service.images.map((s, id) => (
                                  <div
                                    key={id}
                                    className={
                                      "carousel-item " +
                                      (s.main === 1 ? "active" : " ")
                                    }
                                  >
                                    {s.type === "image" ? (
                                      <img
                                        style={{ opacity: !s.main ? 0.5 : 1 }}
                                        className="w-100 mb-4"
                                        src={s.full_path}
                                        alt="صورة"
                                      />
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                              <a
                                className="carousel-control-prev"
                                href="#imagesCarousel"
                                role="button"
                                data-slide="prev"
                              >
                                <span
                                  className="carousel-control-prev-icon"
                                  aria-hidden="true"
                                ></span>
                              </a>
                              <a
                                className="carousel-control-next"
                                href="#imagesCarousel"
                                role="button"
                                data-slide="next"
                              >
                                <span
                                  className="carousel-control-next-icon"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Next</span>
                              </a>
                            </div>
                          ) : null}

                          <div className="row mt-3">
                            {hasVideos?.length ? (
                              <h3 className="h6 medium_font">
                                تطويرات هذه الخدمة
                              </h3>
                            ) : null}
                            {service.images?.map((v, i) => {
                              return v.type === "youtube" ? (
                                <div className="col-md-6">
                                  <iframe
                                    height="300"
                                    className="w-100"
                                    src={v.full_path}
                                    title={`youtube${i}`}
                                  ></iframe>
                                </div>
                              ) : null;
                            })}
                          </div>
                          <article
                            style={{ lineHeight: "2rem" }}
                            dangerouslySetInnerHTML={{
                              __html: service?.desc
                                ?.split(/\n/)
                                .join(" <br /> "),
                            }}
                          ></article>
                        </div>

                        <div className="content_card mb-4">
                          <div className="card_head">
                            <h3 className="h6 medium_font">
                              تطويرات هذه الخدمة
                            </h3>
                          </div>
                          <div className="card_content">
                            {service.additions?.length ? (
                              service.additions?.map((e, i) => (
                                <div className="d-flex flex-md-row flex-column mb-3 border-bottom pb-2">
                                  <label
                                    htmlFor={`addition-${i + 1}`}
                                    className={classes.CustomCheckbox}
                                  >
                                    <input
                                      type="checkbox"
                                      name=""
                                      id={`addition-${i + 1}`}
                                      onChange={this.handleAddition.bind(
                                        this,
                                        e.id
                                      )}
                                    />
                                    <span className="ml-3"></span>
                                    {e.title}
                                  </label>
                                  <div className="mx-auto">
                                    + {e.addition_time} ايام
                                  </div>
                                  <div className="mr-auto">+ {e.price} SR</div>
                                </div>
                              ))
                            ) : (
                              <h3 className="medium_font grey h6 mt-2 text-center">
                                لايوجد تطويرات فى هذه الخدمة
                              </h3>
                            )}
                          </div>
                        </div>

                        <div className="content_card mb-4">
                          <div className="card_head">
                            <div className="d-flex">
                              <h3 className="h6 medium_font">اشترى الخدمة</h3>
                              <button
                                className="mr-auto btn_link medium_font"
                                to="/"
                                data-toggle="modal"
                                data-target="#msgModal"
                              >
                                <i className="fa fa-comment ml-2"></i>
                                تواصل مع البائع
                              </button>
                            </div>
                          </div>
                          <div className="card_content">
                            <div className="d-flex justify-content-center align-items-center mt-3">
                              <div className="ml-2">عدد مرات الطلب</div>
                              <select
                                className={classes.FormControl}
                                style={{ width: "90px" }}
                                onChange={this.handleQty}
                              >
                                <option value="1" selected>
                                  1
                                </option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </select>
                            </div>

                            <div className="text-center mt-3">
                              {Object.keys(user).length ? (
                                <button
                                  onClick={this.handlePurchase}
                                  className={classes.Primary_btn}
                                >
                                  <i className="fa fa-shopping-cart ml-2"></i>
                                  اضف الى السلة
                                </button>
                              ) : (
                                <div>
                                  <Link
                                    className={classes.Primary_btn}
                                    to={`/login`}
                                  >
                                    تسجيل دخول
                                  </Link>
                                  <div className="small_font grey mt-2">
                                    يجب عليك تسجيل الدخول اولاً حتى تتمكن من
                                    الشراء
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* recommended services */}
                        <div className="content_card mb-4">
                          <div className="card_head mb-3">
                            <h3 className="h6 medium_font">خدمات مقترحة</h3>
                          </div>
                          <div className="card_content">
                            <div className="row">
                              {service.related?.map((e) => (
                                <div className="col-md-6">
                                  <div className="d-flex align-items-center mb-3">
                                    <Link
                                      to={`/services/${e.id}`}
                                      className="ml-2"
                                    >
                                      <img
                                        width="95"
                                        alt="service"
                                        src={siteLogo}
                                      />
                                    </Link>
                                    <h3 className="h6">
                                      <Link
                                        to={`/services/${e.id}`}
                                        className="text-dark"
                                      >
                                        {e.title}
                                      </Link>
                                    </h3>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* Reviews */}
                        <div className="content_card">
                          <div className="card_head">
                            <h3 className="h6 medium_font">تقييمات الخدمة</h3>
                          </div>
                          <div className="card_content mt-3 mb-4">
                            {service.rates?.length ? (
                              service.rates.map((e, i) => (
                                <React.Fragment>
                                  <div className="d-flex">
                                    <img
                                      className="img_small rounded-circle"
                                      src="https://avatars.hsoubcdn.com/da0fdeb74f0491ffa7200aeb5153cc3c?s=128"
                                      alt="Mohamed ahmed"
                                    />
                                    <div className="mr-2">
                                      <h3 className="h6 medium_font">
                                        <i className="fa fa-thumbs-up ml-1"></i>
                                        <button>Mohamed Ahmed</button>
                                      </h3>
                                      <div className="grey small_font">
                                        <i className="fa fa-clock-o ml-2"></i>
                                        {e.created_at}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="small_font mt-3">
                                    {e.rate_desc}
                                  </p>
                                </React.Fragment>
                              ))
                            ) : (
                              <h3 className="h5 grey text-center mb-3">
                                لايوجد تقييمات حتى الأن
                              </h3>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="content_card">
                          <div className="card_head mb-2">
                            <div className="text-center">
                              <h3 className="h6 medium_font mb-0">
                                سعر الخدمة
                              </h3>
                              <div className="d-flex justify-content-center align-items-center">
                                <div className={classes.Primary + " h2"}>
                                  {service.price}
                                </div>
                                <sup className="mr-2"> ر.س</sup>
                              </div>
                            </div>
                            <h3 className="h6 medium_font">تقييم الخدمة</h3>
                          </div>
                          <div className="card_content">
                            <div className="user_rate mb-3 d-flex justify-content-between">
                              {generateStars(service.total_rate)}
                            </div>

                            <div className="mb-3">
                              <i className="fa fa-eye ml-2"></i>
                              مشاهدات: {service.views}
                            </div>
                            <div className="mb-3">
                              <i className="fa fa-shopping-cart ml-2"></i>
                              اشتريا هذه الخدمة: {service.orders_count}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </UserConsumer>
          )}
        </main>
        <div
          className="modal fade"
          id="msgModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <form onSubmit={this.sendMsg}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    إرسال رسالة جديدة
                  </h5>
                </div>
                <div className="modal-body">
                  <label htmlFor="msg" className="grey small_font">
                    الرسالة
                  </label>
                  <textarea
                    name="msg"
                    className={classes.FormControl}
                    row="4"
                    required
                    placeholder="اريد الاستفسار عن الخدمة ...."
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button data-dismiss="modal" className="modalCloser"></button>
                  <button type="submit" className={classes.Primary_btn}>
                    إرسال
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="reportModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <form onSubmit={this.reportService}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    بلاغ جديد
                  </h5>
                </div>
                <div className="modal-body">
                  <label htmlFor="title" className="grey small_font">
                    العنوان
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    onChange={this.handleChange}
                    className={classes.FormControl}
                  />
                  <label htmlFor="msg" className="grey small_font">
                    الوصف
                  </label>
                  <textarea
                    name="desc"
                    className={classes.FormControl}
                    row="4"
                    required
                    placeholder="اريد الابلاغ عن الخدمة ...."
                    onChange={this.handleChange}
                  ></textarea>
                </div>
                <div className="modal-footer">
                  <button
                    data-dismiss="modal"
                    className="reportCloser"
                  ></button>
                  <button type="submit" className={classes.Primary_btn}>
                    إرسال
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
