import React from 'react';
import './Loader.css';

const loader = (props) => {
  return (
    <div className={'loaderWrapper ' + props.home}>
      <div className="loader"></div>
    </div>
  );
};

export default loader;
