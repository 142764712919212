import React, { Component } from "react";
import classes from "../UI/UI.module.css";
import "./frontTest.css";
import Countdown from "react-countdown";
import bg from "../Trainer/trainerBg.jpg";
import "../Trainer/trainer.css";
import certIcon from "./merit.svg";
import failedIcon from "./failed.svg";
import { toastr } from "../../Helper/Helper";
import axios from "../../axios-api";
import Loader from "../UI/Loader/Loader";
import { Link } from "react-router-dom";
class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      date: null,
      isAgreed: false,
    };
  }
  startExam = () => {
    this.setState({ isLoading: true });
    const id = this.props.course.id;
    const token = localStorage.getItem("studentToken");
    axios
      .post(`/exam/${id}/start`, null, {
        headers: {
          token,
        },
      })
      .then(() => {
        toastr("success", "ابدء الاختبار الأن");
        this.setExamTime();
      })
      .catch((err) => {
        toastr("error", "لقد اجتزت الاختبار من قبل");
        this.setState({ isLoading: false });
      });
  };
  setExamTime = () => {
    const time = Number(this.props.course.exam_duration);
    this.setState({
      date: Date.now() + time * 60 * 1000,
      isAgreed: true,
      isLoading: false,
    });
  };
  generateField = (q, id) => {
    const path = "https://acplatform.net/storage/";
    return (
      <div className="single_question" key={id}>
        <h3 className="medium_font h5">
          <span>{id + 1}</span>
          {q.question}
        </h3>
        <div className="row">
          {q.image ? (
            <div className="col-md-8 mx-auto mb-3">
              <img src={path + q.image} alt={`صورة السؤال ${id}`} />
            </div>
          ) : null}
          {Number(q.is_boolean) ? (
            <React.Fragment>
              <div className="col-6 mb-4">
                <label
                  htmlFor={`yes-${id}`}
                  className={[
                    classes.CustomCheckbox,
                    classes.Radio,
                    "ml-4 medium_font",
                  ].join(" ")}
                >
                  <input
                    onChange={() => this.props.answerHandler(id, true)}
                    type="radio"
                    name={`q-${id}`}
                    id={`yes-${id}`}
                    required
                  />
                  <span className="ml-2"></span>
                  صح
                </label>
              </div>
              <div className="col-6 mb-4">
                <label
                  htmlFor={`no-${id}`}
                  className={[
                    classes.CustomCheckbox,
                    classes.Radio,
                    "medium_font",
                  ].join(" ")}
                >
                  <input
                    onChange={() => this.props.answerHandler(id, false)}
                    type="radio"
                    name={`q-${id}`}
                    id={`no-${id}`}
                    required
                  />
                  <span className="ml-2"></span>
                  خطأ
                </label>
              </div>
            </React.Fragment>
          ) : (
            q.answers?.map((answer, i) => this.generateAnswer(answer, id, i))
          )}
        </div>
      </div>
    );
  };
  generateAnswer = (field, qId, aId) => {
    return (
      <div className="col-md-6 mb-4" key={`${qId}-${aId}`}>
        <label
          htmlFor={`q-${qId}-${aId}`}
          className={[
            classes.CustomCheckbox,
            classes.Radio,
            "medium_font",
          ].join(" ")}
        >
          <input
            onChange={() => this.props.answerHandler(qId, aId)}
            type="radio"
            name={`q-${qId}`}
            id={`q-${qId}-${aId}`}
            required
          />
          <span className="ml-2"></span>
          {field.answer}
        </label>
      </div>
    );
  };

  render() {
    const msg = this.props.messages;
    const { logos, name, id } = this.props.trainer || "";
    const { isAgreed, date } = this.state;
    const fields = this.props.questions;
    const { is_pass, degree_percentage } = this.props.student;
    const path = "https://acplatform.net/storage/";
    return (
      <React.Fragment>
        {this.state.isLoading ? <Loader /> : null}
        <div
          className="userTop_details mb-3"
          style={{
            backgroundImage: this.props.bg
              ? `url(${path}${this.props.bg})`
              : `url(${bg})`,
          }}
        >
          <div className="container-lg container-fluid position-relative">
            <div className="row">
              <div className="col-md-6">
                <h1 className="h4 bold_font text-white text-right mb-3 mb-md-0">
                  {this.props.course.title}
                </h1>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  {logos ? (
                    <img
                      width="70"
                      className="rounded-circle ml-3"
                      src={logos ? logos[0].logo : null}
                      alt="شعار المدرب"
                    />
                  ) : null}
                  <div>
                    <h3 className="h6 medium_font text-white mb-3">{name}</h3>
                    <Link
                      to={`/trainer/${id}`}
                      className={classes.Primary_btn + " small_font"}
                    >
                      <i className="fa fa-eye ml-2"></i>
                      صفحة المدرب
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isAgreed ? (
          <div className="container-lg container-fluid position-relative">
            <div className="row">
              {this.props.isPassed ? (
                <div className="col-md-6 mx-auto p-0">
                  <div className="bg-white p-4 rounded shadow-sm">
                    <React.Fragment>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="d-flex flex-md-row flex-column align-items-center">
                            <div>
                              <div className="degree_circle ml-md-2">
                                {`${Number(degree_percentage)} %`}
                              </div>
                              <div className="text-center">درجتك</div>
                            </div>

                            <div className="text-md-right text-center">
                              <h3
                                className={
                                  "medium_font h4 " +
                                  (Number(is_pass)
                                    ? "text-success"
                                    : "text-danger")
                                }
                              >
                                {Number(is_pass)
                                  ? msg.success
                                  : "لقد اجتزت الاختبار بنجاح "
                                  ? msg.fail
                                  : "رسبت فى الاختبار"}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 text-md-left text-center">
                          <img
                            width="120"
                            src={Number(is_pass) ? certIcon : failedIcon}
                            alt="صورة الشهادة"
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="col-md-8">
                    <form>
                      {fields
                        ? fields.map((f, i) => this.generateField(f, i))
                        : null}
                    </form>
                  </div>
                  <div className="col-md-4">
                    <div className="bg-white text-center rounded mt-4 mt-md-0 p-4 shadow-sm exam_time">
                      <h3 className="h6 medium_font d-md-block d-none">
                        الوقت المتبقى
                      </h3>
                      <Countdown
                        onComplete={() => this.props.timeOut()}
                        date={date}
                        renderer={(props) => (
                          <div className="time_circle">
                            {props.minutes}:{props.seconds}
                          </div>
                        )}
                      />
                      <button
                        onClick={() => this.props.handleSend()}
                        className={classes.Primary_btn + " btn-block mt-4"}
                      >
                        تسليم الاختبار
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <div className="col-md-6 bg-white shadowm-sm rounded p-4 mx-auto p-0 position-relative">
            <h3 className="medium_font h5 grey text-center mb-3">
              التعليمات الأساسية
            </h3>
            <div className="alert alert-warning" role="alert">
              <ul style={{ paddingRight: "1rem", marginBottom: 0 }}>
                <li style={{ listStyle: "square" }}>
                  بمجرد الموافقة على الشروط , سيبدء العد التنازلى تلقائياً
                </li>
                <li style={{ listStyle: "square" }}>
                  اذا بدء الاختبار واغلقت الصفحة , لن تتمكن من استكمال الاختبار
                  وسيتم الغاء النتيجة
                </li>
              </ul>
            </div>
            <h3 className="medium_font h6">تعليمات الاختبار</h3>
            <p className={classes.Grey}>{this.props.course.content}</p>

            <button onClick={this.startExam} className={classes.Primary_btn}>
              اوافق على الشروط
            </button>

            <div className="text-center mt-3">
              اذا تود التسجيل فى الاختبار بحساب اخر ,
              <button
                onClick={() => this.props.logOut()}
                className={classes.Primary + " medium_font mr-2"}
              >
                تسجيل خروج
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Questions;
