import React, { Component } from "react";
import UserAvatar from "../../Navigation/NavItems/Male-Avatar.png";
import classes from "../../UI/UI.module.css";
import UserCourse from "./UserCourse";
import { Link } from "react-router-dom";
import "./UserDetails.css";
import { UserConsumer } from "../../../Context/User-context";
import axios from "../../../axios-api";
import { toastr } from "../../../Helper/Helper";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.querySelector(".whats_link").classList.remove("d-none");
  }
  componentWillUnmount() {
    document.querySelector(".whats_link").classList.add("d-none");
  }
  handleDeleteCourse = (id) => {
    if (window.confirm("هل انت متأكد من حذف الدورة")) {
      axios
        .delete(`/account/course/${id}`)
        .then((res) => {
          this.props.updateCourses();
          toastr("success", "تم حذف الدورة بنجاح");
        })
        .catch((err) => this.setState({ isLoading: false }));
    }
  };

  render() {
    const courses = this.props.user.courses;
    const logo = this.props.user.logos;
    const mainLogo = logo.find((e) => e.main === 1);
    return (
      <UserConsumer>
        {(user) => (
          <div className="container-lg container-fluid mt-5">
            <div className="row">
              <div className="col-md-3">
                <div className="sidebar_wrapper">
                  <div className="user_sidebar mb-4">
                    <img
                      className="mb-3"
                      src={logo && mainLogo ? mainLogo.logo : UserAvatar}
                      alt="صورة العضو"
                    />
                    <p>
                      <i className="fa fa-user ml-2"></i>
                      {this.props.user.name}
                    </p>
                    <p>
                      <i className="fa fa-envelope ml-2"></i>
                      {this.props.user.email}
                    </p>
                    <p className="mb-2">
                      <i className="fa fa-phone ml-2"></i>
                      {this.props.user.phone}
                    </p>

                    <p>
                      <Link className="d-inline-block ml-2" to="/account/edit">
                        الحساب
                      </Link>
                      <Link
                        className="ml-2 d-inline-block"
                        to={`/trainer/${this.props.user?.id}`}
                      >
                        صفحتى
                      </Link>
                      <Link className=" d-inline-block" to="/account/my-orders">
                        طلباتى
                      </Link>
                    </p>
                  </div>
                  <div className="user_sidebar side_balance mb-4">
                    <h3 className="text-center h6 medium_font">
                      انت على
                      {this.props.user.plan !== null
                        ? ` ${this.props.user.plan.title}`
                        : " الخطة الافتراضية"}
                    </h3>
                    <div className="balance_number text-center mb-3">
                      {this.props.user.balance}
                      <sup className="small">شهادة</sup>
                    </div>

                    <div className="text-center">
                      <p className="mb-0">
                        <Link to="/pricing-plans">تجديد الاشتراك</Link>
                      </p>
                    </div>
                  </div>
                  {/* {Number(user.can_sell_online) ? (
                    ) : null} */}
                  <div className="user_sidebar side_balance mb-4">
                    <h3 className="text-center h6 medium_font">رصيدى</h3>
                    <div className="balance_number text-center mb-3">
                      {this.props.user.sell_online_balance}
                      <sup className="small">ر.س</sup>
                    </div>

                    <div className="text-center">
                      <Link
                        to="/account/balance-withdraw"
                        className="ml-3 small_font"
                      >
                        سحب الرصيد
                      </Link>
                      <Link
                        to="/account/balance-details"
                        className="small_font"
                      >
                        التفاصيل
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-9">
                <div className="row mb-3">
                  <div className="col-md-3 mb-md-0 mb-3">
                    <h1 className="h4 bold_font text-md-right text-center mt-4 mt-md-0">
                      لوحة التحكم
                    </h1>
                  </div>
                  <div className="col-md-9 text-md-left text-center">
                    {user.plan ? (
                      <div className="d-inline-block">
                        <Link
                          to="/account/add-course"
                          className={classes.Primary_btn}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          دورة جديدة
                        </Link>
                        <Link
                          to="/account/add-test"
                          className={classes.Transparent_btn + " mr-2"}
                        >
                          <i className="fa fa-plus ml-2"></i>
                          اختبار جديد
                        </Link>
                      </div>
                    ) : (
                      <Link
                        className={classes.Transparent_btn}
                        to="/pricing-plans"
                      >
                        اشترك الان
                      </Link>
                    )}
                    <Link
                      to="/account/add-service"
                      className={classes.Transparent_btn + " mr-2"}
                    >
                      <i className="fa fa-plus ml-2"></i>
                      اضف خدمة
                    </Link>
                  </div>
                </div>
                {!user.plan ? (
                  <div
                    className="alert alert-warning alert-dismissible fade show mt-3 py-2"
                    role="alert"
                  >
                    لايمكنك إضافة دورة قبل ان تشترك فى احد الخطط , اشترك الان
                    حتى تتمتع بمزايا المنصة
                  </div>
                ) : null}
                {courses.length === 0 ? (
                  <div className="bg-white p-4 shadow-sm rounded text-center">
                    <h3 className={classes.Grey + " medium_font h5"}>
                      لايوجد دورات حتى الان
                    </h3>
                  </div>
                ) : null}

                <div className="table-responsive courses_table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>اسم الدورة</th>
                        <th>تاريخ البداية</th>
                        <th>تاريخ النهاية</th>
                        <th>عدد الطلاب</th>
                        <th>خيارات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses !== undefined
                        ? courses.map((e) => {
                            return (
                              <UserCourse
                                plan={this.props.user.plan}
                                key={e.id}
                                students={e.students_count}
                                courseName={e.title}
                                startDate={e.start_date}
                                endDate={e.end_date}
                                deleteCourse={() =>
                                  this.handleDeleteCourse(e.id)
                                }
                                id={e.id}
                              ></UserCourse>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default UserDetails;
