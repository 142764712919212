import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { toastr } from "../../../Helper/Helper";

export default class EditService extends Component {
  state = {
    isLoading: false,
    payload: {
      service_category_id: null,
      title: "",
      desc: "",
      delivery_time: null,
      instructions: "",
      price: null,
    },
    additions: [],
    cat: {},
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({ isLoading: true });

    axios.get(`/trainer/services/${params.serviceId}`).then((res) => {
      this.setState({
        payload: res.data,
        additions: res.data.additions,
        isLoading: false,
      });
      axios
        .get(`/services/category/${res.data.service_category_id}`)
        .then((res) =>
          this.setState({ cat: res.data.category, isLoading: false })
        );
    });
  }

  handleChange = (type = null, id = null, e) => {
    const { name, value } = e.target;
    const {
      payload: { images },
      payload,
      additions,
    } = this.state;
    if (type === "addition") {
      additions[id][name] = value;
    } else if (type === "video") {
      images[id][name] = value;
    } else {
      payload[name] = value;
    }
    this.forceUpdate();
  };
  addNewVideo = () => {
    const {
      payload: { images },
    } = this.state;
    images.push({ type: "youtube", image: "" });
    this.forceUpdate();
  };
  handleSelect = (e) => {
    const { value } = e.target;
    const { payload } = this.state;

    this.setState({ isLoading: true });
    axios
      .get(`/services/category/childs/${value}`)
      .then((res) => {
        if (res.data.length) {
          this.setState({
            sub_cats: res.data,
          });
        } else {
          this.setState({ sub_cats: [] });
          payload.service_category_id = value;
          this.forceUpdate();
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  addNewAddition = () => {
    const additionObj = {
      title: "",
      is_addition_time: 1,
      price: null,
      addition_time: null,
    };
    this.setState((prev) => ({
      additions: [...prev.additions, additionObj],
    }));
  };
  deleteAddition = (index, id) => {
    const { additions } = this.state;
    this.setState({ isLoading: true });
    axios.post(`/additional/destroy/${id}`).then(() => {
      additions.splice(index, 1);
      this.setState({ isLoading: false });
      this.forceUpdate();
    });
  };
  deleteImg = (id) => {
    const {
      payload: { images },
    } = this.state;
    axios.post(`/image/destroy/${id}`).then((res) => {
      const newImgs = images.filter((img) => img.id !== id);
      this.setState((prev) => ({
        payload: {
          ...prev.payload,
          images: newImgs,
        },
      }));
    });
  };

  handleIncrease = (id, e) => {
    const { value } = e.target;
    const { additions } = this.state;
    additions[id].is_addition_time = Math.floor(value) ? true : false;
    this.forceUpdate();
  };
  handleChangeStatus = ({ meta, file }, status) => {
    this.setState({ image: file });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.postingData();
  };
  postingData = () => {
    const {
      payload: {
        id,
        title,
        desc,
        delivery_time,
        user_id,
        instructions,
        price,
        images,
        service_category_id,
      },
      additions,
    } = this.state;

    const data = {
      service_category_id,
      title,
      user_id,
      desc: desc?.split(/\n/).join("<br/>"),
      delivery_time,
      instructions,
      price,
      add: additions,
      images,
    };

    const formData = new FormData();
    for (const key in data) {
      if (key === "images" && data[key].length) {
        data[key].forEach((e, i) => {
          if (!e.id) {
            formData.append(
              `images[${i}][image]`,
              e.type === "youtube" ? e.image : e
            );
            formData.append(
              `images[${i}][type]`,
              e.type === "youtube" ? "youtube" : "image"
            );
          }
        });
      } else if (key === "add") {
        data[key].forEach((a, i) => {
          Object.keys(a).forEach((o, index) => {
            formData.append(`${key}[${a.id ? a.id : i}][${o}]`, a[o]);
          });
        });
      } else {
        formData.append(key, data[key]);
      }
    }
    axios
      .post(`/services/update/${id}`, formData)
      .then((res) => {
        toastr("success", "تمت إضافة الخدمة بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        toastr("warning", "يوجد خطأ فى البيانات");
        // if (errors.images) {
        //   toastr("warning", "يجب إرفاق صورة واحدة للخدمة على الاقل");
        // }
        this.setState({ isLoading: false });
      });
  };
  mainImgHandler = (img, i) => {
    this.setState({ isLoading: true });

    const {
      payload: { images },
    } = this.state;
    axios
      .post(`/image/main/${img.id}`)
      .then((res) => {
        images[i].main = 1;
        this.forceUpdate();
        toastr("success", "تم تغيير حالة الصورة بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({}));
  };
  deleteService = (id) => {
    if (window.confirm("هل انت متأكد من حذف الخدمة")) {
      this.setState({ isLoading: true });
      axios
        .delete(`/services/destroy/${id}`)
        .then((res) => {
          this.setState({ isLoading: false });
          this.props.history.push("/account");
        })
        .catch((err) => this.setState({ isLoading: false }));
    }
  };
  deleteVideo = (i) => {
    const {
      payload: { images },
    } = this.state;
    images.splice(i, 1);
    this.forceUpdate();
  };
  render() {
    const { additions, cat, isLoading, payload } = this.state;
    return (
      <div className="container-lg container-fluid mt-3">
        {isLoading ? <Loader /> : null}
        <div className="col-md-8 mx-auto p-0">
          <h1 className="h5 bold_font mb-3">تعديل الخدمة</h1>
          <div className="bg-white p-4 mb-4">
            {/*  New Service content  */}
            <form onSubmit={this.handleSubmit}>
              {payload.status === "pending" ? (
                <div
                  className="alert alert-warning alert-dismissible fade show  py-2"
                  role="alert"
                >
                  هذه الخدمة مازالت بانتظار موافقة الإدارة
                </div>
              ) : null}
              {payload.status_reason ? (
                <div className="mb-3 text-danger">
                  <i className="fa fa-info ml-2"></i>
                  {payload.status_reason}
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="serviceName"
                    >
                      اسم الخدمة
                    </label>
                    <input
                      id="serviceName"
                      type="text"
                      name="title"
                      required
                      value={payload.title}
                      className={classes.FormControl}
                      onChange={this.handleChange.bind(this, null, null)}
                    />
                    <small className="grey small_font">
                      أدخل عنواناً واضحاً باللغة العربية يصف الخدمة التي تريد أن
                      تقدمها
                    </small>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="price"
                    >
                      سعر الخدمة
                    </label>
                    <input
                      id="price"
                      type="number"
                      name="price"
                      required
                      value={payload.price}
                      className={classes.FormControl}
                      onChange={this.handleChange.bind(this, null, null)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="serviceCat"
                    >
                      تصنيف الخدمة
                    </label>
                    <select
                      name="service_category_id"
                      id="serviceCat"
                      className={classes.FormControl}
                    >
                      <option value={cat.id}>{cat.title}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="caption"
                >
                  وصف الخدمة
                </label>
                <textarea
                  onChange={this.handleChange.bind(this, null, null)}
                  name="desc"
                  id="caption"
                  className={classes.FormControl}
                  rows="2"
                  required
                  value={payload?.desc?.replace(/<br\s*[\/]?>/gi, "\n")}
                ></textarea>
                <small className="grey small_font">
                  أدخل وصف الخدمة بدقة يتضمن جميع المعلومات والشروط . يمنع وضع
                  البريد الالكتروني، رقم الهاتف أو أي معلومات اتصال أخرى.
                </small>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="serviceName"
                >
                  معرض صور الخدمة
                </label>
                {payload.images?.length ? (
                  <div className="bg-light pt-3 px-3 pb-0 rounded mb-2">
                    <div className="row">
                      {payload.images?.map((img, i) => (
                        <div className="col-md-2 col-sm-4 col-md-3 mb-3">
                          <div
                            key={i}
                            className="border rounded ml-3 p-2 position-relative"
                          >
                            {img.type === "image" ? (
                              <React.Fragment>
                                <button
                                  data-toggle="tooltip"
                                  title="الصورة الرئيسية"
                                  type="button"
                                  className="img_main"
                                  onClick={this.mainImgHandler.bind(
                                    this,
                                    img,
                                    i
                                  )}
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                                <img
                                  src={img.full_path}
                                  alt={"صورة الخدمة"}
                                  width="72"
                                  height="72"
                                />
                              </React.Fragment>
                            ) : (
                              `فيديو-${i + 1}`
                            )}
                            <div className="text-center">
                              <button
                                type="button"
                                className="text-danger"
                                onClick={this.deleteImg.bind(this, img.id)}
                              >
                                حذف
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                <div className="mb-3">
                  <Dropzone
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleUpload}
                    inputContent={
                      <div>
                        <i className="fa fa-upload ml-2"></i>
                        رفع صور الخدمة
                      </div>
                    }
                    accept="image/*"
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="grey small_font">معرض فيديو</label>
                  <div className="row">
                    {payload.images?.map((e, i) =>
                      e.type === "youtube" && !e.id ? (
                        <div className="col-md-6 mb-3" key={i}>
                          <div className="d-flex">
                            <input
                              type="url"
                              name="image"
                              placeholder="(https://www.youtube.com/embed/42-k6YQLa_I)"
                              onChange={this.handleChange.bind(
                                this,
                                "video",
                                i
                              )}
                              className={classes.FormControl}
                              required
                            />
                            <button
                              type="button"
                              className="btn btn-outline-danger mr-2"
                              onClick={this.deleteVideo.bind(this, i)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                  <button
                    onClick={this.addNewVideo}
                    className={classes.Transparent_btn}
                    type="button"
                  >
                    <i className="fa fa-plus ml-2"></i>
                    فيديو يوتيوب جديد
                  </button>
                </div>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="delivery_time"
                >
                  مدة التسليم
                </label>
                <select
                  onChange={this.handleChange.bind(this, null, null)}
                  className={classes.FormControl}
                  name="delivery_time"
                  required
                >
                  <option
                    value="1"
                    selected={payload.delivery_time == 1 ? true : false}
                  >
                    يوم واحد
                  </option>
                  <option
                    value="2"
                    selected={payload.delivery_time == 2 ? true : false}
                  >
                    يومين
                  </option>
                  <option
                    value="3"
                    selected={payload.delivery_time == 3 ? true : false}
                  >
                    ثلاثة أيام
                  </option>
                  <option
                    value="4"
                    selected={payload.delivery_time == 4 ? true : false}
                  >
                    أربعة أيام
                  </option>
                  <option
                    value="5"
                    selected={payload.delivery_time == 5 ? true : false}
                  >
                    خمس أيام
                  </option>
                  <option
                    value="6"
                    selected={payload.delivery_time == 6 ? true : false}
                  >
                    ستة أيام
                  </option>
                  <option
                    value="7"
                    selected={payload.delivery_time == 7 ? true : false}
                  >
                    أسبوع
                  </option>
                  <option
                    value="14"
                    selected={payload.delivery_time == 4 ? true : false}
                  >
                    أسبوعين
                  </option>
                  <option
                    value="21"
                    selected={payload.delivery_time == 1 ? true : false}
                  >
                    ثلاثة أسابيع
                  </option>
                  <option
                    value="30"
                    selected={payload.delivery_time == 0 ? true : false}
                  >
                    شهر
                  </option>
                </select>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="caption"
                >
                  تعليمات للمشترى
                </label>
                <textarea
                  name="instructions"
                  onChange={this.handleChange.bind(this, null, null)}
                  id="caption"
                  value={payload.instructions}
                  className={classes.FormControl}
                  rows="2"
                ></textarea>
                <small className="grey small_font">
                  المعلومات التي تحتاجها من المشتري لتنفيذ الخدمة. تظهر هذه
                  المعلومات بعد شراء الخدمة فقط.
                </small>
              </div>
              <div className="text-left">
                <button
                  onClick={this.addNewAddition}
                  className={classes.Transparent_btn}
                  type="button"
                >
                  <i className="fa fa-plus ml-2"></i>
                  اضف تطوير جديد
                </button>
              </div>
              {additions.length
                ? additions.map((a, i) => (
                    <React.Fragment key={i}>
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                        >
                          عنوان التطوير
                        </label>
                        <input
                          type="text"
                          name="title"
                          onChange={this.handleChange.bind(this, "addition", i)}
                          className={classes.FormControl}
                          value={a.title}
                        />
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <div className="form-group mb-4">
                            <select
                              name="is_addition_time"
                              onChange={this.handleIncrease.bind(this, i)}
                              className={classes.FormControl}
                            >
                              <option
                                value="1"
                                selected={a.is_addition_time ? true : false}
                              >
                                سيزيد من مدة تنفيذ الخدمة
                              </option>
                              <option
                                value="0"
                                selected={!a.is_addition_time ? true : false}
                              >
                                لن يزيد من مدة الخدمة
                              </option>
                            </select>
                          </div>
                        </div>

                        {a.is_addition_time ? (
                          <div className="col-md-3">
                            <select
                              name="addition_time"
                              onChange={this.handleChange.bind(
                                this,
                                "addition",
                                i
                              )}
                              className={classes.FormControl + " mb-4"}
                            >
                              <option
                                value="1"
                                selected={1 == a.addition_time ? true : false}
                              >
                                يوم واحد
                              </option>
                              <option
                                value="2"
                                selected={2 == a.addition_time ? true : false}
                              >
                                يومين
                              </option>
                              <option
                                value="3"
                                selected={3 == a.addition_time ? true : false}
                              >
                                ثلاثة أيام
                              </option>
                              <option
                                value="4"
                                selected={4 == a.addition_time ? true : false}
                              >
                                أربعة أيام
                              </option>
                              <option
                                value="5"
                                selected={5 == a.addition_time ? true : false}
                              >
                                خمس أيام
                              </option>
                              <option
                                value="6"
                                selected={6 == a.addition_time ? true : false}
                              >
                                ستة أيام
                              </option>
                              <option
                                value="7"
                                selected={7 == a.addition_time ? true : false}
                              >
                                أسبوع
                              </option>
                              <option
                                value="14"
                                selected={14 == a.addition_time ? true : false}
                              >
                                أسبوعين
                              </option>
                              <option
                                value="21"
                                selected={21 == a.addition_time ? true : false}
                              >
                                ثلاثة أسابيع
                              </option>
                              <option
                                value="30"
                                selected={30 == a.addition_time ? true : false}
                              >
                                شهر
                              </option>
                            </select>
                          </div>
                        ) : null}
                        <div className="col-md-3">
                          <div className="form-group mb-4">
                            <input
                              onChange={this.handleChange.bind(
                                this,
                                "addition",
                                i
                              )}
                              type="number"
                              placeholder="سعر الإضافة"
                              className={classes.FormControl}
                              name="price"
                              value={a.price}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 mb-4">
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={this.deleteAddition.bind(this, i, a.id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                : null}

              <div className="row mt-3 align-items-center">
                <div className="col-6">
                  <button className={classes.Primary_btn}>تعديل الخدمة</button>
                </div>
                <div className="col-6 text-md-left">
                  <button
                    onClick={this.deleteService.bind(this, payload.id)}
                    className="btn btn-outline-danger"
                  >
                    حذف الخدمة
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
