import React, { Component } from "react";
import loginImg from "./austin-distel-wD1LRb9OeEo-unsplash.jpg";
import axios from "../../axios-api";
import Loader from "../UI/Loader/Loader";
import { Link } from "react-router-dom";
import { toastr } from "../../Helper/Helper";
import classes from "../UI/UI.module.css";
import { WOW } from "wowjs";
import "../AboutUs/about.css";
import OptionsContext from "../../Context/Options-context";

class Login extends Component {
  static contextType = OptionsContext;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    this.setState({ isLoading: true });
    const { email, password } = this.state;

    axios
      .post("/auth/login", { email, password })
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.handleSuccessfulAuth(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "يوجد خطأ فى البيانات او الحساب غير موجود");
      });

    event.preventDefault();
  };
  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });

    wow.init();
  }
  render() {
    const options = this.context;
    const loginImg = options.find((o) => o.key === "login_img");
    const pathName = `https://acplatform.net/storage/`;
    const { isLoading, errors } = this.state;

    return (
      <div
        className="aboutWrapper"
        style={{
          backgroundImage: loginImg
            ? `url(${pathName + loginImg.value})`
            : null,
        }}
      >
        {isLoading === true ? <Loader /> : null}
        <div className="container-fluid container-lg">
          <div className="row align-items-center">
            <div className="col-md-6 mr-auto">
              <div className="register_wrapper wow fadeInUp">
                <h2 className="bold_font mb-4 h4">تسجيل دخول</h2>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="userName"
                    >
                      البريد الإلكترونى
                    </label>
                    <div className="position-relative">
                      <i className={classes.Input_icon + " fa fa-user"}></i>
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        className={classes.FormControl}
                        id="userName"
                        onChange={this.handleChange}
                      />
                    </div>
                    {errors != null ? (
                      <p className="text-danger small mb-0">{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-2">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="userName"
                    >
                      كلمة المرور
                    </label>
                    <div className="position-relative">
                      <i className={classes.Input_icon + " fa fa-key"}></i>
                      <input
                        type="password"
                        name="password"
                        value={this.state.passowrd}
                        className={classes.FormControl}
                        id="userName"
                        onChange={this.handleChange}
                      />
                    </div>

                    {errors != null ? (
                      <p className="text-danger small mb-0">
                        {errors.password}
                      </p>
                    ) : null}
                  </div>

                  <div className="row align-items-center mb-4">
                    <div className="col-6">
                      <label htmlFor="agree" className={classes.CustomCheckbox}>
                        <input type="checkbox" name="" id="agree" />
                        <span className="ml-2"></span>
                        تذكرنى
                      </label>
                    </div>
                    <div className="col-6 text-left">
                      <Link
                        to="/forget-password"
                        className={[classes.Primary, classes.Medium_font].join(
                          " "
                        )}
                      >
                        هل نسيت كلمة المرور ؟
                      </Link>
                    </div>
                  </div>
                  <button type="submit" className={classes.Primary_btn}>
                    تسجيل دخول
                  </button>
                </form>
                <div className="text-center mt-4">
                  ليس لديك حساب
                  <Link
                    to={`/register`}
                    className={[classes.Primary, classes.Medium_font].join(" ")}
                  >
                    {" "}
                    انشئ حساب الان
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
