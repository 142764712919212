import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import { paypalParams, toastr } from "../../../Helper/Helper";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { UserConsumer } from "../../../Context/User-context";

class Checkout extends Component {
  state = {
    hasOrder: false,
    withWallet: false,
    order: {},
    paypalStatus: null,
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    const {
      location: { search },
      match: { params },
    } = this.props;
    console.log(search);
    const urlParams = new URLSearchParams(search);
    const query = paypalParams(urlParams);
    const { payment, token, payerId, canceled } = query;

    this.makePaypalDone(params.orderId, { payment, token, payerId, canceled });
    axios
      .get(`/services/orders/${params.orderId}`)
      .then((res) => {
        if (res.data.status === "needs_action") {
          this.props.history.push(
            `/account/chat/${res.data.message.id}?type=orderMsg`
          );
        } else {
          this.setState({ isLoading: false, order: res.data });
        }
      })
      .catch((err) => this.setState({ isLoading: false }));
  }
  changePayment = (e) => {
    this.setState({ withWallet: e.target.checked });
  };
  handlePurchase = (type) => {
    this.setState({ isLoading: true });
    const {
      withWallet,
      order: { id },
    } = this.state;

    const url = withWallet
      ? "/services/order/payWallet"
      : "/services/order/payment";

    axios
      .post(url, { order_id: id, payment_method: type })
      .then((res) => {
        if (res.data.error === "balance_lower_than_order_price") {
          toastr("warning", "لايوجد رصيد كاف , يجب عليك الشراء اونلاين");
        } else if (res.data.id) {
          this.props.history.push(
            `/account/chat/${res.data.message.id}?type=orderMsg`
          );
        } else {
          window.open(res.data, "_blank");
          toastr("success", "تم الدفع بنجاح");
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  makePaypalDone = (id, payload) => {
    if (payload.payment) {
      this.setState({ paypalStatus: "success" });
    } else if (payload.canceled) {
      this.setState({ paypalStatus: "danger" });
    }
    const query =
      payload && payload.payment
        ? `?paymentId=${payload.payment}&token=${payload.token}&PayerID=${payload.payerId}`
        : payload === "canceled"
        ? "?canceled"
        : null;
    axios
      .get(`/account/service_order/${id}${payload ? query : ""}`)
      .then((res) => console.log(res))
      .catch((err) => this.setState({ isLoading: false }));
  };
  render() {
    const { order, isLoading, withWallet, paypalStatus } = this.state;
    return (
      <UserConsumer>
        {(user) => (
          <div className="container-lg container-fluid">
            <div className="row mt-4">
              {isLoading ? <Loader /> : null}
              <div className="col-md-6 mx-auto">
                <div className="bg-white p-4 rounded shadow-sm">
                  {typeof paypalStatus === "string" ? (
                    <div
                      className={`alert alert-${paypalStatus} alert-dismissible fade show`}
                      role="alert"
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      {paypalStatus === "success"
                        ? "تم الدفع بنجاح"
                        : "فشلت عملية الدفع"}
                    </div>
                  ) : null}
                  <h3 className="h5 medium_font mb-4 text-center">
                    {order.title}
                  </h3>
                  <div className="mb-3">
                    <i className="fa fa-clock-o ml-2 grey"></i>
                    {order.created_humans}
                  </div>
                  <div className="mb-3">
                    <i className="fa fa-ticket ml-2 grey"></i>
                    {order.status_label}
                  </div>
                  <div className="py-2 border-bottom my-2 border-top">
                    <h3 className="h6 medium_font mb-3">إضافات الطلب</h3>
                    {order.items?.map((e, i) => (
                      <div key={i} className="d-flex align-items-center mb-2">
                        {e.title}
                        <span className="mr-auto">{e.price} ر.س</span>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <span>
                      <i className="fa fa-money-bill ml-2 grey"></i>
                      الإجمالى
                    </span>
                    <div className="h5 medium_font mr-auto">
                      {order.total_price}
                      <sup> ر.س</sup>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="wallet" className={classes.CustomCheckbox}>
                      <input
                        type="checkbox"
                        name="wallet"
                        id="wallet"
                        onChange={this.changePayment}
                      />
                      <span className="ml-2"></span>
                      دفع بالرصيد ( {user.sell_online_balance} ر.س )
                    </label>
                  </div>
                  <button
                    onClick={this.handlePurchase.bind(
                      this,
                      !withWallet ? "visa" : null
                    )}
                    className={classes.Primary_btn}
                  >
                    <i className="fa fa-cc-visa ml-2"></i>
                    {withWallet ? "ادفع بالرصيد" : " دفع اونلاين"}
                  </button>
                  <button
                    onClick={this.handlePurchase.bind(this, "paypal")}
                    className={[
                      classes.Primary_btn,
                      classes.Paypal_btn,
                      " mr-3",
                    ].join(" ")}
                  >
                    <i className="fa fa-paypal ml-2"></i>
                    الدفع باى بال
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default Checkout;
