import React from "react";
import { Link } from "react-router-dom";
import "./serviceCard.css";
import classes from "../../UI/UI.module.css";
import logo from "../../../assets/imgs/logo.png";

const serviceCard = (props) => {
  const { service, edit } = props;
  const mainImg = service.images?.find((img) => img.main === 1);
  console.log(mainImg ? mainImg : null);
  return (
    <div className={props.column}>
      <div className="service_card mb-4">
        <Link
          to={
            props.pending
              ? `/account/edit-service/${service.id}`
              : `/services/${service.id}`
          }
        >
          <img
            style={{ opacity: !mainImg ? 0.2 : 1 }}
            src={mainImg ? mainImg.full_path : logo}
            alt={service.title}
          />
        </Link>
        <h3 className="h6">
          <Link
            to={
              edit
                ? `/account/edit-service/${service.id}`
                : `/services/${service.id}`
            }
          >
            {service.title}
          </Link>
        </h3>
        <div className={"text-center h5 medium_font " + classes.Primary}>
          {service.price}
          <sup className="mr-1 grey">ر.س</sup>
        </div>
      </div>
    </div>
  );
};
export default serviceCard;
