import React, { Component } from "react";
import NavigationItems from "./NavItems/NavigationItems";
import Logo from "./Logo/Logo";
import styles from "../UI/UI.module.css";
import UserAvatar from "./NavItems/Male-Avatar.png";
import { Link } from "react-router-dom";
import OptionsContext from "../../Context/Options-context";
import UserContext, { UserConsumer } from "../../Context/User-context";
import CartContext from "../../Context/Cart-context";
import axios from "../../axios-api";
import "./navbar.css";
import Pusher from "pusher-js";
import NotifyPopup from "../Account/AllNotifications/NotifyModal";

class Navbar extends Component {
  static contextType = OptionsContext;
  state = {
    msgs: {},
    user_id: null,
    results: [],
    notifys: [],
    newNotify: {},
  };

  componentDidMount() {
    const { user_id } = this.props;
    if (this.props.login) {
      this.getMsgs();
    }
  }
  getMsgs = () => {
    axios
      .get("/services/all/messages")
      .then((res) => this.setState({ msgs: res.data }))
      .catch((err) => this.setState({ isLoading: false }));
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.login !== this.props.login && this.props.login) {
      // this.getCart();
      this.getMsgs();
      this.getNotifications();
      this.setState({ user_id: this.props.user_id });
    }
    if (prevState.user_id !== this.state.user_id) {
      this.turnPusher();
    }
  }
  printMsg = (msg) => {
    return (
      <div className="dropdown-item" key={msg.id}>
        <div className="SingleMsg">
          <img
            src="https://i.pinimg.com/originals/a7/0e/16/a70e1675c7bc001f1578aa76bb0a7819.png"
            alt="ahmed mohamed"
          />
          <div>
            <Link
              to={{
                pathname: `/account/chat/${msg.id}`,
                search: "type=serviceMsg",
              }}
            >
              استفسار عن: {msg.service?.title}
            </Link>
            <div className="msg_caption">
              <i className="fa fa-clock-o ml-2"></i>
              {msg.created_at_human}
            </div>
          </div>
        </div>
      </div>
    );
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length >= 2 && name === "search") {
      axios.get(`/search/services?s=${value}`).then((res) => {
        this.setState({ results: res.data });
      });
    }
  };
  getNotifications = () => {
    axios
      .get("/notifications")
      .then((res) => {
        this.setState({ notifys: res.data });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  readMsgs = () => {
    axios.post(`/notifications/read`).then((res) => {
      const { notifys } = this.state;
      const readed = notifys.map((n) => {
        n.read_at = "read";
        return n;
      });
      this.setState({ notifys: readed, msgRead: true });
    });
  };
  turnPusher = () => {
    const { user_id, newNotify } = this.state;

    var pusher = new Pusher("97bc21717c68f612a331", {
      cluster: "ap4",
    });

    var channel = pusher.subscribe(`channel_${user_id}`);

    channel.bind("my-event", (data) => {
      console.log(data);
      const { id, type } = data;
      if (type === "service_order_message") {
        axios
          .get(this.getUrl(type, id))
          .then((res) => {
            document.getElementById("openNotify").click();
            this.setState({ newNotify: res.data });
          })
          .catch((err) => {
            this.setState({ isLoading: false });
          });
        this.setState({ newNotify });
      }
      this.getNotifications();
    });
  };

  getUrl = (type, id) => {
    if (type === "service_order_message") {
      return `/services/order/messages/${id}`;
    } else if (type === "serviceMsg") {
      return `/services/messages/${id}`;
    } else if (type === "ticket") {
      return `/tickets/${id}`;
    }
  };

  getNotifyType = (n) => {
    const {
      data: { message, item, type, order },
    } = n;

    if (type === "service_message" || type === "service_order_message") {
      const param = type === "service_message" ? "serviceMsg" : "orderMsg";
      const id =
        type === "service_order_message"
          ? message.id
          : message.service_message_id;

      return (
        <Link to={`/account/chat/${id}?type=${param}`}>
          {type === "service_message"
            ? message.message.length > 40
              ? `${message.message.substr(0, 40)}...`
              : message.message
            : item.message.length > 40
            ? `${item.message.substr(0, 40)}...`
            : item.message}
        </Link>
      );
    } else if (type === "new_order") {
      return (
        <Link to={`/account/chat/${order.message.id}?type=orderMsg`}>
          مشتريات جديدة بإنتظار التعليمات
        </Link>
      );
    }
  };
  render() {
    const { msgs, results, notifys, newNotify } = this.state;
    const unread = notifys.filter((n) => n.read_at === null);
    const options = this.context;
    // const socialMedia = options.filter((o) => {
    //   return (
    //     o.key === "facebook" || o.key === "twitter" || o.key === "instagram"
    //   );
    // });
    const siteLogo = options.find((o) => o.key === "site_logo");
    const logos = this.props.logo;
    const pathName = `https://acplatform.net/storage/`;

    let mainLogo = [];
    if (logos) {
      mainLogo = logos.find((l) => l.main === 1);
    }

    return (
      <UserConsumer>
        {(user) => (
          <React.Fragment>
            <nav className={"custom_navbar navbar navbar-expand-lg "}>
              <div className="container-lg container-fluid">
                <button
                  className="navbar-toggler grey d-inline-block ml-3"
                  type="button"
                  data-toggle="modal"
                  data-target="#menu_modal"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-bars"></i>
                </button>
                <Logo logo={siteLogo ? `${pathName + siteLogo.value}` : null} />

                {this.props.login ? (
                  <div className="d-none mr-auto ml-4">
                    <div className="mr-auto dropdown">
                      <button className="user_avatar">
                        <img
                          src={
                            logos && logos.length && mainLogo
                              ? mainLogo.logo
                              : UserAvatar
                          }
                          alt="صورة المستخدم"
                        />
                      </button>
                      <div className="dropdown-menu">
                        <Link to="/account" className="dropdown-item">
                          حسابى
                        </Link>
                        {user.plan ? (
                          <Link
                            to="/account/add-course"
                            className="dropdown-item"
                          >
                            إنشاء دورة جديدة
                          </Link>
                        ) : null}
                        <Link
                          className="dropdown-item"
                          to={`/account/message-history`}
                        >
                          جميع الرسائل
                        </Link>
                        <Link
                          to="/login"
                          className="dropdown-item"
                          onClick={this.props.logout}
                        >
                          تسجيل خروج
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.props.login ? (
                  <React.Fragment>
                    <div className="dropdown mr-auto">
                      <button
                        className="navbar_btn"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                    <CartContext.Consumer>
                      {({ cart }) => (
                        <div className="dropdown">
                          <button className="navbar_btn">
                            <i className="fa fa-shopping-cart"></i>
                            {cart.length ? (
                              <span className="circle_num">{cart.length}</span>
                            ) : null}
                          </button>
                          <div className="MsgMenu dropdown-menu pb-0">
                            <div className="dropdown_scroll">
                              {cart.length ? (
                                cart.map((s, i) => (
                                  <div className="dropdown-item" key={i}>
                                    <div className="SingleMsg">
                                      <div key={i}>
                                        <Link to={`/services/${s.service_id}`}>
                                          {s.service?.title}
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="py-3 text-center grey">
                                  لايوجد خدمات فى السلة
                                </div>
                              )}
                            </div>
                            <Link className="showAll_link " to="/account/cart">
                              <i className="fa fa-shopping-cart ml-2"></i>
                              اعرض سلة المشتريات
                            </Link>
                          </div>
                        </div>
                      )}
                    </CartContext.Consumer>

                    <div className="dropdown">
                      <button
                        className="navbar_btn"
                        onMouseOver={this.readMsgs}
                      >
                        <i className="fa fa-bell"></i>
                        {unread.length ? (
                          <span className="circle_num">{unread.length}</span>
                        ) : null}
                      </button>
                      <div className="MsgMenu dropdown-menu pb-0">
                        <div className="dropdown_scroll">
                          {notifys.length ? (
                            notifys.map((n, i) => (
                              <div
                                key={n.id}
                                className={
                                  `dropdown-item` +
                                  (n.read_at === null ? " bg-light" : " ")
                                }
                              >
                                <div className="SingleMsg">
                                  <img src={UserAvatar} alt="ahmed mohamed" />
                                  <div>
                                    {n.data?.type === "order_release"
                                      ? `تم تحرير مبلغ ${n.data?.amount.calculated_amount}`
                                      : null}
                                    {this.getNotifyType(n)}

                                    {n.data?.item?.created_at_human ? (
                                      <div className="msg_caption">
                                        <i className="fa fa-clock-o ml-2"></i>
                                        {n.data.item.created_at_human}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="py-3 text-center grey">
                              لايوجد اشعارات حتى الأن
                            </div>
                          )}
                        </div>
                        <Link
                          className="showAll_link "
                          to="/account/notifications"
                        >
                          <i className="fa fa-bell ml-2"></i>
                          جميع الاشعارات
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button className="navbar_btn">
                        <i className="fa fa-envelope"></i>
                      </button>
                      <div className="MsgMenu dropdown-menu pb-0">
                        <div className="dropdown_scroll">
                          {msgs.in?.map((e, i) => this.printMsg(e))}
                          {msgs.out?.map((e, i) => this.printMsg(e))}
                          {!msgs.in?.length && !msgs.out?.length ? (
                            <div className="py-3 text-center grey">
                              لايوجد رسائل حتى الأن
                            </div>
                          ) : null}
                        </div>
                        <Link className="showAll_link" to="/account/msgs">
                          <i className="fa fa-envelope ml-2"></i>
                          جميع الرسائل
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown  d-inline-block">
                      <button className="user_avatar">
                        <img
                          src={
                            logos && logos.length && mainLogo
                              ? mainLogo.logo
                              : UserAvatar
                          }
                          alt="صورة المستخدم"
                        />
                      </button>
                      <div className="dropdown-menu">
                        <Link
                          to="/account/my-profile"
                          className="dropdown-item"
                        >
                          <i className="fa fa-user ml-2 grey"></i>
                          {user.name}
                        </Link>

                        <Link className="dropdown-item" to={`/account`}>
                          <i className="fa fa-edit ml-2 grey"></i>
                          ركن التدريب
                        </Link>
                        <Link
                          className="dropdown-item"
                          to={`/account/balance-details`}
                        >
                          <i className="fa fa-money ml-2 grey"></i>
                          الرصيد
                        </Link>
                        <Link
                          className="dropdown-item"
                          to={`/account/message-history`}
                        >
                          <i className="fa fa-envelope ml-2 grey"></i>
                          ارشيف الرسائل
                        </Link>
                        <Link className="dropdown-item" to={`/account/edit`}>
                          <i className="fa fa-edit ml-2 grey"></i>
                          تعديل الحساب
                        </Link>
                        <Link
                          to="/login"
                          className="dropdown-item"
                          onClick={this.props.logout}
                        >
                          <i className="fa fa-sign-out ml-2 grey"></i>
                          تسجيل خروج
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </nav>

            <NavigationItems
              options={this.context}
              logo={this.props.logo}
              isLogged={this.props.login}
              logout={this.props.logout}
            />
            <div
              className="modal fade"
              id="searchModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      بحث عن خدمة
                    </h5>
                    <button
                      type="button"
                      className="close mr-auto ml-0"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form className="mb-3">
                      <input
                        onChange={this.handleChange}
                        className={styles.FormControl}
                        type="text"
                        name="search"
                        placeholder="اكتب كلمة البحث هنا ..."
                      />
                    </form>

                    {results.data?.length
                      ? results?.data.map((r, i) => (
                          <div className="mb-2">
                            <Link to={`/services/${r.id}`}>{r.title}</Link>
                          </div>
                        ))
                      : "لايوجد نتائج"}
                  </div>
                  <div className="modal-footer">
                    <button data-dismiss="modal" className="btn btn-secondary">
                      اغلاق
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="d-none"
              id="openNotify"
              data-toggle="modal"
              data-target="#notifyModal"
            ></button>
            <NotifyPopup service={newNotify} />
          </React.Fragment>
        )}
      </UserConsumer>
    );
  }
}

export default Navbar;
