import React, { Component } from "react";
import "./PricingBlock.css";
import classes from "../../UI/UI.module.css";
import { WOW } from "wowjs";
import UserContext from "../../../Context/User-context";
import { OptionsConsumer } from "../../../Context/Options-context";
import { Link } from "react-router-dom";
class PricingPanel extends Component {
  static contextType = UserContext;
  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });

    wow.init();
  }
  getCommission = (options) => {
    if (options.length) {
      const commisionObj = options.find(
        (option) => option.key === "commission_percent"
      );
      return commisionObj.value;
    }
  };

  render() {
    // check user_plan
    const { plan_id } = this.context;
    const {
      title,
      price,
      id,
      cert_count,
      data,
      y_certs,
      y_price,
      isAnnual,
      plan_label,
    } = this.props.plan;
    const featured_array = [];
    for (const i in data) {
      featured_array.push(data[i]);
    }
    return (
      <OptionsConsumer>
        {(options) => (
          <div
            className="col-lg-3 col-md-6 mb-lg-0 mb-4 wow fadeInUp"
            data-wow-delay={this.props.delay}
          >
            <div className="pricing_panel">
              <h3>{title}</h3>
              <div className="panel_price">
                <span>{isAnnual ? y_price | 0 : price}</span>
                <sup>س.ر</sup>
                {isAnnual ? "/ سنة" : "/شهر"}
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  onChange={(e) =>
                    this.props.setAnnualActive(e, this.props.index)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  سنوية
                </label>
              </div>
              <div className="all_features">
                <div className="d-flex single_feature">
                  <span className="pl-2">عدد الشهادات</span>
                  <span className="mr-auto feat_key ">
                    {isAnnual ? y_certs | 0 : cert_count}
                  </span>
                </div>
                {featured_array.map((e, i) => {
                  return (
                    <div className="d-flex single_feature" key={i}>
                      <span className="pl-2">{e.title}</span>
                      <span className="mr-auto feat_key">
                        {e.icon === true ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-times"></i>
                        )}
                      </span>
                    </div>
                  );
                })}
                {this.props.paidOne ? (
                  <div className="d-flex single_feature" key={"swe"}>
                    <span className="pl-2">نسبة المنصة</span>
                    <span className="mr-auto feat_key">
                      {this.getCommission(options)} %
                    </span>
                  </div>
                ) : null}
              </div>

              <div className="text-center mt-auto">
                {plan_id === id ? (
                  <span className={classes.Grey}>انت مشترك فى هذه الخطة</span>
                ) : typeof plan_id === "undefined" ? (
                  <Link
                    to="/register"
                    className={classes.Primary_btn + " link"}
                  >
                    اشتراك الان
                  </Link>
                ) : (
                  <button
                    onClick={this.props.createOrder}
                    className={classes.Primary_btn}
                  >
                    اشتراك الان
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </OptionsConsumer>
    );
  }
}
export default PricingPanel;
