import React, { Component } from "react";
import axios from "../../axios-api";
import Loader from "../UI/Loader/Loader";
import classes from "../UI/UI.module.css";
import { WOW } from "wowjs";
import "../Contact/contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toastr } from "../../Helper/Helper";
import OptionsContext from "../../Context/Options-context";

const captchaRef = React.createRef();

class Contact extends Component {
  static contextType = OptionsContext;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      content: "",
      isLoading: false,
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  }
  onChange = (e) => {
    this.setState({ recaptchaKey: e });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    const { email, name, content, recaptchaKey } = this.state;
    const data = { email, name, content, "g-recaptcha-response": recaptchaKey };

    e.preventDefault();
    axios
      .post("/contact", data)
      .then((res) => {
        this.setState({ isLoading: false });
        toastr("success", "تم ارسال رسالتك بنجاح");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "حدث خطاً ما اثناء الارسال");
      });
  };

  render() {
    const { isLoading, errors } = this.state;
    const options = this.context;
    const contactImg = options.find((o) => o.key === "contact_img");
    const pathName = `https://acplatform.net/storage/`;

    if (this.props.loggedInStatus) {
    }
    return (
      <div
        className="contactWrapper"
        style={{
          backgroundImage: contactImg
            ? `url(${pathName + contactImg.value})`
            : null,
        }}
      >
        {isLoading === true ? <Loader /> : null}
        <div className="container-fluid container-lg">
          <div className="row align-items-center">
            <div className="col-md-6 pt-5 mr-auto wow fadeInUp">
              <div className="register_wrapper">
                <h2 className="bold_font mb-4 h4 mt-4">اتصل بنا</h2>
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="userName"
                    >
                      الاسم
                    </label>
                    <div className="position-relative">
                      <i className={classes.Input_icon + " fa fa-key"}></i>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        className={classes.FormControl}
                        id="userName"
                        onChange={this.handleChange}
                      />
                    </div>

                    {errors != null ? (
                      <p className="text-danger small mb-0">
                        {errors.password}
                      </p>
                    ) : null}
                  </div>
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="userName"
                    >
                      البريد الإلكترونى
                    </label>
                    <div className="position-relative">
                      <i className={classes.Input_icon + " fa fa-user"}></i>
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        className={classes.FormControl}
                        id="userName"
                        onChange={this.handleChange}
                      />
                    </div>
                    {errors != null ? (
                      <p className="text-danger small mb-0">{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-4">
                    <label
                      className={[classes.Grey, classes.Small_font].join(" ")}
                      htmlFor="subject"
                    >
                      الموضوع
                    </label>
                    <div className="position-relative">
                      <textarea
                        className={classes.FormControl}
                        name="content"
                        id="subject"
                        onChange={this.handleChange}
                        rows="4"
                        value={this.state.content}
                      ></textarea>
                    </div>
                    {errors != null ? (
                      <p className="text-danger small mb-0">{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <ReCAPTCHA
                      ref={captchaRef}
                      onExpired={this.showExpire}
                      onChange={this.onChange}
                      sitekey="6Le0Z8UZAAAAAO93EV9IJtZlUNrwBOtW12nxTOrB"
                    />
                  </div>
                  <button
                    type="submit"
                    className={classes.Primary_btn + " mb-3"}
                  >
                    ارسال
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
