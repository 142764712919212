import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { UserConsumer } from "../../../Context/User-context";
import logo from "../../../assets/imgs/logo.png";

export default class SingleTicket extends Component {
  static contextType = UserConsumer;
  state = {
    ticket: {},
    tickets: [],
    files: [],
    msg: "",
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  componentDidMount() {
    this.setState({ isLoading: true });

    const {
      match: { params },
    } = this.props;
    axios
      .get(`/tickets/${params.ticketId}`)
      .then((res) => this.setState({ ticket: res.data, isLoading: false }))
      .catch((err) => this.setState({ isLoading: false }));
  }

  handleSubmit = (e) => {
    const { tickets, ticket, msg } = this.state;

    this.setState({ isLoading: true });
    e.preventDefault();

    axios
      .post(`/tickets/${ticket.id}/replay`, { desc: msg })
      .then((res) => {
        tickets.items = res.data;
        this.setState({ isLoading: false, msg: "" });
        this.forceUpdate();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  localeStatus = (status) => {
    switch (status) {
      case "new":
        return "جديد";
      case "support_undone":
        return "تحت المراجعة";
      case "closed":
        return "مغلقة";
      default:
    }
  };
  setImg = (type) => {
    const { logos } = this.context;
    const mainLogo = logos.find((l) => l.main === 1);
    if (type === "admin") {
      return logo;
    } else if (type === "me") {
      return mainLogo.logo;
    }
  };
  render() {
    const { isLoading, ticket } = this.state;
    return (
      <React.Fragment>
        {isLoading ? <Loader /> : null}
        {/* service head  */}
        <div className="service_head">
          <div className="container-lg container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <img
                    className="rounded-circle ml-2"
                    src={logo}
                    width="64"
                    alt="ahmed"
                  />
                  <div>
                    <h3 className="h6 medium_font">{ticket.title}</h3>
                    <div>
                      <span className="ml-3 grey">
                        <i className="fa fa-clock-o ml-2"></i>
                        منذ 3 ايام
                      </span>
                      <span className="ml-3 grey">
                        <i className="fa fa-ticket ml-2"></i>
                        {this.localeStatus(ticket.status)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* single chat components  */}
        <div className="container-lg container-fluid">
          <div className="bg-white mt-4 mb-4">
            {ticket.items?.length ? (
              ticket.items?.map((m, i) => (
                <div className="px-3 py-4 border-bottom" key={i}>
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-circle ml-2"
                      src={this.setImg(
                        ticket.user_id === m.user.id ? "admin" : "me"
                      )}
                      width="64"
                      alt="الدعم"
                    />
                    <div>
                      <div>
                        <span className="ml-3 grey">
                          <i className="fa fa-clock-o ml-2"></i>
                          {m.created_at}
                        </span>
                      </div>
                    </div>
                  </div>

                  <article className="mt-2">{m.desc}</article>
                </div>
              ))
            ) : (
              <h3 className="medium_font grey mt-4 h5 text-center">
                لايوجد اى رسائل حتى الأن
              </h3>
            )}
            <form className="p-3 mt-3" onSubmit={this.handleSubmit}>
              <textarea
                name="msg"
                onChange={this.handleChange}
                className={classes.FormControl + " mb-3"}
                rows="4"
              ></textarea>
              <div></div>

              <button className={classes.Primary_btn}>ارسل الرسالة</button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
