import React, { Component } from "react";
import classes from "../../components/UI/UI.module.css";
import Loader from "../UI/Loader/Loader";
import axios from "../../axios-api";
import "./course.css";
import { toastr } from "../../Helper/Helper";
import FrontLogin from "./FrontLogin";
import Questions from "./Questions";
import { fabric } from "fabric";
import NotFound from "./NotFound";
import printJS from "print-js";

let cn = null;

export default class FrontTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: {},
      student: {},
      user: {},
      codeDone: false,
      isStarted: false,
      isLogged: true,
      isLoading: false,
      certDone: false,
      image: null,
      errors: null,
      message: null,
    };
  }
  componentWillUnmount() {
    document.querySelector(".navbar-expand-lg").classList.remove("d-none");
  }
  componentDidMount() {
    document.querySelector(".navbar-expand-lg").classList.add("d-none");
    this.fetchStudnet();
  }
  fetchStudnet = () => {
    const token = localStorage.getItem("studentToken");
    if (token) {
      axios
        .get("/student", {
          headers: {
            token,
          },
        })
        .then((res) => {
          this.fetchCourse();
        })
        .catch((err) => {
          this.fetchCourse();
          if (err.response.status === 403) {
            this.setState({ isLogged: false });
          }
        });
    } else {
      this.fetchCourse();
    }
  };
  fetchCourse = () => {
    cn = new fabric.Canvas("c");
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    const token = localStorage.getItem("studentToken");

    axios
      .get(`/exam/${params.testId}`, {
        headers: {
          token,
        },
      })
      .then((res) => {
        if (res.data.message) {
          this.setState({ message: res.data.message });
        }
        if (res.data.questions) {
          this.setState({ codeDone: true });
        }
        const fields = JSON.parse(res.data.student_additional_payload);
        const newFields = fields?.map((e) => {
          let obj = { key: e, value: "" };
          return obj;
        });
        this.setState({
          course: res.data,
          success_message: res.data.success_message,
          fail_message: res.data.fail_message,
          image: res.data.image,
          user: res.data.user,
          userForm: newFields,
          isLoading: false,
        });
        if (res.data.cert_payload !== null) {
          cn.loadFromJSON(res.data.cert_payload);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false, notFound: true });
      });
  };
  handleAnswerChange = (id, answer) => {
    const { questions } = this.state.course;
    if (typeof answer == "boolean") {
      questions[id].boolean_answer = answer;
    } else {
      questions[id].answers.map((a, i) => {
        a.is_true = i === answer ? 1 : 0;
        return a;
      });
    }
    this.setState((prev) => ({ course: { ...prev.course, questions } }));
  };
  endExam = () => {
    this.setState({ isLoading: true });
    const {
      match: { params },
    } = this.props;
    const token = localStorage.getItem("studentToken");
    axios
      .post(`/exam/${params.testId}/end`, null, {
        headers: {
          token,
        },
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  handleSend = () => {
    this.setState({ isLoading: true });
    const token = localStorage.getItem("studentToken");
    const id = this.state.course.id;
    const { questions } = this.state.course;

    const filterQuestions = questions.map((q) => {
      if (Number(q.is_boolean)) {
        const { id, boolean_answer } = q;
        const answerObj = { question: id, answer: boolean_answer ? 1 : 0 };
        return (q = answerObj);
      } else {
        const id = q.id;
        const answer = q.answers.find((q) => q.is_true);
        if (typeof answer === "undefined") {
          toastr("error", "يجب عليك ان تجب على كل الاسئلة");
        } else {
          const answerObj = { question: id, answer: answer.id };
          return (q = answerObj);
        }
      }
    });

    axios
      .post(
        `/exam/${id}/examDetailsStore`,
        { details: filterQuestions },
        {
          headers: {
            token,
          },
        }
      )
      .then((res) => {
        this.setState({ passed: true, isLoading: false, student: res.data });
        this.endExam();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  timeOut = () => {
    this.setState({ isLoading: true });
    const { questions } = this.state.course;
    const token = localStorage.getItem("studentToken");
    const id = this.state.course.id;

    const filterQuestions = questions.map((q) => {
      if (Number(q.is_boolean)) {
        const { id, boolean_answer } = q;
        const answerObj = {
          question: id,
          answer: boolean_answer === true ? 1 : 0 || null,
        };
        return (q = answerObj);
      } else {
        const id = q.id;
        const answer = q.answers.find((q) => q.is_true);
        const answerObj = { question: id, answer: answer ? answer.id : null };
        return (q = answerObj);
      }
    });
    axios
      .post(
        `/exam/${id}/examDetailsStore`,
        { details: filterQuestions },
        {
          headers: {
            token,
          },
        }
      )
      .then((res) => {
        this.setState({ passed: true, isLoading: false, student: res.data });
        this.endExam();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  certUpload = (id, student) => {
    const token = localStorage.getItem("studentToken");
    const { cert_payload } = this.state.course;
    if (typeof cert_payload === "string") {
      cn.getObjects()[0].text = student.name;
      cn.renderAll();
      this.setState({ isLoading: true });
      const image = cn.toDataURL({
        format: "jpeg",
        multiplier: 3,
        quality: 1,
      });
      axios
        .post(
          `/exam/${id}/sendCert`,
          { image },
          {
            headers: {
              token,
            },
          }
        )
        .then((res) => {
          this.setState({ isLoading: false });
          this.fetchCourse();
          toastr("success", "تم منح الشهادة بنجاح");
          this.setState({ certDone: true });
        })
        .catch((err) => this.setState({ isLoading: false }));
    }
  };
  handleLogout = () => {
    localStorage.clear("studnetToken");
    this.setState({ codeDone: false });
  };
  exportResult = () => {
    const {
      student: { exam_answers, student, degree },
    } = this.state;

    const allowed = ["degree", "question", "answer"];
    const answers = exam_answers.map((e) => {
      const filtered = Object.keys(e)
        .filter((key) => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = e[key];
          return obj;
        }, {});
      return filtered;
    });

    printJS({
      printable: answers,
      header: `الاسم :${student.name} رقم الهاتف : ${student.phone} الدرجة :${degree}`,
      gridHeaderStyle: "color: blue;  border: 2px solid #2c05c8;",
      gridStyle: "border: 2px solid #3971A5;",
      properties: ["question", "answer", "degree"],
      type: "json",
    });
  };
  render() {
    const {
      match: { params },
    } = this.props;
    const {
      isLoading,
      codeDone,
      passed,
      course,
      userForm,
      user,
      success_message,
      fail_message,
      isLogged,
      certDone,
      isStarted,
      student,
      notFound,
      image,
      message,
    } = this.state;

    return (
      <React.Fragment>
        {isLoading === true ? <Loader /> : null}

        {notFound ? (
          <NotFound msg={message} test={true} />
        ) : (
          <div className="d-flex flex-column vh-100 position-relative">
            {codeDone ? (
              <Questions
                handleSend={this.handleSend}
                timeOut={this.timeOut}
                handleEnd={this.endExam}
                course={course}
                student={student}
                messages={{ success: success_message, fail: fail_message }}
                isStarted={isStarted}
                questions={this.state.course.questions}
                isPassed={passed}
                trainer={user ? user : null}
                bg={image}
                logOut={this.handleLogout}
                answerHandler={(id, answer) =>
                  this.handleAnswerChange(id, answer)
                }
              />
            ) : (
              <FrontLogin
                logged={isLogged}
                handleLogin={(boolean) => this.setState({ isLogged: boolean })}
                id={params.testId}
                course={course}
                form={userForm}
                headToTest={this.fetchCourse}
              />
            )}

            <div className="d-none" style={{ bottom: 0 }}>
              <canvas
                id="c"
                className="w-100"
                width="800"
                height="600"
              ></canvas>
            </div>
            {student &&
            Number(student.is_pass) &&
            !certDone &&
            course.cert_payload ? (
              <div className="container-lg container-fluid">
                <div className="col-md-6  mx-auto p-0">
                  <div className="row mt-3 align-items-center">
                    <div className="col-md-6  mb-3 text-md-right text-center">
                      <div className={" small_font"}>
                        يمكنك استلام شهادتك على بريدك الإلكترونى
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 text-md-left text-center">
                      <button
                        onClick={() =>
                          this.certUpload(course.id, student.student)
                        }
                        className={classes.Primary_btn}
                      >
                        تصدير الشهادة
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {Object.keys(student).length ? (
              <div className="container-lg container-fluid">
                <div className="col-md-6 p-0 mx-auto">
                  <div className="row">
                    <div className="col-6 mt-3">تصدير النتيجة</div>
                    <div className="col-6 mt-3 text-left">
                      <button
                        onClick={this.exportResult}
                        className={classes.Transparent_btn}
                      >
                        <i className="fa fa-download ml-2"></i>
                        تصدير
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="text-center mt-auto bg-white py-2">
              <a
                href={window.location.origin}
                rel="noopener"
                className={classes.Primary}
              >
                نماذج المدرب العربي
              </a>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
