import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ServiceCard from "../MarketHome/ServiceCard";
import { Link } from "react-router-dom";
import { UserConsumer } from "../../../Context/User-context";
import logo from "../../../assets/imgs/logo.png";
import { getMainImg } from "../../../Helper/Helper";

export default class UserProfile extends Component {
  render() {
    return (
      <UserConsumer>
        {(user) => (
          <Tabs>
            <div className="service_head pb-0">
              <div className="container-lg container-fluid">
                <div className="text-center">
                  <img
                    className="rounded-circle"
                    src={getMainImg(user.logos)}
                    alt="صورة الحساب"
                    width="128"
                    height="128"
                  />
                  <h3 className="h6 medium_font mt-2 mb-3">{user.name}</h3>
                  <div className="d-flex justify-content-between flex-column flex-md-row">
                    <div className="d-flex flex-md-column flex-row">
                      <div className="ml-md-0 ml-3">
                        <i className="fa fa-map-marker ml-2"></i>
                        الدولة
                      </div>
                      <div>{user.nationality}</div>
                    </div>
                    <div className="d-flex flex-md-column flex-row">
                      <div className="ml-md-0 ml-3">
                        <i className="fa fa-id-badge ml-2"></i>
                        الرتبة
                      </div>
                      <div>بائع نشيط</div>
                    </div>
                    <div className="d-flex flex-md-column flex-row">
                      <div className="ml-md-0 ml-3">
                        <i className="fa fa-star ml-2"></i>
                        التقييم
                      </div>
                      <div>
                        {user.user_rate === null ? "لم يحتسب بعد" : null}
                      </div>
                    </div>
                    <div className="d-flex flex-md-column flex-row">
                      <div className="ml-md-0 ml-3">
                        <i className="fa fa-shopping-cart ml-2"></i>
                        عدد الطلبات
                      </div>
                      <div>{user.completed_orders_count}</div>
                    </div>

                    <div className="d-flex flex-md-column flex-row">
                      <div className="ml-md-0 ml-3">
                        <i className="fa fa-id-badge ml-2"></i>
                        اخر تواجد
                      </div>
                      <div>الأن</div>
                    </div>
                  </div>
                  <TabList className="d-flex justify-content-center mt-4 user_tabs">
                    <Tab>الدورات</Tab>
                    <Tab>الخدمات</Tab>
                  </TabList>
                </div>
              </div>
            </div>

            <div className="container-lg container-fluid mt-3">
              <TabPanel className="tab_panel">
                <div className="row">
                  {user.courses?.map((course, i) => {
                    return (
                      <div className="col-md-6 col-lg-4 mb-4" key={i}>
                        <Link to={`/course/${course.id}`}>
                          <div className="card course_card">
                            <div className="card-header d-flex">
                              <h3 className="medium_font h6">{course.title}</h3>
                            </div>
                            <div className="card-body">
                              <img
                                className={
                                  !course.image
                                    ? "transparent_img course_cover"
                                    : "course_cover"
                                }
                                src={course.image ? course.image : logo}
                                alt={"دورة"}
                              />
                            </div>

                            <div className="card-footer text-muted d-flex">
                              <span className="course_label">
                                <i className="fa fa-user ml-2"></i>
                                {course.students_count + " طالب"}
                              </span>
                              <div className="mr-auto">
                                <div className={"h5 mb-0"}>
                                  <i className="fa fa-money-bill ml-2"></i>
                                  {course.price} <sup>ر.س</sup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>

              <TabPanel className="tab_panel">
                <div className="row">
                  {user?.services.map((e, i) => (
                    <ServiceCard
                      key={i}
                      service={e}
                      column="col-lg-3 col-md-4"
                      pending={e.status === "pending" ? true : false}
                    />
                  ))}
                </div>
              </TabPanel>
            </div>
          </Tabs>
        )}
      </UserConsumer>
    );
  }
}
