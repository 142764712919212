import React, { Component } from "react";
import userAvatar from "./Male-Avatar.png";
import bg from "./trainerBg.jpg";
import "./trainer.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import styles from "../../components/UI/UI.module.css";
import axios from "../../axios-api";
import Loader from "../../components/UI/Loader/Loader";
import { copyUrl } from "../../Helper/Helper";
import { Link } from "react-router-dom";
import classes from "../../components/UI/UI.module.css";
import { OptionsConsumer } from "../../Context/Options-context";
import ServiceCard from "../Account/MarketHome/ServiceCard";
import { UserConsumer } from "../../Context/User-context";

class Trainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainer: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    document.querySelector(".navbar-expand-lg").classList.add("d-none");
    this.setState({ isLoading: true });
    const {
      params: { userId },
    } = this.props.match;

    axios
      .get(`/trainer/${userId}`)
      .then((res) => {
        this.setState({ isLoading: false, trainer: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.props.history.push("/");
      });
  }
  getLogo = (options) => {
    const pathName = `https://acplatform.net/storage/`;
    if (options.length) {
      const logoObj = options.find((o) => o.key === "site_logo");
      return `${pathName}/${logoObj.value}`;
    }
  };
  componentWillUnmount() {
    document.querySelector(".navbar-expand-lg").classList.remove("d-none");
  }

  render() {
    const path = "http://acplatform.net/storage";
    const {
      name,
      social,
      id,
      courses,
      logos,
      cover_image,
      services,
      nationality,
      title,
    } = this.state.trainer;
    const { trainer } = this.state;
    const isLoading = this.state.isLoading;

    let mainLogo = "";
    if (logos) {
      mainLogo = logos.find((o) => o.main === 1);
    }
    return (
      <OptionsConsumer>
        {(options) => (
          <React.Fragment>
            <div
              className="userTop_details"
              style={{
                backgroundImage: cover_image
                  ? `url(${path}${cover_image})`
                  : `url(${bg})`,
              }}
            >
              {isLoading ? <Loader /> : null}
              <div className="container-lg container-fluid position-relative">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="d-flex flex-md-row flex-column  align-items-center">
                      <UserConsumer>
                        {(user) =>
                          user.id ? (
                            <Link to={`/account`} className="text-white ml-3">
                              <i className="fa fa-arrow-right ml-2"></i> رجوع
                            </Link>
                          ) : null
                        }
                      </UserConsumer>
                      <img
                        className="rounded-circle user_avatar ml-md-2"
                        src={mainLogo ? mainLogo.logo : userAvatar}
                        alt={name}
                      />

                      <div className="text-center text-md-right">
                        <h3 className="h5 medium_font text-white mb-0 ">
                          {name}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-md-left text-center mt-md-0 mt-3">
                      {social ? (
                        <div className="social_links">
                          {social.facebook ? (
                            <a
                              className="facebook"
                              href={social.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-facebook-f"></i>
                            </a>
                          ) : null}
                          {social.twitter ? (
                            <a
                              className="twitter"
                              href={social.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-twitter"></i>
                            </a>
                          ) : null}
                          {social.linkedin ? (
                            <a
                              className="linkedIn"
                              href={social.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          ) : null}
                          {social.instagram ? (
                            <a
                              className="instagram"
                              href={social.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-instagram"></i>
                            </a>
                          ) : null}

                          {social.snap ? (
                            <a
                              className="snapChat"
                              href="www.whatsapp.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-snapchat-ghost"></i>
                            </a>
                          ) : null}

                          <a className="" href={`tel:1111111`}>
                            <i className="fa fa-phone"></i>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Tabs>
              <div className="service_head pb-0">
                <div className="container-lg container-fluid">
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      src={mainLogo ? mainLogo.logo : userAvatar}
                      alt="صورة الحساب"
                      width="128"
                      height="128"
                    />
                    <div className="d-flex justify-content-between flex-column flex-md-row mb-3">
                      <div className="d-flex flex-md-column flex-row">
                        <div className="ml-md-0 ml-3">
                          <i className="fa fa-map-marker ml-2"></i>
                          الدولة
                        </div>
                        <div>{nationality}</div>
                      </div>
                      <div className="d-flex flex-md-column flex-row">
                        <div className="ml-md-0 ml-3">
                          <i className="fa fa-id-badge ml-2"></i>
                          الرتبة
                        </div>
                        <div>{title?.label}</div>
                      </div>
                      <div className="d-flex flex-md-column flex-row">
                        <div className="ml-md-0 ml-3">
                          <i className="fa fa-star ml-2"></i>
                          التقييم
                        </div>
                        <div>
                          {trainer.user_rate === null
                            ? "لم يحتسب بعد"
                            : trainer.user_rate}
                        </div>
                      </div>
                      <div className="d-flex flex-md-column flex-row">
                        <div className="ml-md-0 ml-3">
                          <i className="fa fa-shopping-cart ml-2"></i>
                          عدد المبيعات
                        </div>
                        <div>{trainer.completed_orders_count}</div>
                      </div>

                      <div className="d-flex flex-md-column flex-row">
                        <div className="ml-md-0 ml-3">
                          <i className="fa fa-id-badge ml-2"></i>
                          اخر تواجد
                        </div>
                        <div>الأن</div>
                      </div>
                    </div>
                    {social ? <p>{social.bio}</p> : null}
                    <TabList className="d-flex justify-content-center mb-3 user_tabs">
                      <Tab>الدورات</Tab>
                      <Tab>الخدمات</Tab>
                    </TabList>
                  </div>
                </div>
              </div>
              <div className="container-lg container-fluid ">
                <TabPanel className="tab_panel">
                  <div className="row">
                    {courses?.map((course, i) => {
                      return (
                        <div className="col-md-6 col-lg-4 mb-4" key={i}>
                          <Link to={`/course/${course.id}`}>
                            <div className="card course_card">
                              <div className="card-header d-flex">
                                <h3 className="medium_font h6">
                                  {course.title}
                                </h3>
                              </div>
                              <div className="card-body">
                                <img
                                  className={
                                    !course.image
                                      ? "transparent_img course_cover"
                                      : "course_cover"
                                  }
                                  src={
                                    course.image
                                      ? course.image
                                      : this.getLogo(options)
                                  }
                                  alt={course.title}
                                />
                              </div>

                              <div className="card-footer text-muted d-flex">
                                <span className="course_label">
                                  <i className="fa fa-user ml-2"></i>
                                  {course.students_count + " طالب"}
                                </span>
                                <div className="mr-auto">
                                  {course.price ? (
                                    <div
                                      className={classes.Primary + " h5 mb-0"}
                                    >
                                      <i className="fa fa-money-bill ml-2"></i>
                                      {course.price} <sup>ر.س</sup>
                                    </div>
                                  ) : (
                                    <span className="text-success medium_font">
                                      مجانية
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>

                <TabPanel className="tab_panel">
                  <div className="row">
                    {services?.map((e, i) => {
                      return e.status !== "pending" ? (
                        <ServiceCard
                          key={i}
                          service={e}
                          column="col-lg-3 col-md-4"
                        />
                      ) : null;
                    })}
                  </div>
                </TabPanel>
                <div className="text-center mt-3 mb-3">
                  <button
                    className={styles.Primary_btn + " pt-1 pb-2"}
                    onClick={() => copyUrl("trainer", id)}
                  >
                    <i className="fa fa-copy ml-2"></i>
                    مشاركة الصفحة
                  </button>
                </div>
              </div>
            </Tabs>
          </React.Fragment>
        )}
      </OptionsConsumer>
    );
  }
}

export default Trainer;
