import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";

export default class AllNotifications extends Component {
  state = {
    notices: [],
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get(`/notifications`)
      .then((res) => this.setState({ isLoading: false, notices: res.data }))
      .catch(() => this.setState({ isLoading: false }));
  }
  userImg = (imgs) => {
    if (imgs && imgs.length) {
      const img = imgs.find((img) => img.main === 1);
      return (
        <img
          className="img_small border ml-3 rounded-circle"
          src={img.logo}
          alt="adnan halbya"
        />
      );
    } else {
      return (
        <span
          className="rounded-circle img_small text-center grey border d-inline-block ml-3 h5"
          style={{ lineHeight: "43px" }}
        >
          <i className="fa fa-bell"></i>
        </span>
      );
    }
  };
  render() {
    const { isLoading, notices } = this.state;
    return (
      <div className="container-lg container-fluid mt-3">
        <div className="col-md-6 mx-auto p-0">
          {isLoading ? <Loader /> : null}
          <h1 className="h5 bold_font">جميع الاشعارات</h1>

          {/* all notifications  */}
          <div className="bg-white p-4">
            {notices.length ? (
              notices.map((e, i) => (
                <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                  {this.userImg(e.data?.item?.seller.logos)}
                  <div>
                    <h3 className="h6">
                      {e.data?.message?.message}
                      {e.data?.type === "order_release"
                        ? `تم تحرير المبلغ ${e.data?.amount?.calculated_amount} ر.س`
                        : null}
                    </h3>
                    <div className="grey small_font">
                      <span className="ml-3">
                        <i className="fa fa-user ml-2"></i>
                        {e.data?.item?.seller?.name}
                      </span>
                      <span className="ml-3">
                        <i className="fa fa-edit ml-2"></i>
                        {e.data?.item?.service?.title}
                      </span>
                      <span className="ml-3">
                        <i className="fa fa-clock ml-2"></i>
                        {e.created_at_human}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="py-5">
                <React.Fragment>
                  <h1 className="text-center grey mb-3">
                    <i className="fa fa-exclamation"></i>
                  </h1>
                  <h3 className="medium_font text-center grey h5">
                    لايوجد اشعارات حتى الأن
                  </h3>
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
