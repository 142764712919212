import React, { Component } from 'react';
import loginImg from './austin-distel-wD1LRb9OeEo-unsplash.jpg';
import axios from '../../axios-api';
import Loader from '../UI/Loader/Loader';
import { Link } from 'react-router-dom';
import classes from '../UI/UI.module.css';
import '../Contact/contact.css';
class ForgetPw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      urlPw: '',
      password: '',
      password_confirmation: '',
      submitted: false,
      hasCreds: false,
      isLoading: false,
      errors: null,
    };
  }
  componentDidMount() {
    const url = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('token');
    if (myParam) {
      this.setState({ urlPw: url, hasCreds: true });
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    const access_token = localStorage.getItem('token');
    this.setState({ isLoading: true });
    const email = this.state.email;

    axios
      .post(
        'https://acplatform.net/api/auth/password/email',
        { email },
        {
          headers: {
            authorization: access_token,
          },
        },
      )
      .then((res) => {
        console.log(res);
        this.setState({ isLoading: false, submitted: true });
      })
      .catch((err) => {
        console.log(err.response.data.errors);
        const errors = err.response.data.errors;
        this.setState({ isLoading: false, errors: errors });
      });

    event.preventDefault();
  };
  handleSubmitPw = (e) => {
    this.setState({ isLoading: true });
    const { urlPw, password, password_confirmation } = this.state;
    e.preventDefault();
    axios
      .post(urlPw, { password, password_confirmation })
      .then((res) => {
        this.props.history.push('/login');
      })
      .catch((err) => this.setState({ errors: err.response.data.errors, isLoading: false }));
  };
  render() {
    const { isLoading, errors, submitted, hasCreds } = this.state;
    if (this.props.loggedInStatus) {
    }
    return (
      <div className="contactWrapper" style={{ backgroundImage: `url(${loginImg})` }}>
        {isLoading === true ? <Loader /> : null}

        <div className="container-fluid container-lg">
          {hasCreds === true ? (
            <div className="row align-items-center wow fadeInUp">
              <div className="col-md-6 mr-auto">
                <div className="home_wrapper">
                  <h2 className="bold_font mb-4 h4">تغيير كلمة السر</h2>
                  <form onSubmit={this.handleSubmitPw}>
                    <div className="form-group mb-4">
                      <label className={[classes.Grey, classes.Small_font].join(' ')} htmlFor="pw">
                        كلمة السر الجديدة
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + ' fa fa-key'}></i>
                        <input
                          type="password"
                          name="password"
                          value={this.state.password}
                          className={classes.FormControl}
                          id="pw"
                          onChange={this.handleChange}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.password}</p>
                      ) : null}
                    </div>
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(' ')}
                        htmlFor="pw_confirmation"
                      >
                        تأكيد كلمة السر
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + ' fa fa-key'}></i>
                        <input
                          type="password"
                          name="password_confirmation"
                          value={this.state.password_confirmation}
                          className={classes.FormControl}
                          id="pw_confirmation"
                          onChange={this.handleChange}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.password_confirmation}</p>
                      ) : null}
                    </div>

                    <button type="submit" className={classes.Primary_btn}>
                      تغيير كلمة السر
                    </button>
                  </form>
                  <div className="text-center mt-4">
                    او
                    <Link
                      to="/login"
                      className={[classes.Primary, classes.Medium_font].join(' ')}
                    >
                      {' '}
                      سجل دخول
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row align-items-center">
              <div className="col-md-6 mr-auto">
                <div className="home_wrapper">
                  <h2 className="bold_font mb-4 h4">استعادة كلمة السر</h2>
                  {submitted ? (
                    <div className="alert alert-warning">
                      تم ارسال رسالة تأكيد عبر البريد الإلكترونى
                    </div>
                  ) : null}
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(' ')}
                        htmlFor="userName"
                      >
                        البريد الإلكترونى
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + ' fa fa-envelope'}></i>
                        <input
                          type="text"
                          name="email"
                          value={this.state.email}
                          className={classes.FormControl}
                          id="userName"
                          onChange={this.handleChange}
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.email}</p>
                      ) : null}
                    </div>

                    <button type="submit" className={classes.Primary_btn}>
                      استعادة كلمة السر
                    </button>
                  </form>
                  <div className="text-center mt-4">
                    او
                    <Link
                      to="/login"
                      className={[classes.Primary, classes.Medium_font].join(' ')}
                    >
                      {' '}
                      سجل دخول
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ForgetPw;
