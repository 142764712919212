import React, { Component } from "react";
import classes from "../../components/UI/UI.module.css";
import Loader from "../UI/Loader/Loader";
import { paypalParams, toastr } from "../../Helper/Helper";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";
import axios from "../../axios-api";
import "./course.css";
import closedIcon from "./closed.svg";

export default class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      start_date: "",
      end_date: "",
      first_name: "",
      last_name: "",
      email: "",
      country: "sa",
      payment_method: null,
      paypalStatus: null,
      nationality: [],
      phone: "",
      errors: null,
      isLoading: false,
    };
  }
  componentWillUnmount() {
    document.querySelector(".navbar-expand-lg").classList.remove("d-none");
  }
  componentDidMount() {
    document.querySelector(".navbar-expand-lg").classList.add("d-none");

    const {
      location: { search },
    } = this.props;

    // check if paypal payment successful
    const urlParams = new URLSearchParams(search);
    const query = paypalParams(urlParams);
    const { payment, token, payerId, canceled } = query;

    if (payment && token && payerId) {
      this.fetchCourse({ payment, token, payerId });
      this.setState({ paypalStatus: "success" });
    } else if (typeof canceled === "string") {
      this.fetchCourse("canceled");
      this.setState({ paypalStatus: "danger" });
    } else {
      this.fetchCourse();
    }

    // set loading first
    this.setState({ isLoading: true });
  }
  fetchCourse = (payload) => {
    const {
      match: { params },
    } = this.props;

    const query =
      payload && payload.payment
        ? `?paymentId=${payload.payment}&token=${payload.token}&PayerID=${payload.payerId}`
        : payload === "canceled"
        ? "?canceled"
        : null;

    axios
      .get(`/course/${params.courseId}${payload ? query : ""}`)
      .then((res) => {
        if (res === undefined) {
          this.setState({ closed: true });
        }
        const {
          title,
          content,
          start_date,
          end_date,
          image,
          price,
          price_status,
          cert_price_status,
          cert_price,
        } = res.data;
        this.setState({
          isLoading: false,
          title,
          content,
          image,
          fields: res.data.form ? res.data.form.form_data : null,
          start_date,
          end_date,
          price,
          price_status,
          cert_price_status,
          cert_price,
        });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          const {
            title,
            content,
            start_date,
            end_date,
            image,
            price,
            price_status,
          } = err.response?.data;
          this.setState({
            title,
            content,
            image,
            start_date,
            end_date,
            price,
            price_status,
            closed: true,
            isLoading: false,
          });
        }
        this.setState({ isLoading: false });
      });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleAnswerChange = (i, e) => {
    const { value } = e.target;
    const { fields } = this.state;

    if (fields[i].multiple) {
      if (e.target.checked) {
        fields[i].choosedAnswers.push(value);
        console.log(fields[i]);
      } else {
        const index = fields[i].choosedAnswers.indexOf(value);
        fields[i].choosedAnswers.splice(index, 1);
      }
    } else {
      fields[i].choosedAnswers = value;
    }
    this.setState({ fields });
  };
  choosePayType = (type) => {
    this.setState({ payment_method: type });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;

    const {
      match: { params },
    } = this.props;
    const {
      first_name,
      phone,
      email,
      fields,
      price_status,
      payment_method,
      cert_price_status,
    } = this.state;

    if (fields) {
      fields.forEach((field) => {
        if (!field.type === "image" && field.choosedAnswers.length === 0) {
          valid = false;
        }
      });
    }
    if (!valid) {
      toastr("error", "يوجد اسئلة غير مجاب عليها");
    } else {
      this.setState({ isLoading: true });
      const data = {
        first_name,
        phone,
        email,
        nationality: fields,
        course_id: params.courseId,
        payment_method,
        isLoading: false,
      };

      axios
        .post(
          `/course/${params.courseId}/${
            price_status || cert_price_status ? "storeAndPay" : "store"
          }`,
          data
        )
        .then((res) => {
          if (res.data.invoice_url) {
            window.open(res.data.invoice_url, "_blank");
            this.setState({ payment_method: null });
          }
          toastr(
            "success",
            price_status || cert_price_status
              ? "جارى تحويلك الى موقع الدفع الأن"
              : "تم الاشتراك فى الدورة بنجاح"
          );
          this.setState({ isLoading: false });
        })
        .catch((err) => {
          toastr("warning", "هناك خطاً ما فى البيانات , حاول مرة اخرى");
          this.setState({
            errors: err.response?.data?.errors,
            isLoading: false,
          });
        });
    }
  };
  render() {
    const siteUrl = "https://acplatform.net";
    const {
      first_name,
      email,
      errors,
      fields,
      phone,
      country,
      isLoading,
      title,
      content,
      start_date,
      price_status,
      image,
      end_date,
      price,
      closed,
      cert_price_status,
      cert_price,
      paypalStatus,
    } = this.state;
    return (
      <div className="container-lg container-fluid">
        {isLoading === true ? <Loader /> : null}

        <div className="bg-white rounded mt-4 p-4 shadow-sm">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-8 border-left order-md-1 order-2 ">
                {typeof paypalStatus === "string" ? (
                  <div
                    className={`alert alert-${paypalStatus} alert-dismissible fade show`}
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    {paypalStatus === "success"
                      ? "تم الدفع بنجاح"
                      : "فشلت عملية الدفع"}
                  </div>
                ) : null}
                {closed ? (
                  <div className="text-center">
                    <img className="closedIcon" src={closedIcon} alt="مغلقة" />
                    <h3 className={classes.Grey + " medium_font mt-2"}>
                      عفواً , هذه الدورة غير متاحة او تم حذفها
                    </h3>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="h6 medium_font">البيانات الاساسية</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="studnet_name"
                        >
                          الاسم بالكامل
                        </label>
                        <div className="position-relative">
                          <i className={classes.Input_icon + " fa fa-user"}></i>
                          <input
                            type="text"
                            className={classes.FormControl}
                            name="first_name"
                            id="studnet_name"
                            onChange={this.handleChange}
                            value={first_name}
                            required
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.first_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="startDate"
                        >
                          رقم الجوال
                        </label>
                        <div className="position-relative" dir="ltr">
                          <PhoneInput
                            country={country}
                            inputClass={classes.FormControl}
                            enableSearch={true}
                            localization={ar}
                            value={phone}
                            onChange={(phone, country) =>
                              this.setState({ phone, country: country.name })
                            }
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.phone}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-4">
                        <label
                          className={[classes.Grey, classes.Small_font].join(
                            " "
                          )}
                          htmlFor="startDate"
                        >
                          البريد الإلكترونى
                        </label>
                        <div className="position-relative">
                          <i
                            className={classes.Input_icon + " fa fa-envelope"}
                          ></i>
                          <input
                            onChange={this.handleChange}
                            name="email"
                            type="email"
                            value={email}
                            className={classes.FormControl}
                            required
                          />
                        </div>
                        {errors != null ? (
                          <p className="text-danger small mb-0">
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    {fields && Array.isArray(fields)
                      ? fields.map((f, fieldIndex) => {
                          return f.type === "text" ? (
                            <div className="col-md-12" key={fieldIndex}>
                              <p className="bg-light text-center py-2 px-3 rounded">
                                {f.value}
                              </p>
                            </div>
                          ) : f.type === "image" ? (
                            <div
                              className="col-md-12 mb-3 text-center"
                              key={fieldIndex}
                            >
                              <img
                                className="rounded"
                                src={`${siteUrl}/storage/${f.image}`}
                                alt={`question${fieldIndex}`}
                              />
                            </div>
                          ) : (
                            <div className="col-md-6" key={fieldIndex}>
                              <div className="form-group mb-4">
                                <label
                                  className={[
                                    classes.Grey,
                                    classes.Small_font,
                                  ].join(" ")}
                                  htmlFor={"field" + fieldIndex}
                                >
                                  {f.value}
                                </label>
                                {f.type === "select" ? (
                                  <div className="position-relative">
                                    <div
                                      className="dropdown answers-dropdown"
                                      id={"field" + fieldIndex}
                                    >
                                      <button
                                        className="dropdown-toggle d-block text-right border w-100 py-2 px-3"
                                        data-toggle="dropdown"
                                      >
                                        {f.choosedAnswers.length
                                          ? f.choosedAnswers.toString()
                                          : "اختر الاجابة"}
                                      </button>
                                      <div className="dropdown-menu">
                                        {f.answers
                                          ? f.answers.map((answer, i) => {
                                              return (
                                                <div
                                                  className="dropdown-item checkboxes-group"
                                                  key={i}
                                                >
                                                  <label
                                                    htmlFor={`${fieldIndex}-answer-${i}`}
                                                    className={
                                                      classes.CustomCheckbox +
                                                      " w-100"
                                                    }
                                                  >
                                                    <input
                                                      type={
                                                        f.multiple
                                                          ? "checkbox"
                                                          : "radio"
                                                      }
                                                      value={answer}
                                                      onChange={this.handleAnswerChange.bind(
                                                        this,
                                                        fieldIndex
                                                      )}
                                                      id={`${fieldIndex}-answer-${i}`}
                                                      name={`answers-${fieldIndex}[]`}
                                                    />
                                                    <span className="ml-2"></span>
                                                    {answer}
                                                  </label>
                                                </div>
                                              );
                                            })
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="position-relative">
                                    <input
                                      type="text"
                                      required={f.required}
                                      className={classes.FormControl}
                                      id={"field" + fieldIndex}
                                      onChange={this.handleAnswerChange.bind(
                                        this,
                                        fieldIndex
                                      )}
                                      value={f.choosedAnswer}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null}
                    <div className="col-md-12">
                      {(!closed && price_status) || cert_price_status ? (
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              type="submit"
                              onClick={() => this.choosePayType("visa")}
                              className={
                                classes.Primary_btn +
                                " btn-block d-md-none d-block mb-3"
                              }
                            >
                              <i className="fa fa-cc-visa ml-2"></i>
                              دفع اونلاين
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              type="submit"
                              onClick={() => this.choosePayType("paypal")}
                              className={[
                                classes.Primary_btn,
                                classes.Paypal_btn,
                                "d-md-none d-block btn-block",
                              ].join(" ")}
                            >
                              <i className="fa fa-paypal ml-2"></i>
                              الدفع باى بال
                            </button>
                          </div>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className={
                            classes.Primary_btn + " btn-block d-md-none d-block"
                          }
                        >
                          التسجيل فى الدورة
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4 order-1 order-md-2 order-1 mb-md-0 mb-4">
                <aside className="course_aside">
                  {image ? <img src={image} alt="صورة الدورة" /> : null}
                  <h1 className="h4 bold_font mb-3">{title}</h1>
                  <p
                    className={classes.Grey}
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></p>
                  <div className="mb-3">
                    <i className={classes.Grey + " fa fa-money ml-2 fa-fw"}></i>
                    سعر الدورة :
                    {price_status ? (
                      <div className="d-inline-block">
                        {price}
                        <sup>ر.س</sup>
                      </div>
                    ) : (
                      <div className="text-success medium_font d-inline-block">
                        مجانى
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <i className={classes.Grey + " fa fa-money ml-2 fa-fw"}></i>
                    سعر الشهادة :
                    {cert_price_status ? (
                      <div className="d-inline-block">
                        {cert_price}
                        <sup>ر.س</sup>
                      </div>
                    ) : (
                      <div className="text-success medium_font d-inline-block">
                        مجانى
                      </div>
                    )}
                  </div>
                  {start_date ? (
                    <p>
                      <i
                        className={classes.Grey + " fa fa-calendar ml-2 fa-fw"}
                      ></i>
                      تبدء الدورة فى :{start_date}
                    </p>
                  ) : null}
                  {end_date ? (
                    <p>
                      <i
                        className={
                          classes.Grey + " fa fa-calendar-times-o ml-2 fa-fw"
                        }
                      ></i>
                      تنتهى الدورة فى :{end_date}
                    </p>
                  ) : null}
                  {(!closed && price_status) || cert_price_status ? (
                    <div className="row gx-2 d-none d-md-flex">
                      <div className="col-md-6">
                        <button
                          type="submit"
                          onClick={() => this.choosePayType("visa")}
                          className={classes.Primary_btn + " btn-block mb-3"}
                        >
                          <i className="fa fa-cc-visa ml-2"></i>
                          دفع اونلاين
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="submit"
                          onClick={() => this.choosePayType("paypal")}
                          className={[
                            classes.Primary_btn,
                            classes.Paypal_btn,
                            "btn-block",
                          ].join(" ")}
                        >
                          <i className="fa fa-paypal ml-2"></i>
                          الدفع باى بال
                        </button>
                      </div>
                    </div>
                  ) : !closed ? (
                    <button
                      type="submit"
                      className={
                        classes.Primary_btn +
                        " btn-block mb-3 d-none d-md-block"
                      }
                    >
                      التسجيل فى الدورة
                    </button>
                  ) : null}
                </aside>
              </div>
            </div>
          </form>
        </div>

        <div className="text-center mt-3 mb-3">
          <div className="grey">
            لم يتم إنشاء هذا المحتوى ولا اعتماده من قِبل{" "}
            <a
              href={window.location.origin}
              rel="noopener"
              className={classes.Primary}
            >
              نماذج منصة المدرب العربي
            </a>
          </div>
        </div>
      </div>
    );
  }
}
