import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import logo from "../../../assets/imgs/logo.png";
export default class MarketHome extends Component {
  state = {
    catParents: [],
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/services/category/parents")
      .then((res) => {
        this.setState({ catParents: res.data, isLoading: false });
      })
      .catch((err) => this.setState({ isLoading: false }));
  }

  render() {
    const { catParents, isLoading } = this.state;
    return (
      <React.Fragment>
        {isLoading ? <Loader /> : null}
        <div className="container-lg container-fluid mt-4">
          <h3 className="h5 medium_font text-center mb-4">
            الخدمات المساندة للمدرب العربي
          </h3>
          {/* main categories */}
          <div className="row d-md-flex d-none">
            {catParents.map((cat, i) => (
              <div className="col-md-3" key={i}>
                <div className="product_cat mb-4">
                  <Link
                    to={`/category/${cat.id}`}
                    className="d-block position-relative"
                  >
                    <img
                      src={cat.full_path ? cat.full_path : logo}
                      alt="category-1"
                    />
                    <h3 className="h5 medium_font">{cat.title}</h3>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div
            id="carouselCategory"
            className="carousel slide d-block d-md-none"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              {catParents.map((cat, i) => (
                <div
                  key={i}
                  className={"carousel-item" + (i === 0 ? " active" : " ")}
                  data-interval="3000"
                >
                  <div className="product_cat mb-4">
                    <Link
                      to={`/category/${cat.id}`}
                      className="d-block position-relative"
                    >
                      <img src={cat.full_path} alt={`category${i + 1}`} />
                      <h3 className="h5 medium_font">{cat.title}</h3>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselCategory"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselCategory"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>

          {/* different categories */}
          {catParents.map((s, i) => (
            <React.Fragment key={i}>
              <div className="d-flex mb-4 align-items-center">
                <h3 className="h5 medium_font mb-0">
                  <Link className="text-dark" to={`/category/${s.id}`}>
                    {s.title}
                  </Link>
                </h3>
                <Link
                  to={`/category/${s.id}`}
                  className={classes.Transparent_btn + " mr-auto"}
                >
                  عرض الجميع
                </Link>
              </div>

              {s.servicess?.data?.length ? (
                <div className="row ">
                  {s.servicess.data?.map((e, i) =>
                    i <= 3 ? (
                      <ServiceCard column="col-lg-3 col-md-4" service={e} />
                    ) : null
                  )}
                </div>
              ) : (
                <div className="h5 text-center grey medium_font mb-4">
                  لايوجد خدمات فى هذا القسم حتى الأن
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
