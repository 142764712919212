import React, { Component } from "react";
import classes from "../UI/UI.module.css";
import { Link } from "react-router-dom";
import imgError from "./error.svg";
import "./noMatch.css";
class NoMatch extends Component {
  render() {
    const {
      match: { params },
    } = this.props;
    if (params[0] === "/account") this.props.history.push("/login");
    return (
      <div className="container-lg container-fluid">
        <div className="col-md-6 mx-auto p-0">
          <div className="bg-white p-4 rounded mt-4 text-center">
            <img className="imgError mb-4" src={imgError} alt="صورة الخطاً" />
            <h3 className={classes.Grey + " h4 medium_font mb-4"}>
              عذراً , يبدو انك وصلت لصفحة الخطأ
            </h3>
            <Link to="" className={classes.Primary_btn}>
              العودة للرئيسية
              <i className="fa fa-arrow-left mr-2"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default NoMatch;
