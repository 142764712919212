import React from "react";
import { Link } from "react-router-dom";
import { copyUrl } from "../../../Helper/Helper";

export default function actionBtns(props) {
  return (
    <React.Fragment>
      <Link
        data-tip="تعديل"
        className="btn btn-outline-dark ml-2"
        to={props.editPath}
      >
        <i className="fa fa-edit"></i>
      </Link>
      <Link
        data-tip="تعيين شهادة"
        className="btn btn-outline-dark ml-2"
        to={props.certPath}
      >
        <i className="fa fa-photo"></i>
      </Link>
      <button
        data-tip="نسخ الرابط"
        onClick={(id) => copyUrl(`quiz`, props.id)}
        className="btn btn-outline-dark ml-2"
      >
        <i className="fa fa-copy"></i>
      </button>
      <button
        data-tip="حذف"
        onClick={(e, id) => props.handleDelete(e, id)}
        data-type="error"
        className="btn btn-outline-danger"
      >
        <i className="fa fa-trash"></i>
      </button>
    </React.Fragment>
  );
}
