import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios-api";
import { getMainImg } from "../../../Helper/Helper";
import Loader from "../../UI/Loader/Loader";
import classes from "../../UI/UI.module.css";

export default class Purchases extends Component {
  state = {
    orders: [],
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get(`/services/order/messages`)
      .then((res) => this.setState({ orders: res.data, isLoading: false }))
      .catch((err) => this.setState({ isLoading: false }));
  }
  render() {
    const { orders, isLoading } = this.state;
    return (
      <div className="container-lg container-fluid mt-3">
        {isLoading ? <Loader /> : null}
        {/* all notifications  */}
        <div className="row">
          {/* <div className="col-md-3">
            <ul className="side_cat">
              <li>
                <div className="d-flex align-items-center mb-3">
                  <Link className="cat_link  text-dark" to="/">
                    بانتظار التعليمات
                  </Link>
                  <span class="badge rounded-pill bg-dark mr-auto">30</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Link className="cat_link text-dark" to="/">
                    جارى تنفيذها
                  </Link>
                  <span class="badge rounded-pill bg-dark mr-auto">30</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Link className="cat_link text-dark" to="/">
                    بانتظار الاستلام
                  </Link>
                  <span class="badge rounded-pill bg-dark mr-auto">30</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Link className="cat_link text-dark" to="/">
                    تم تسليمها
                  </Link>
                  <span class="badge rounded-pill bg-dark mr-auto">30</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <Link className="cat_link text-dark" to="/">
                    ملغية
                  </Link>
                  <span class="badge rounded-pill bg-dark mr-auto">30</span>
                </div>
              </li>
            </ul>
          </div> */}

          <div className="col-md-9 mx-auto">
            <div className="d-flex align-items-center mb-2">
              <h1 className="h5 bold_font">المشتريات</h1>
            </div>

            <div className="bg-white p-4">
              {orders.in?.map((e, i) => (
                <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                  <Link
                    to={
                      e.status === "pending"
                        ? `/account/serv-orders/${e.id}`
                        : {
                            pathname: `/account/chat/${e?.message?.id}`,
                            search: "?type=orderMsg",
                          }
                    }
                  >
                    <img
                      className="img_small border ml-3 rounded-circle"
                      src={getMainImg(e.user.logos)}
                      alt={e.user.name}
                    />
                  </Link>
                  <div>
                    <h3 className="h6">
                      <Link
                        className="primary"
                        to={
                          e.status === "pending"
                            ? `/account/serv-orders/${e.id}`
                            : {
                                pathname: `/account/chat/${e?.message?.id}`,
                                search: "?type=orderMsg",
                              }
                        }
                      >
                        {e.title}
                      </Link>
                    </h3>
                    <div className="grey small_font">
                      <Link to={`/trainer/${e.user.id}`} className="ml-3">
                        <i className="fa fa-user ml-2"></i>
                        {e.user.name}
                      </Link>
                      <span className="ml-3">
                        <i className="fa fa-info-circle ml-2"></i>
                        {e.status_label}
                      </span>
                      <span className="ml-3">
                        <i className="fa fa-clock-o ml-2"></i>
                        {e.created_humans}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {orders.out?.map((e, i) => (
                <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                  <Link
                    to={
                      e.status === "pending"
                        ? `/account/serv-orders/${e.id}`
                        : {
                            pathname: `/account/chat/${e?.message?.id}`,
                            search: "?type=orderMsg",
                          }
                    }
                  >
                    <img
                      className="img_small border ml-3 rounded-circle"
                      src={getMainImg(e.user.logos)}
                      alt={e.user.name}
                    />
                  </Link>
                  <div>
                    <h3 className="h6">
                      <Link
                        className="primary"
                        to={
                          e.status === "pending"
                            ? `/account/serv-orders/${e.id}`
                            : {
                                pathname: `/account/chat/${e?.message?.id}`,
                                search: "?type=orderMsg",
                              }
                        }
                      >
                        {e.title}
                      </Link>
                    </h3>
                    <div className="grey small_font">
                      <Link to={`/trainer/${e.user.id}`} className="ml-3">
                        <i className="fa fa-user ml-2"></i>
                        {e.user.name}
                      </Link>
                      <span className="ml-3">
                        <i className="fa fa-info-circle ml-2"></i>
                        {e.status_label}
                      </span>
                      <span className="ml-3">
                        <i className="fa fa-clock-o ml-2"></i>
                        {e.created_humans}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {!orders.out?.length && !orders.in?.length ? (
                <div className="text-center py-4">
                  <h3 className="medium_font  text-center grey">
                    لم تقم بشراء خدمة حتي الأن
                  </h3>
                  <p className="grey">شاهد خدمات المنصة</p>

                  <Link className={classes.Primary_btn} to="/marketplace">
                    خدمات البائعين
                  </Link>
                </div>
              ) : null}
              {/* <div className="text-center">
                <button className={classes.Transparent_btn}>عرض المزيد</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
