import axios from "axios";
import React, { Component } from "react";

class PaypalPurchases extends Component {
  state = {
    paymentDone: null,
  };
  componentDidMount() {
    const {
      location: { search },
      match: { params },
    } = this.props;
    // check if paypal payment successful
    const urlParams = new URLSearchParams(search);
    const paymentId = urlParams.get("paymentId");
    const token = urlParams.get("token");
    const payerId = urlParams.get("PayerID");
    console.log({ paymentId, token, payerId });
    this.setState({
      paymentDone: paymentId ? true : false,
      token,
      payerId,
      paymentId,
    });

    axios
      .get(
        `/account/order/${params.orderId}?paymentId=${payerId}?PayerId=${payerId}?token=${token}`
      )
      .then((res) => console.log(res))
      .catch((err) => this.setState({ isLoading: false }));
  }

  render() {
    const { paymentDone } = this.state;
    return (
      <div className="container-lg container-fluid">
        <div className="col-md-6 p-0 mx-auto shadow-sm mt-4">
          <div
            className={`${
              paymentDone ? "text-success" : "text-danger"
            } bg-white py-5 px-4 text-center`}
          >
            <div className="h1">
              <i
                className={`fa ${
                  paymentDone ? "fa-check-circle" : "fa-times-circle"
                }`}
              ></i>
            </div>
            <h3 className="medium_font h5">
              {paymentDone ? "تم الدفع بنجاح" : "فشل عملية الدفع"}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
export default PaypalPurchases;
