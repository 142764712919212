import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { columns } from "./TableColumns";
import { tableStyle } from "../../UI/DataTable/TableStyle";
import MoneyIcon from "./assets/salary.svg";
import courseIcon from "./assets/graduated.svg";
import "./Balance.css";
import { Link } from "react-router-dom";
import classes from "../../UI/UI.module.css";
import axios from "../../../axios-api";
import { UserConsumer } from "../../../Context/User-context";
import Loader from "../../UI/Loader/Loader";

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      orders: [],
      transictions: [],
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/account/withdraw")
      .then((res) => {
        const orders = res.data.map((order) => {
          order.status = this.translateStatus(order.status);
          return order;
        });
        this.setState({ isLoading: false, orders });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });

    this.getTransacations();
  }
  getTransacations = () => {
    axios
      .get("/transactions")
      .then((res) =>
        this.setState({ isLoading: false, transictions: res.data })
      );
  };
  translateStatus = (status) => {
    switch (status) {
      case "completed":
        return "مكتمل";
      case "canceled":
        return "ملغى";
      case "pending":
        return "تحت المراجعة";
      case "in_progress":
        return "جارى تحويل المبلغ";
      default:
        return;
    }
  };
  render() {
    const { transactions, holdBalance } = this.state.transictions;
    return (
      <UserConsumer>
        {(user) => (
          <div className="container-lg container-fluid">
            {this.state.isLoading ? <Loader /> : null}
            <div className="bg-white p-4 rounded mt-4 shadow-sm">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="stats_block text-center">
                    <div>
                      <h3 className="medium_font h5">الرصيد الكلى</h3>
                    </div>
                    <span className="stat_number d-block">
                      {user.sell_online_balance}
                    </span>
                    <div className="grey small_font mt-2">
                      كامل الرصيد الموجود في حسابك الآن يتضمن الأرباح والرصيد
                      المعلّق أيضاً.
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats_block text-center">
                    <div>
                      <h3 className="medium_font h5">الرصيد المعلق</h3>
                    </div>
                    <span className="stat_number d-block">{holdBalance}</span>
                    <div className="grey small_font mt-2">
                      يتم تعليق الأرباح التي حققتها لمدة 14 يوم قبل أن تتمكن من
                      استخدامها.
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="stats_block text-center">
                    <div>
                      <h3 className="medium_font h5">رصيد قابل للسحب</h3>
                    </div>
                    <span className="stat_number d-block">
                      {user.sell_online_balance - holdBalance}
                    </span>
                    <div className="grey small_font mt-2">
                      المبلغ الذي حققته من بيع الخدمات والدورات ويمكن سحبه الى
                      حسابك البنكى
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <Link
                  to={`/account/balance-withdraw`}
                  className={classes.Primary_btn}
                >
                  سحب الرصيد
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="bg-white p-4 rounded shadow-sm mt-4">
                  {this.state.orders.length ? (
                    <React.Fragment>
                      <h1 className="medium_font h4 text-center mb-3">
                        طلبات الخطط
                      </h1>
                      <DataTable
                        noHeader={true}
                        columns={columns}
                        customStyles={tableStyle}
                        data={this.state.orders}
                      />
                    </React.Fragment>
                  ) : (
                    <div className="text-center py-4">
                      <h3 className="medium_font  text-center grey">
                        لم تقم باختيار خطة حتى الأن
                      </h3>
                      <p className="grey">اختر من الخطط الموجودة فى المنصة</p>

                      <Link className={classes.Primary_btn} to="/pricing-plans">
                        خطط المنصة
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="bg-white p-4 rounded shadow-sm mt-4">
                  <h1 className="medium_font h4 text-center mb-3">
                    المعاملات المالية
                  </h1>
                  <div className="table-responsive courses_table">
                    <table className="table">
                      <tbody>
                        <thead>
                          <tr className="medium_font">
                            <th className="border-bottom">#</th>
                            <th className="border-bottom">المبلغ</th>
                            <th className="border-bottom">نسبة المنصة</th>
                            <th className="border-bottom">الإجمالى</th>
                          </tr>
                        </thead>

                        <tbody>
                          {transactions?.map((t, i) => (
                            <tr key={i}>
                              <td>{t.id}</td>
                              <td
                                className={
                                  t.type ? "text-success" : "text-danger"
                                }
                              >
                                {t.type ? "+" : "-"}
                                {t.calculated_amount}
                              </td>
                              <td>{t.commession}</td>
                              <td>{t.total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default Balance;
