import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { toastr } from "../../../Helper/Helper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/ar";
import { Link } from "react-router-dom";

export default class Messaging extends Component {
  state = {
    courseName: "",
    title: "",
    content: "",
    files: [],
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    const {
      params: { courseId },
    } = this.props.match;
    axios.get(`/account/course/${courseId}`).then((res) => {
      this.setState({
        courseName: res.data.title,
        id: res.data.id,
        isLoading: false,
      });
    });
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleChangeStatus = ({ meta, file }, status) => {
    const { files } = this.state;
    if (status === "done") {
      this.setState((prev) => ({ files: [...prev.files, file] }));
    } else if (status === "removed") {
      const newFiles = files.filter((f) => f.name !== file.name);
      this.setState({ files: newFiles });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const id = this.state.id;
    const { title, content, files } = this.state;
    // formdata
    const data = new FormData();
    data.append("title", title);
    data.append("content", content);

    const sendingMsg = () => {
      axios
        .post(`/account/course/${id}/emails`, data)
        .then((res) => {
          this.setState({ isLoading: false });
          toastr("success", "تم إرسال الرسالة بنجاح");
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          toastr("warning", err.response.data.message);
        });
    };

    if (files.length) {
      files.forEach((f, i) => data.append(`file[${i}]`, f));
      this.setState({ isLoading: true });
      sendingMsg();
    } else {
      if (window.confirm("ستقوم بإرسال الرسالة بدون مرفقات , هل انت متأكد ؟")) {
        this.setState({ isLoading: true });
        sendingMsg();
      }
    }
  };
  render() {
    const { isLoading, courseName, title, content, id } = this.state;
    return (
      <div className="container-lg container-fluid">
        {isLoading ? <Loader /> : null}
        <div className="col-lg-6 col-md-8 mx-auto p-0">
          <h1 className="h5 bold_font mt-4 mb-3">
            <Link
              className={classes.Primary + " ml-2"}
              to={`/account/courses/${id}`}
            >
              <i className="fa fa-arrow-right"></i>
            </Link>
            إرسال بريد إلكترونى للطلاب فى {courseName}
          </h1>
          <div className="bg-white p-4 shadow-sm rounded">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="msgTitle"
                >
                  عنوان الرسالة
                </label>
                <div className="position-relative">
                  <i className={classes.Input_icon + " fa fa-book"}></i>
                  <input
                    type="text"
                    className={classes.FormControl}
                    name="title"
                    id="msgTitle"
                    onChange={this.handleChange}
                    value={title}
                    required
                  />
                </div>
              </div>
              <div className="form-group mb-4">
                <label
                  className={[classes.Grey, classes.Small_font].join(" ")}
                  htmlFor="msgTitle"
                >
                  محتوى الرسالة
                </label>
                <div className="position-relative">
                  <CKEditor
                    editor={DecoupledEditor}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);

                      // Insert the toolbar before the editable area.
                      editor.ui
                        .getEditableElement()
                        .parentElement.insertBefore(
                          editor.ui.view.toolbar.element,
                          editor.ui.getEditableElement()
                        );

                      this.editor = editor;
                    }}
                    config={{
                      removePlugins: ["insertImage"],
                      language: "ar",
                    }}
                    data={content}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ content: data });
                    }}
                  />
                </div>
              </div>
              <Dropzone
                onChangeStatus={this.handleChangeStatus}
                inputContent={
                  <div key="1">
                    <i className="fa fa-upload ml-2"></i>
                    ملحقات الرسالة
                  </div>
                }
                accept="image/*"
              />
              <button className={classes.Primary_btn + " mt-4"}>
                إرسال الرسالة
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
