import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import avatar from "../../../assets/imgs/user_avatar.jpg";

export default class AllMsg extends Component {
  state = {
    msgs: {},
    inbox: true,
    outbox: true,
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/services/all/messages")
      .then((res) => this.setState({ isLoading: false, msgs: res.data }));
  }
  filterMsgs = (type) => {
    if (type === "out") {
      this.setState({ inbox: false, outbox: true });
    } else {
      this.setState({ inbox: true, outbox: false });
    }
    this.forceUpdate();
  };
  render() {
    const { isLoading, msgs, inbox, outbox } = this.state;
    return (
      <div className="container-lg container-fluid mt-3">
        {isLoading ? <Loader /> : null}
        <div className="d-flex align-items-center mb-3">
          <h1 className="h5 bold_font">جميع الرسائل</h1>
        </div>

        {/* all notifications  */}
        <div className="row">
          <div className="col-md-3">
            <ul className="side_cat">
              <li>
                <div className="d-flex align-items-center mb-3">
                  <button
                    className="cat_link  text-dark"
                    onClick={this.filterMsgs.bind(this, "out")}
                  >
                    الرسائل الصادرة
                  </button>
                  <span className="badge rounded-pill bg-dark mr-auto">
                    {msgs.out?.length}
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center">
                  <button
                    className="cat_link text-dark"
                    onClick={this.filterMsgs.bind(this, "in")}
                  >
                    الرسائل الواردة
                  </button>
                  <span className="badge rounded-pill bg-dark mr-auto">
                    {msgs.in?.length}
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <div className="col-md-9">
            <div className="bg-white p-4">
              {inbox &&
                msgs.in?.map((e, i) => (
                  <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                    <Link
                      to={{
                        pathname: `/account/chat/${e.id}`,
                        search: "type=serviceMsg",
                      }}
                    >
                      <img
                        className="img_small border ml-3 rounded-circle"
                        src={avatar}
                        alt={e.seller.name}
                      />
                    </Link>
                    <div>
                      <h3 className="h6">
                        <Link
                          className="primary"
                          to={{
                            pathname: `/account/chat/${e.id}`,
                            search: "type=serviceMsg",
                          }}
                        >
                          استفسار عن: {e.service.title}
                        </Link>
                      </h3>
                      <div className="grey small_font">
                        <span className="ml-3">
                          <i className="fa fa-user ml-2"></i>
                          {e.seller.name}
                        </span>
                        <span className="ml-3">
                          <i className="fa fa-clock-o ml-2"></i>
                          {e.created_at_human}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              {outbox
                ? msgs.out?.map((e, i) => (
                    <div className="border-bottom d-flex pb-2 mb-3" key={i}>
                      <Link
                        to={{
                          pathname: `/account/chat/${e.id}`,
                          search: "type=serviceMsg",
                        }}
                      >
                        <img
                          className="img_small border ml-3 rounded-circle"
                          src={avatar}
                          alt={e.seller.name}
                        />
                      </Link>
                      <div>
                        <h3 className="h6">
                          <Link
                            className="primary"
                            to={{
                              pathname: `/account/chat/${e.id}`,
                              search: "type=serviceMsg",
                            }}
                          >
                            استفسار عن: {e.service.title}
                          </Link>
                        </h3>
                        <div className="grey small_font">
                          <span className="ml-3">
                            <i className="fa fa-user ml-2"></i>
                            {e.seller.name}
                          </span>
                          <span className="ml-3">
                            <i className="fa fa-clock-o ml-2"></i>
                            {e.created_at_human}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
              {!msgs.out?.length && !msgs.in?.length ? (
                <div className="text-center py-4">
                  <h3 className="medium_font  text-center grey">
                    لايوجد رسائل حتى الان
                  </h3>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
