import React, { Component } from "react";
import classes from "../../UI/UI.module.css";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
import { toastr } from "../../../Helper/Helper";
import CartContext from "../../../Context/Cart-context";
import cartIcon from "./empty-cart.svg";

export default class Cart extends Component {
  static contextType = CartContext;
  state = {
    cart: [],
    cartTotal: {},
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/cart")
      .then((res) => {
        this.setState({
          cart: res.data.cart,
          cartTotal: res.data.cartTotal,
          isLoading: false,
        });
      })
      .catch((err) => this.setState({ isLoading: false }));
  }

  handleChange = (cartItem, index, itemId, e) => {
    this.setState({ isLoading: true });

    const { name, value } = e.target;
    const { id, qty, items } = cartItem;
    const data = { qty };
    const { cart } = this.state;

    if (this.hasAddition(index, items)) {
      const item = items.find((a) => a.service_additional_id === index);
      data.service_additionals = [{ id: item.id }];
    } else {
      data.service_additionals = [{ service_additional_id: index }];
    }

    axios
      .post(
        `/services/updateCart/${id}`,
        name === "additional" ? data : { qty: value }
      )
      .then((res) => {
        const {
          cart: { total, items },
          cartTotal,
        } = res.data;
        cart[itemId].total = total;
        cart[itemId].items = items;
        this.forceUpdate();
        this.setState({ isLoading: false, cartTotal });
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  handleDelete = (id, index) => {
    const { cart, cartTotal } = this.state;
    const { updateCart } = this.context;
    this.setState({ isLoading: true });
    axios
      .delete(`/cart/destroy/${id}`)
      .then((res) => {
        toastr("success", "تم حذف عنصر السلة بنجاح");
        cart.splice(index, 1);
        this.setState({ isLoading: false, cart });
        updateCart("delete", id);
      })
      .catch((err) => this.setState({ isLoading: false }));
  };
  orderCreate = () => {
    this.setState({ isLoading: true });
    axios
      .post("/services/order/create")
      .then((res) =>
        this.props.history.push(`/account/serv-orders/${res.data.id}`)
      )
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr("warning", "فشلت عملية إنشاء الطلب");
      });
  };
  hasAddition = (id, items) => {
    const getItems = items.find((e) => e.service_additional_id === id);
    if (getItems) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { isLoading, cart, cartTotal } = this.state;
    return (
      <div className="container-lg container-fluid mt-4">
        {isLoading ? <Loader /> : null}
        <h1 className="h5 bold_font mb-3">سلة المشتريات</h1>

        <div className="bg-white mb-4">
          {cart.length ? (
            <div className="table-responsive courses_table">
              <table className="table">
                <tbody>
                  <tr className="medium_font">
                    <th className="border-bottom">الخدمة</th>
                    <th className="border-bottom" width="16%">
                      مرات الطلب
                    </th>
                    <th className="border-bottom" width="14%">
                      المبلغ
                    </th>
                    <th className="border-bottom" width="5%"></th>
                  </tr>
                  {cart.map((e, i) => (
                    <tr key={i}>
                      <td>
                        <React.Fragment>
                          <h3 className="h6 mb-3">{e.service.title}</h3>
                          {e?.service.additions.map((a, index) => (
                            <div className="small_font" key={index}>
                              <label
                                htmlFor={`addition-${index + 1}`}
                                className={classes.CustomCheckbox}
                              >
                                <input
                                  type="checkbox"
                                  name="additional"
                                  id={`addition-${index + 1}`}
                                  onChange={this.handleChange.bind(
                                    this,
                                    e,
                                    a.id,
                                    i
                                  )}
                                  defaultChecked={this.hasAddition(
                                    a.id,
                                    e.items
                                  )}
                                />
                                <span className="ml-3"></span>
                                {a.title} + ( {a.price} ر.س)
                              </label>
                            </div>
                          ))}
                        </React.Fragment>
                      </td>
                      <td className="align-middle">
                        <select
                          className={classes.FormControl}
                          style={{ width: "90px" }}
                          onChange={this.handleChange.bind(this, e, null, i)}
                        >
                          {Array.from(Array(10).keys()).map((q, i) => (
                            <option
                              key={i}
                              value={q + 1}
                              selected={q + 1 === e.qty ? true : false}
                            >
                              {q + 1}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="align-middle">{e.total} ر.س</td>
                      <td className="align-middle medium_font">
                        <button
                          className="btn btn-outline-danger mr-2 py-1"
                          type="button"
                          onClick={this.handleDelete.bind(this, e.id, i)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td></td>
                    <td>مدة العمل</td>
                    <td>{cartTotal.days} يوم</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className="medium_font">الاجمالى</td>
                    <td className="medium_font">{cartTotal.price} ر.س</td>
                  </tr>
                </tbody>
              </table>

              <div className="text-center mt-3 mb-3">
                <button
                  onClick={this.orderCreate}
                  className={classes.Primary_btn}
                >
                  ادفع الأن
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <img src={cartIcon} alt="cart icon" width="72" />
              <h3 className="h5 medium_font grey mt-3">
                لايوجد عناصر فى السلة
              </h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}
