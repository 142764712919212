import React, { Component } from "react";
import classes from "../UI/UI.module.css";
import { WOW } from "wowjs";
import axios from "../../axios-api";
import { toastr } from "../../Helper/Helper";
import Loader from "../UI/Loader/Loader";

class FrontLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      name: "",
      code: "",
      userForm: [],
      errors: null,
      submitted: false,
    };
  }
  authCheck = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    const id = this.props.id;
    const { name, email, phone, userForm } = this.state;
    const data = { name, email, phone, student_additional_payload: userForm };
    axios
      .post(`/exam/${id}/store`, data)
      .then((res) => {
        const STUDENT_TOKEN = res.data.token;
        localStorage.setItem("studentToken", STUDENT_TOKEN);
        this.setState({ isLoading: false });
        this.props.headToTest();
      })
      .catch((err) =>
        this.setState({ errors: err.response.data.errors, isLoading: false })
      );
  };
  handleChange = (id = null, type = null, e) => {
    const { name, value } = e.target;
    const { userForm } = this.state;
    if (type) {
      userForm[id].value = value;
      this.setState({ userForm });
    } else {
      this.setState({ [name]: value });
    }
  };
  componentDidMount() {
    const wow = new WOW({
      offset: 0,
      mobile: true,
      live: true,
    });

    wow.init();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.form !== this.state.userForm) {
      this.setState({ userForm: this.props.form });
    }
  }
  handleLogin = (e) => {
    this.setState({ isLoading: true });
    const { email, phone } = this.state;
    e.preventDefault();
    axios
      .post("/s/login", { email, phone })
      .then((res) => {
        this.setState({ isLoading: false, submitted: true, errors: null });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          toastr("warning", "الحساب ليس موجود");
        } else {
          this.setState({ errors: err.response.data.errors, isLoading: false });
        }
        this.setState({ isLoading: false });
      });
  };
  handleCode = (e) => {
    this.setState({ isLoading: true });
    const { email, code } = this.state;
    e.preventDefault();
    axios
      .post("/s/verify", { code, email })
      .then((res) => {
        localStorage.setItem("studentToken", res.data.token);
        this.setState({ isLoading: false });
        this.props.headToTest();
      })
      .catch((err) => {
        toastr("warning", "الكود خطاً");
        this.setState({ isLoading: false });
      });
  };
  render() {
    const course = this.props.course;
    const { userForm } = this.state;
    const {
      email,
      errors,
      name,
      isLoading,
      code,
      phone,
      submitted,
    } = this.state;
    return (
      <div className="container-lg container-fluid mt-md-auto mb-4">
        {isLoading ? <Loader /> : null}
        <div className="col-md-6 mx-auto">
          <div className="bg-white rounded mt-4 p-4 shadow-sm">
            {submitted ? (
              <form className="wow fadeInUp" onSubmit={this.handleCode}>
                <h3 className="bold_font h5 text-center">{course.title}</h3>
                <p
                  className={[classes.Grey, "text-center medium_font"].join(
                    " "
                  )}
                >
                  ادخل الكود المرسل على بريدك الإلكترونى
                </p>

                <div className="form-group mb-4">
                  <label
                    className={[classes.Grey, classes.Small_font].join(" ")}
                    htmlFor="code"
                  >
                    كود التأكيد
                  </label>
                  <div className="position-relative">
                    <i className={classes.Input_icon + " fa fa-key"}></i>
                    <input
                      onChange={this.handleChange.bind(this, null, null)}
                      name="code"
                      type="number"
                      id="code"
                      value={code}
                      className={classes.FormControl}
                      required
                    />
                  </div>
                  {errors != null ? (
                    <p className="text-danger small mb-0">{errors.email}</p>
                  ) : null}
                </div>

                <button type="submit" className={classes.Primary_btn}>
                  إرسل الكود
                </button>

                <div className="text-center mt-3">
                  ترغب فى إنشاء حساب جديد فى الاختبار
                  <button
                    className={classes.Primary + " medium_font mr-2"}
                    onClick={() => this.props.handleLogin(true)}
                  >
                    سجل الأن
                  </button>
                </div>
              </form>
            ) : (
              <React.Fragment>
                {!this.props.logged ? (
                  <form className="wow fadeInUp" onSubmit={this.handleLogin}>
                    <h3 className="bold_font h5 text-center">{course.title}</h3>
                    <p
                      className={[classes.Grey, "text-center medium_font"].join(
                        " "
                      )}
                    >
                      تسجيل الدخول
                    </p>

                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="loginEmail"
                      >
                        البريد الإلكترونى
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-key"}></i>
                        <input
                          onChange={this.handleChange.bind(this, null, null)}
                          name="email"
                          type="email"
                          id="loginEmail"
                          value={email}
                          className={classes.FormControl}
                          required
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.email}</p>
                      ) : null}
                    </div>
                    <div className="form-group mb-4">
                      <label
                        className={[classes.Grey, classes.Small_font].join(" ")}
                        htmlFor="loginPhone"
                      >
                        رقم الجوال
                      </label>
                      <div className="position-relative">
                        <i className={classes.Input_icon + " fa fa-key"}></i>
                        <input
                          onChange={this.handleChange.bind(this, null, null)}
                          name="phone"
                          type="number"
                          value={phone}
                          id="loginPhone"
                          className={classes.FormControl}
                          required
                        />
                      </div>
                      {errors != null ? (
                        <p className="text-danger small mb-0">{errors.phone}</p>
                      ) : null}
                    </div>

                    <button type="submit" className={classes.Primary_btn}>
                      ارسل كود التأكيد
                    </button>

                    <div className="text-center mt-3">
                      ترغب فى إنشاء حساب جديد فى الاختبار
                      <button
                        className={classes.Primary + " medium_font mr-2"}
                        onClick={() => this.props.handleLogin(true)}
                      >
                        سجل الأن
                      </button>
                    </div>
                  </form>
                ) : (
                  <form className="wow fadeInUp" onSubmit={this.authCheck}>
                    <h3 className="bold_font h5 text-center">{course.title}</h3>
                    <p
                      className={[classes.Grey, "text-center medium_font"].join(
                        " "
                      )}
                    >
                      التسجيل فى الاختبار
                    </p>
                    <div className="row g-2">
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="userName"
                          >
                            الاسم
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-user"}
                            ></i>
                            <input
                              onChange={this.handleChange.bind(
                                this,
                                null,
                                null
                              )}
                              name="name"
                              type="text"
                              id="userName"
                              value={name}
                              className={classes.FormControl}
                              required
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="phone"
                          >
                            رقم الجوال
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-phone"}
                            ></i>
                            <input
                              onChange={this.handleChange.bind(
                                this,
                                null,
                                null
                              )}
                              name="phone"
                              type="number"
                              id="phone"
                              value={phone}
                              className={classes.FormControl}
                              required
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.phone}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label
                            className={[classes.Grey, classes.Small_font].join(
                              " "
                            )}
                            htmlFor="email"
                          >
                            البريد الإلكترونى
                          </label>
                          <div className="position-relative">
                            <i
                              className={classes.Input_icon + " fa fa-envelope"}
                            ></i>
                            <input
                              onChange={this.handleChange.bind(
                                this,
                                null,
                                null
                              )}
                              name="email"
                              type="email"
                              id="email"
                              value={email}
                              className={classes.FormControl}
                              required
                            />
                          </div>
                          {errors != null ? (
                            <p className="text-danger small mb-0">
                              {errors.email}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {userForm?.map((f, i) => {
                        return (
                          <div className="col-md-6" key={i}>
                            <div className="form-group mb-4">
                              <label
                                className={[
                                  classes.Grey,
                                  classes.Small_font,
                                ].join(" ")}
                                htmlFor={`field-${i}`}
                              >
                                {f.key}
                              </label>
                              <div className="position-relative">
                                <i
                                  className={
                                    classes.Input_icon + " fa fa-address-card"
                                  }
                                ></i>
                                <input
                                  onChange={this.handleChange.bind(
                                    this,
                                    i,
                                    "external"
                                  )}
                                  type="text"
                                  value={f.value}
                                  className={classes.FormControl}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button type="submit" className={classes.Primary_btn}>
                      التسجيل فى الاختبار
                    </button>

                    <div className="text-center mt-3">
                      لديك حساب بالفعل ,
                      <button
                        className={classes.Primary + " medium_font"}
                        onClick={() => this.props.handleLogin(false)}
                      >
                        سجل دخول الأن
                      </button>
                    </div>
                  </form>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FrontLogin;
