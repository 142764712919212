import React, { Component } from "react";
import { Route } from "react-router-dom";
import Loader from "../UI/Loader/Loader";
import UserDetails from "./UserDetails/UserDetails";
import AddCourse from "./AddCourse/AddCourse";
import EditAccount from "./EditAccount/EditAccount";
import SingleCourse from "./SingleCourse/SingleCourse";
import Orders from "./Orders/Orders";
import AddCert from "./AddCert/AddCert";
import SubmitOrder from "../SubmitOrder/SubmitOrder";
import EditCourse from "./EditCourse/EditCourse";
import Balance from "./BalanceDetails/Balance";
import BalanceWithdraw from "./BalanceWithdraw/BalanceWithdraw";
import Messaging from "./Messaging/Messaging";
import MsgHistory from "./MsgHistory/MsgHistory";
import Tests from "./Test/Tests";
import QuizCert from "./Test/Cert";
import AddTest from "./Test/AddTest";
import EditTest from "./Test/EditTest";
import Students from "./Test/Students";
import SingleService from "./SingleService/SingleService";
import Cart from "./Cart/Cart";
import Checkout from "./Cart/Checkout";
import AddService from "./AddService/AddService";
import EditService from "./AddService/EditService";
import AllNotifications from "./AllNotifications/AllNotifications";
import AllMsg from "./AllMsg/AllMsg";
import Purchases from "./Purchases/Purchases";
import SingleChat from "./SingleChat/SingleChat";
import UserProfile from "./UserProfile/UserProfile";
import Tickets from "./Tickets/Tickets";
import SingleTicket from "./Tickets/SingleTicket";
import PaypalPurchases from "./Purchases/PaypalPurchases";

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    if (!this.props.loggedInStatus) {
      this.props.history.push("/login");
    }
    if (this.state.trainerData !== {}) {
      return (
        <React.Fragment>
          {this.state.isLoading === true ? <Loader /> : null}
          <Route
            exact
            path="/account"
            render={(props) => (
              <UserDetails
                {...props}
                user={this.props.userData}
                updateCourses={this.props.updateCourse}
              />
            )}
          />

          <Route
            render={(props) => <SingleService {...props} />}
            path="/account/service/:serviceId"
          />
          <Route
            path="/account/message-history"
            render={(props) => <MsgHistory {...props} />}
          />
          <Route
            path={"/account/add-course"}
            render={(props) => (
              <AddCourse {...props} updateCourses={this.props.updateCourse} />
            )}
          />
          <Route
            path={"/account/my-orders"}
            render={(props) => <Orders {...props} />}
          />
          <Route
            path={"/account/serv-orders/:orderId"}
            render={(props) => <Checkout {...props} />}
          />

          <Route
            path={`/account/balance-details`}
            render={(props) => <Balance {...props} />}
          />

          <Route
            path={`/account/balance-withdraw`}
            render={(props) => <BalanceWithdraw {...props} />}
          />

          <Route
            path={"/account/add-Certifcate/:cert_id"}
            render={(props) => (
              <AddCert
                {...props}
                updateMedia={this.props.updateMedia}
                userId={this.props.userData.id}
                logos={this.props.userData.logos}
              />
            )}
          />
          <Route
            path={"/account/edit"}
            render={(props) => (
              <EditAccount
                {...props}
                updateLogos={this.props.updateLogos}
                logos={this.props.userData.logos}
                id={this.props.userData.id}
              />
            )}
          />
          <Route
            path="/account/orders/:orderId"
            render={(props) => (
              <SubmitOrder
                {...props}
                activeOrder={this.props.userData.orders}
              />
            )}
          />
          <Route
            path={`/account/courses/:courseId`}
            render={(props) => (
              <SingleCourse
                {...props}
                fetchCourse={this.props.fetchCourse}
                course={this.props.userData.courses}
                plan={this.props.userData.plan}
              />
            )}
          />
          <Route
            path={`/account/edit-course/:courseId`}
            render={(props) => <EditCourse {...props} />}
          />
          <Route
            path="/account/messaging/:courseId"
            render={(props) => <Messaging {...props} />}
          />
          <Route
            path="/account/tests"
            render={(props) => (
              <Tests
                {...props}
                logos={this.props.userData.logos}
                updateMedia={this.props.updateMedia}
                userId={this.props.userData.id}
              />
            )}
          />
          <Route
            path="/account/exam/:testId"
            render={(props) => <Students {...props} />}
          />
          <Route
            path="/account/test-cert/:testId"
            render={(props) => (
              <QuizCert
                {...props}
                updateMedia={this.props.updateMedia}
                userId={this.props.userData.id}
                logos={this.props.userData.logos}
              />
            )}
          />
          <Route
            path={"/account/add-test"}
            render={(props) => (
              <AddTest {...props} updateCourses={this.props.updateCourse} />
            )}
          />
          <Route
            path={"/account/test/:testId"}
            render={(props) => (
              <EditTest {...props} updateCourses={this.props.updateCourse} />
            )}
          />
          <Route path="/account/cart" render={(props) => <Cart {...props} />} />
          <Route
            path="/account/add-service"
            render={(props) => <AddService {...props} />}
          />
          <Route
            path="/account/edit-service/:serviceId"
            render={(props) => <EditService {...props} />}
          />
          <Route
            render={(props) => <AllNotifications {...props} />}
            path="/account/notifications"
          />
          <Route
            render={(props) => <AllMsg {...props} />}
            path="/account/msgs"
          />

          <Route
            render={(props) => <SingleChat {...props} />}
            path="/account/chat/:chatId"
          />

          <Route
            render={(props) => <UserProfile {...props} />}
            path="/account/my-profile"
          />
          <Route
            render={(props) => <Purchases {...props} />}
            path="/account/purchases"
          />

          <Route
            render={(props) => <Tickets {...props} />}
            path="/account/tickets"
          />
          <Route
            render={(props) => <SingleTicket {...props} />}
            path="/account/ticket/:ticketId"
          />
        </React.Fragment>
      );
    }
  }
}
