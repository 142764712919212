import React, { Component } from "react";
import axios from "../../../axios-api";
import classes from "../../UI/UI.module.css";
import ReactTooltip from "react-tooltip";
import { toastr } from "../../../Helper/Helper";
import Loader from "../../UI/Loader/Loader";
import DataTable from "react-data-table-component";
import { tableStyle } from "../../UI/DataTable/TableStyle";
import { Link } from "react-router-dom";
import ActionBtns from "../../UI/ActionBtns/ActionBtns";

const columns = [
  {
    name: "#",
    selector: "id",
    sortable: true,
  },
  {
    name: "اسم الاختبار",
    selector: "title",
    sortable: true,
  },
  {
    name: "عدد الطلاب",
    selector: "students_count",
    sortable: true,
  },
  {
    name: "شهادة",
    selector: "isCert",
    sortable: true,
  },
  {
    name: "خيارات",
    selector: "options",
    sortable: true,
  },
];

class Tests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tests: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios
      .get("/account/exams")
      .then((res) => {
        const tests = res.data.map((test, i) => {
          const { id, title, students, cert_payload } = test;
          const obj = {
            id,
            title: (
              <Link
                to={{
                  pathname: `/account/exam/${id}`,
                  students: test.students,
                }}
                className={classes.Primary + " medium_font"}
              >
                {title}
              </Link>
            ),
            students_count: students.length,
            isCert: (
              <span
                className={`badge rounded-pill  ${
                  cert_payload ? "bg-success pt-0" : "bg-danger"
                }`}
              >
                {cert_payload ? "يوجد شهادة" : "لايوجد شهادة"}
              </span>
            ),
            options: (
              <ActionBtns
                id={id}
                handleDelete={() => this.testDelete(id)}
                editPath={`/account/test/${id}`}
                certPath={`/account/test-cert/${id}`}
              />
            ),
          };
          return obj;
        });
        this.setState({ tests, isLoading: false });
        this.forceUpdate();
      })
      .catch((err) => {
        toastr("warning", "لم يتم العثور على نتائج");
        this.setState({ isLoading: false });
      });
  }
  testDelete = (id) => {
    if (window.confirm("هل انت متأكد من حذف الاختبار")) {
      this.setState({ isLoading: true });
      axios
        .delete(`account/exams/${id}`)
        .then(() => {
          const oldTests = this.state.tests;
          const tests = oldTests.filter((t) => t.id !== id);
          toastr("success", "تم الحذف بنجاح");
          this.setState({ tests, isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
          toastr("warning", "حدث خطأ ما , حاول مرة اخرى");
        });
    }
  };
  render() {
    const { isLoading, tests } = this.state;
    // adding
    return (
      <div className="container-lg container-fluid">
        <div className="row mt-3 mb-3 align-items-center">
          <div className="col-6">
            <h1 className="h5 medium_font mb-0">جميع الاختبارات</h1>
          </div>
          <div className="col-6 text-left">
            <Link to="/account/add-test" className={classes.Primary_btn}>
              <i className="fa fa-plus ml-2"></i>
              اختبار جديد
            </Link>
          </div>
        </div>
        <div className="bg-white shadow-sm rounded p-4">
          {isLoading ? <Loader /> : null}

          {tests.length ? (
            <React.Fragment>
              <DataTable
                noHeader={true}
                columns={columns}
                customStyles={tableStyle}
                data={tests}
              />
              <ReactTooltip data-place="top" effect="solid" />
            </React.Fragment>
          ) : (
            <h3 className={classes.Grey + " medium_font text-center"}>
              لايوجد اختبارات حتى الان
            </h3>
          )}
        </div>
      </div>
    );
  }
}

export default Tests;
