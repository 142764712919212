export const tableStyle = {
  rows: {
    style: {
      fontSize: "1rem",
      minHeight: "52px", // override the row height
    },
  },
  headCells: {
    style: {
      fontSize: "1rem",
      fontWeight: "500",
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};
