import React from "react";
import classes from "../UI/UI.module.css";
import Logo from "../../assets/imgs/logo.png";
import { Link } from "react-router-dom";

export default function NotFound(props) {
  return (
    <div className="d-flex vh-100 align-items-center">
      <div className="container-lg container-fluid">
        <div className="col-md-6 mx-auto p-0">
          <div className="bg-white p-4 rounded text-center">
            <img src={Logo} alt="logo" width="250" className="mb-2" />
            <h3 className={"h4 medium_font text-danger mb-3"}>
              {props.msg ? props.msg : "اختبار غير موجود"}
            </h3>
            {props.test ? (
              <p className={classes.Grey + " medium_font mb-4"}>
                {props.msg
                  ? "يجب عليك الانتظار حتى يبدأ الاختبار"
                  : " عذراً , يبدو انك وصلت لصفحة اختبار غير موجود"}
              </p>
            ) : null}
            <Link to="/" className={classes.Primary_btn}>
              العودة للرئيسية
              <i className="fa fa-arrow-left mr-2"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
