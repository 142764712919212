import React, { Component } from "react";
import classes from "../UI/UI.module.css";
import Dropzone from "react-dropzone-uploader";
import axios from "../../axios-api";
import "./submitOrder.css";
import paymentImage from "./imgs/cards.png";
import Loader from "../UI/Loader/Loader";
import { Link } from "react-router-dom";
import { paypalParams, toastr } from "../../Helper/Helper";

export default class SubmitOrder extends Component {
  state = {
    file: null,
    banks: [],
    isLoading: false,
    order: {},
    isReturned: false,
    notFound: false,
    paypalStatus: null,
  };

  fetchOrderData = (payload) => {
    // get order
    const {
      match: { params },
    } = this.props;
    const query =
      payload && payload.payment
        ? `?paymentId=${payload.payment}&token=${payload.token}&PayerID=${payload.payerId}`
        : payload === "canceled"
        ? "?canceled"
        : null;
    axios
      .get(`/account/order/${params.orderId}${payload ? query : ""}`)
      .then((res) => {
        this.setState({ order: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false, notFound: true });
      });
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    const {
      location: { search },
    } = this.props;

    // check if paypal payment successful
    const urlParams = new URLSearchParams(search);
    const query = paypalParams(urlParams);
    const { payment, token, payerId, canceled } = query;

    if (payment && token && payerId) {
      this.fetchOrderData({ payment, token, payerId });
      this.setState({ paypalStatus: "success" });
    } else if (typeof canceled === "string") {
      this.fetchOrderData("canceled");
      this.setState({ paypalStatus: "danger" });
    } else {
      this.fetchOrderData();
    }

    axios
      .get("/payments")
      .then((res) => {
        this.setState({ banks: res.data, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = (files, allFiles) => {
    const id = this.state.order.id;
    const body = new FormData();
    files.map((f) => {
      body.append("file", f.file);
    });
    this.setState({ isLoading: true });
    axios
      .post(`/account/upload/prove/${id}`, body)
      .then((res) => {
        toastr("success", "تم ارسال سند التحويل بنجاح");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        toastr("warning", "لم يتم ارسال سند التحويل , حاول مرة اخرى");
      });
  };
  checkStatus = (status) => {
    switch (status) {
      case "pending":
        return "تحت المراجعة";
      case "completed":
        return "مكتمل";
      case "canceled":
        return "ملغى";
      default:
        return;
    }
  };
  handlePurchase = (e) => {
    const { id } = this.state.order;
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    if (id) {
      axios
        .post("/account/purchase", {
          order_id: id,
        })
        .then((res) => {
          window.open(res.data, "_blank");
          this.setState({ isLoading: false, isReturned: true });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    }
  };
  refreshPage = () => {
    this.fetchOrderData();
  };
  paypalOrderHandle = () => {
    this.setState({ isLoading: true });
    const { id } = this.state.order;
    axios
      .post("/account/purchase/paypal", { order_id: id })
      .then((res) => {
        this.setState({ isLoading: false });
        window.open(res.data.url, "_blank");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };
  render() {
    const {
      banks,
      isLoading,
      notFound,
      isReturned,
      paypalStatus,
      order,
      order: { status, payment_type },
    } = this.state;

    if (order.status !== "completed") {
      setTimeout(() => {
        this.refreshPage();
      }, 1000 * 30);
    }
    return (
      <div className="container-lg container-fluid">
        {isLoading === true ? <Loader /> : null}
        {notFound ? (
          <div className="bg-white mx-auto col-md-6 shadow-sm rounded p-5 text-center mt-5">
            <h3 className={classes.Grey + " h4 bold_font mb-4"}>
              لايوجد طلب فى هذه الصفحة
            </h3>
            <Link to={"/account/my-orders"} className={classes.Primary_btn}>
              العودة للطلبات
            </Link>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-md-4">
              <div className="bg-white shadow-sm rounded p-4">
                <h3 className="medium_font h5 mb-3">تفاصيل الطلب</h3>
                {order.reason ? (
                  <div
                    className="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {order.reason}
                  </div>
                ) : null}

                {order !== undefined ? (
                  <div className="row">
                    <div className="col-md-6 col-7 mb-3">
                      <i className="fa fa-list ml-2"></i>
                      اسم الخطة :
                    </div>
                    <div className="col-md-6 col-7 mb-3">
                      {order.plan ? order.plan.title : null}
                    </div>
                    <div className="col-md-6 col-7 mb-3">
                      <i className="fa fa-refresh ml-2"></i>
                      نوع الاشتراك :
                    </div>
                    <div className="col-md-6 col-5">
                      {order.plan_type === "year" ? "سنوية" : "شهرية"}
                    </div>

                    <div className="col-md-6 col-7 mb-3">
                      <i className="fa fa-list ml-2"></i>
                      عدد الشهادات :
                    </div>
                    <div className="col-md-6 col-5">
                      {order?.plan?.y_certs && order.plan_type === "year"
                        ? order.plan.y_certs
                        : order?.plan?.cert_count}
                    </div>

                    <div className="col-md-6 col-7 mb-3">
                      <i className="fa fa-list ml-2"></i>
                      حالة الطلب :
                    </div>
                    <div className="col-md-6 col-5">
                      {this.checkStatus(order.status)}
                    </div>

                    <div className="col-md-6 col-7 mb-3">
                      <i className="fa fa-money ml-2"></i>
                      سعر الخطة :
                    </div>
                    <div className="col-md-6 col-5">{`${order.price} ر.س`}</div>
                  </div>
                ) : null}
                <div className="form-group mt-4">
                  <div className="position-relative">
                    <div className="row">
                      {status !== "completed" && payment_type !== "paypal" ? (
                        <div className="col-md-6">
                          <button
                            onClick={
                              isReturned
                                ? this.refreshPage
                                : this.handlePurchase
                            }
                            className={classes.Primary_btn + " btn-block mb-3 "}
                          >
                            <span>
                              <i className="fa fa-cc-visa ml-2"></i>
                              ادفع اونلاين
                            </span>
                          </button>
                        </div>
                      ) : null}
                      <div className="col-md-6">
                        {status === "pending" ? (
                          <button
                            onClick={this.paypalOrderHandle}
                            className={[
                              classes.Primary_btn,
                              classes.Paypal_btn,
                            ].join(" ")}
                          >
                            <i className="fa fa-paypal ml-2"></i>
                            ادفع باى بال
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow-sm rounded p-4 mt-4">
                <h3 className="medium_font h5 mb-3">صورة التحويل</h3>
                {order.transaction_prof ? (
                  <img src={order.transaction_prof} alt="صورة التحويل" />
                ) : (
                  "لايوجد سند تحويل"
                )}
              </div>
            </div>
            <div className="col-md-8">
              <div className="bg-white py-4 px-4 rounded">
                {typeof paypalStatus === "string" ? (
                  <div
                    className={`alert alert-${paypalStatus} alert-dismissible fade show`}
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    {paypalStatus === "success"
                      ? "تم الدفع بنجاح"
                      : "فشلت عملية الدفع"}
                  </div>
                ) : null}
                <h3 className="h5 medium_font mb-3">الحسابات البنكية</h3>
                <div className="row">
                  {banks.map((b, key) => {
                    return (
                      <div className="col-md-6" key={key}>
                        <div className="border single_bank rounded p-3 mb-4 text-center">
                          <img
                            src={b.bank_logo}
                            alt="صورة شعار البنك"
                            className="mb-2"
                          />
                          <div>
                            <p className="mb-2">
                              رقم الحساب :{" "}
                              <span className="medium_font">
                                {b.account_number}
                              </span>
                            </p>
                            <p className="mb-2 small_font">
                              اسم صاحب الحساب :
                              <span className="medium_font">
                                {b.account_holder}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {status !== "completed" ? (
                  <Dropzone
                    inputContent={
                      <div>
                        <i className="fa fa-upload ml-2"></i>
                        <span>ارفق سند التحويل</span>
                      </div>
                    }
                    maxFiles={1}
                    onSubmit={this.handleSubmit}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}

        {/* <div className={ isReturned ? 'modalPayment show' : 'modalPayment'}>
          <div className="modal_box">
            <div className="d-flex mb-4">
              <h3 className="h5">استمارة الدفع</h3>
              <button className="mr-auto close" onClick={this.closeModal}>
                 <i className="fa fa-times"></i>
              </button>
            </div>

           <div className="iframe_wrapper">
           <iframe className="w-100" height="650" src={returnUrl} frameborder="0" title="paymentIframe"></iframe>
           </div>

          </div>
        </div> */}
      </div>
    );
  }
}
