import React, { Component } from "react";
import ServiceCard from "../MarketHome/ServiceCard";
import classes from "../../UI/UI.module.css";
import { Link } from "react-router-dom";
import "./ServiceCat.css";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";

export default class ServiceCat extends Component {
  state = {
    activeIndex: null,
    isLoading: false,
    cat: {},
    parents: [],
    current_page: 1,
    singleParent: {},
    chosenFilter: "",
    empty: false,
  };
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ isLoading: true });
    const search = this.props.location.search;
    const pageParam = new URLSearchParams(search).get("type");

    axios.get("/services/category/parents").then((res) => {
      this.setState({ parents: res.data, isLoading: false });
      this.getChilds();
      if (pageParam === "child") {
        this.getUrl(params.catId);
      } else {
        const singleParent = res.data.find((e) => e.id == params.catId);
        this.setState({ singleParent });
      }
    });
  }
  getUrl = (id) => {
    this.setState({ isLoading: true });
    axios.get(`/services/category/${id}`).then((res) => {
      this.setState({ cat: res.data, isLoading: false });
    });
  };
  getChilds = () => {
    this.setState({ isLoading: true });
    const { parents } = this.state;
    const newParents = parents.map((p, i) => {
      axios.get(`/services/category/childs/${p.id}`).then((res) => {
        p.childs = res.data;
      });
      return p;
    });
    this.setState({ parents: newParents, isLoading: false });
  };
  toggleActive = (i) => {
    this.setState({ activeIndex: i });
  };
  componentDidUpdate(prevProps, prevState) {
    const {
      location: { search },
      match: { params },
    } = this.props;

    if (prevProps.match.params.catId !== params.catId) {
      this.setState({ isLoading: true });
      axios
        .get(`/services/category/${params.catId}`)
        .then((res) =>
          this.setState({ cat: res.data, isLoading: false, singleParent: {} })
        )
        .catch(() => this.setState({ isLoading: false }));
    }
    if (prevProps.location.search !== search) {
      this.pageMore();
    }
  }
  pageMore = () => {
    this.setState({ isLoading: true });
    const {
      current_page,
      cat: { category },
    } = this.state;
    axios
      .get(`/services/category/${category?.id}?page=${current_page + 1}`)
      .then((res) =>
        this.setState({
          isLoading: false,
          cat: res.data,
          current_page: current_page + 1,
        })
      )
      .catch(() => this.setState({ isLoading: false }));
  };
  applyFilter = (filter, value) => {
    this.setState({ isLoading: true });
    const { category } = this.state.cat;

    axios
      .get(`/services/category/${category.id}?${filter}=${value}`)
      .then((res) => this.setState({ cat: res.data, isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      activeIndex,
      parents,
      current_page,
      isLoading,
      singleParent: { servicess },
      cat: { services, category },
    } = this.state;

    return (
      <div className="container-lg container-fluid mt-4">
        {isLoading ? <Loader /> : null}
        <div className="d-flex mb-2 align-items-center">
          <h3 className="h5 bold_font mb-0">
            <div className="text-dark" to="/">
              خدمات أعمال
            </div>
          </h3>
          {category ? (
            <div className="mr-auto dropdown">
              <button
                className={`${classes.Transparent_btn} dropdown-toggle`}
                data-toggle="dropdown"
              >
                اخر الخدمات
              </button>
              <div className="dropdown-menu">
                <button
                  onClick={this.applyFilter.bind(this, "sort", "pricel")}
                  className="dropdown-item"
                >
                  الاقل سعراً
                </button>
                <button
                  onClick={this.applyFilter.bind(this, "sort", "priceh")}
                  className="dropdown-item"
                >
                  الأكثر سعراً
                </button>
                <button
                  onClick={this.applyFilter.bind(this, "seller", "s1")}
                  className="dropdown-item"
                >
                  بائع جديد
                </button>
                <button
                  onClick={this.applyFilter.bind(this, "seller", "s2")}
                  className="dropdown-item"
                >
                  بائع نشيط
                </button>
                <button
                  onClick={this.applyFilter.bind(this, "seller", "s3")}
                  className="dropdown-item"
                >
                  بائع موثوق
                </button>
              </div>
            </div>
          ) : null}
        </div>

        {/* row of services */}
        <div className="row">
          <div className="col-lg-3">
            <h3 className="h6 medium_font">الاقسام</h3>
            <ul className="side_cat">
              {parents.map((e, i) => (
                <li
                  className={`main_cat ` + (activeIndex === i ? "active" : " ")}
                  key={i}
                >
                  <div className="d-flex">
                    <a
                      className="cat_link"
                      href="#/"
                      onClick={this.toggleActive.bind(this, i)}
                    >
                      {e?.title}
                    </a>
                    <span className="badge rounded-pill bg-dark mr-auto">
                      {e?.servicess.length}
                    </span>
                  </div>
                  <ul>
                    {e.childs?.map((c, i) => (
                      <li key={i}>
                        <div className="d-flex">
                          <Link
                            to={{
                              pathname: `/category/${c.id}`,
                              search: "?type=child",
                            }}
                          >
                            {c.title}
                          </Link>
                          <span class="badge rounded-pill bg-dark mr-auto">
                            {c.services_count}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-9">
            <div className="row mb-4">
              {services ? (
                <React.Fragment>
                  {services?.map((e, i) => (
                    <ServiceCard
                      key={i}
                      link={`/service/${e.id}`}
                      column="col-lg-4"
                      service={e}
                    />
                  ))}
                  {services && !services.length ? (
                    <div className="bg-white shadow-sm py-5">
                      <React.Fragment>
                        <h1 className="text-center grey mb-3">
                          <i className="fa fa-exclamation"></i>
                        </h1>
                        <h3 className="medium_font text-center grey h5">
                          لايوجد خدمات فى هذا التصنيف
                        </h3>
                      </React.Fragment>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              {servicess?.data?.length
                ? servicess?.data.map((e, i) => (
                    <ServiceCard
                      key={i}
                      link={`/service/${e.id}`}
                      column="col-lg-4"
                      service={e}
                    />
                  ))
                : null}
              {servicess && !servicess?.data?.length ? (
                <div className="bg-white shadow-sm py-5">
                  <React.Fragment>
                    <h1 className="text-center grey mb-3">
                      <i className="fa fa-exclamation"></i>
                    </h1>
                    <h3 className="medium_font text-center grey h5">
                      لايوجد خدمات فى هذا التصنيف
                    </h3>
                  </React.Fragment>
                </div>
              ) : null}
            </div>

            <div className="text-center mb-3">
              {services?.length ? (
                <Link
                  className={classes.Transparent_btn}
                  to={(location) =>
                    `${location.pathname}?type=child?page=${current_page + 1}`
                  }
                >
                  تحميل المزيد
                </Link>
              ) : null}
              {servicess?.length && servicess.next_page_url ? (
                <Link
                  className={classes.Transparent_btn}
                  to={(location) =>
                    `${
                      location.pathname +
                      (location.search ? location.search : null)
                    }?page=${servicess.next_page_url}`
                  }
                >
                  تحميل المزيد
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
