import classes from "../../UI/UI.module.css";
import React, { Component } from "react";
import axios from "../../../axios-api";
import Loader from "../../UI/Loader/Loader";
export default class MsgHistory extends Component {
  state = {
    messages: [],
    activeMsg: {},
    isLoading: false,
  };
  componentDidMount() {
    this.setState({ isLoading: true });

    axios
      .get("/account/emails/history")
      .then((res) => {
        this.setState({ isLoading: false, messages: res.data });
      })
      .catch((err) => this.setState({ isLoading: false }));
  }
  setActiveMsg = (activeMsg) => {
    this.setState({ activeMsg });
  };
  render() {
    const { messages, isLoading, activeMsg } = this.state;
    return (
      <div className="container-lg container-fluid">
        {isLoading ? <Loader /> : null}
        <h3 className="bold_font h5 mb-3 mt-4">سجل الرسائل</h3>

        <div className="bg-white shadow-sm p-4 py-3">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>الدورة</th>
                  <th>عنوان الرسالة</th>
                  <th>محتوى الرسالة</th>
                  <th>عدد البريد</th>
                </tr>
              </thead>
              <tbody>
                {!messages.length ? (
                  <td colSpan="6">
                    <div className="h5 py-5 grey medium_font text-center">
                      لايوجد رسائل حتى الان
                    </div>
                  </td>
                ) : null}
                {messages.map((m, i) => {
                  return (
                    <tr key={i}>
                      <td>{m.id}</td>
                      <td>{m.course?.title}</td>
                      <td>{m.title}</td>
                      <td>
                        <button
                          data-toggle="modal"
                          data-target="#msg-details-modal"
                          className={`${classes.Primary} medium_font`}
                          onClick={this.setActiveMsg.bind(this, m)}
                        >
                          <i className="fa fa-eye ml-2"></i>
                          عرض
                        </button>
                      </td>

                      <td>{m.emails_count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="modal fade"
          id="msg-details-modal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {activeMsg.title}
                </h5>
                <button
                  type="button"
                  className="close mr-auto ml-0"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {activeMsg.content}
                {activeMsg?.file?.length ? (
                  <>
                    <h3 className="h6 medium_font mt-3">المرفقات</h3>
                    {activeMsg?.file?.map((f, i) => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={[classes.Primary, "medium_font"].join(" ")}
                        href={f}
                      >
                        مرفق {i + 1}
                      </a>
                    ))}
                  </>
                ) : null}
              </div>
              <div className="modal-footer">
                <button data-dismiss="modal" className="btn btn-secondary">
                  اغلاق
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
